<template>
  <v-row>
    <v-col cols="12">
      <p v-if="project.categories.length && project.categories[0].length <= 3" class="inline-list">
        Fag:
        <span v-for="category in project.categories" :key="category">{{ allCategories[category].title }}</span>
      </p>
    </v-col>

    <v-col v-if="project.categories.length && project.categories[0].length > 3" cols="12">
      <subject-picker :project="project" />
    </v-col>

    <v-col v-else cols="12">
      <category-picker
        key="choosing"
        :hidden="false"
        :type="0"
        :expanded="false"
        :accordion="false"
        :searchable="true"
        :pre-checked="project.categories"
        @check="saveCategories(...arguments)"
      />
    </v-col>
  </v-row>
</template>

<script>
import CategoryPicker    from '../Categorypicker'
import SubjectPicker     from '../SubjectPicker'
import { allCategories } from '../../categories'

export default {
  name: 'ProjectSubjectsOrCategories',
  components: {
    CategoryPicker,
    SubjectPicker
  },
  props: ['project'],
  data () {
    return {
      allCategories
    }
  },
  methods: {
    saveCategories (newCategories) {
      return this.$axios.put('/admin/projects/' + this.project.ID + '/categories', { categories: newCategories }, {
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(() => {
          this.$emit('updated', {
            ID: this.project.ID,
            key: 'categories',
            value: newCategories
          })
        })
        .catch(e => {
          this.$notify({
            title: 'Kunne ikke oppdatere fag',
            messag: 'Error: ' + e,
            type: 'error'
          })
        })
    }
  }
}
</script>
