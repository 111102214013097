var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          !(_vm.editDescription && _vm.editing)
            ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v("\n      " + _vm._s(_vm.project.description) + "\n    ")
              ])
            : _vm._e(),
          _vm.editDescription
            ? _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", autosize: { minRows: 4 } },
                        model: {
                          value: _vm.newDescription,
                          callback: function($$v) {
                            _vm.newDescription = $$v
                          },
                          expression: "newDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-check" },
                        on: {
                          click: function($event) {
                            _vm.updateDescription()
                            _vm.editDescription = false
                          }
                        }
                      }),
                      _c("el-button", {
                        attrs: { type: "danger", icon: "el-icon-close" },
                        on: {
                          click: function($event) {
                            _vm.editDescription = false
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        [
          !_vm.editDescription
            ? _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.newDescription = ""
                      _vm.editDescription = true
                      _vm.editing = false
                    }
                  }
                },
                [_vm._v("\n      Legg til en kommentar\n    ")]
              )
            : _vm._e(),
          !_vm.editDescription
            ? _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.editDescription = true
                      _vm.editing = true
                      _vm.newDescription = _vm.project.description
                    }
                  }
                },
                [_vm._v("\n      Rediger tekst\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }