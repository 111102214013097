<template>
  <div>
    <v-select
      v-model="lesson.location"
      :label="'Sted'"
      :items="[
        {
          value: 'home',
          text: 'Hjemme'
        },
        {
          value: 'online',
          text: 'På nett'
        },
        {
          value: 'public',
          text: 'Offentlig sted'
        }
      ]"
    />
    <v-dialog
      v-model="timeDialog"
      lazy
      full-width
      width="870px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field v-model="dateField" readonly :label="'Tid'" v-on="on" />
      </template>

      <v-layout style="background: #1976d2; color: white">
        <v-flex>
          <h2>Dato</h2>
          <v-date-picker
            v-model="date"
            first-day-of-week="1"
          />
        </v-flex>

        <v-flex>
          <h2>Starttid</h2>
          <v-time-picker v-model="startTime" format="24hr" />
        </v-flex>

        <v-flex>
          <h2>Sluttid</h2>
          <v-time-picker v-model="endTime" format="24hr" />
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-btn
      color="#009bff"
      dark
      :loading="loading"
      :disabled="!lesson.startTime || !lesson.endTime"
      @click="createLesson"
    >
      Opprett ny time
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'LessonEditor',
  props: ['project'],
  data () {
    return {
      date: '',
      dateField: 'Velg tid',
      endTime: '',
      startTime: '',
      loading: false,
      lesson: {
        customerUID: '',
        endTime: 0,
        location: '',
        projectID: '',
        startTime: 0
      },
      timeDialog: false
    }
  },
  watch: {
    timeDialog (val) {
      if (!val) {
        if (this.date && this.startTime && this.endTime) {
          this.lesson.startTime = this.$moment(this.date + ' ' + this.startTime, 'YYYY-MM-DD HH:mm').unix()
          this.lesson.endTime = this.$moment(this.date + ' ' + this.endTime, 'YYYY-MM-DD HH:mm').unix()

          this.dateField = this.$moment(this.date).format('Do MMM') + ' ' + this.startTime + ' - ' + this.endTime
        }
      }
    }
  },
  mounted () {
    if (this.date && this.startTime && this.endTime) {
      this.dateField = this.date + ' ' + this.startTime + ' - ' + this.endTime
    }

    this.lesson.customerUID = this.project.customerUID
    this.lesson.projectID = this.project.ID
  },
  methods: {
    async createLesson () {
      this.loading = true

      try {
        await this.$axios.post('/lessons', this.lesson)
        this.$emit('success', this.lesson)
      }
      catch (e) {
        this.$sentry.captureException(e)
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>
