<template>
  <v-row>
    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="studentName"
        label="Elevens fornavn"
      />
    </v-col>
    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="general"
        label="Generelt"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="level"
        label="Nivå"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="challenge"
        label="Utfordring"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="goal"
        label="Mål"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="lessonFrequency"
        label="Ønsker undervisning"
        placeholder="F. eks. To ganger pr. uke"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="diagnosis"
        label="Diagnose"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="interests"
        label="Interesser"
      />
    </v-col>

    <v-col cols="6">
      <document-prop-text-field-editor
        :id="project.ID"
        collection="projects"
        :doc="project"
        field="other"
        label="Annet"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProjectInformation',
  props: ['project']
}
</script>
