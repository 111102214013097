<template>
  <div v-loading="loading" style="border: 1px solid #ffda81; padding: 10px 0 0 0; border-radius: 3px">
    <h5 style="color: orange; margin: -5px 0 15px 0">
      Kun synlig for PL og ansatte
    </h5>

    <el-row style="text-align: left; margin: 0 10px">
      <el-radio-group v-model="status">
        <el-row class="status-picker">
          <el-col v-if="project.minutesSold === 0" :span="12" style="margin-bottom: 10px">
            <el-radio label="awaitingFirstLesson">
              Venter kartleggingstime
            </el-radio>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <el-radio label="good">
              Eleven har det bra
            </el-radio>
          </el-col>

          <el-col v-if="project.minutesSold > 0" :span="12" style="margin-bottom: 10px">
            <el-radio label="paused">
              Pause fra undervisningen
            </el-radio>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <el-radio label="badResultPossible">
              Fare for dårlig resultat
            </el-radio>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <el-radio label="notAnswering">
              Jeg får ikke tak i eleven
            </el-radio>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <el-radio label="quitting">
              Eleven ønsker å slutte
            </el-radio>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <el-radio label="other">
              Annet
            </el-radio>
          </el-col>
        </el-row>
      </el-radio-group>

      <el-row v-show="showInput" :gutter="20" style="margin: 20px 0 10px 0">
        <el-col :xs="24" :sm="20" :md="20" :lg="18" :xl="20">
          <el-input v-model="statusText" type="textarea" :rows="5" :placeholder="generatePlaceholder" />
        </el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
          <el-button type="primary" :disabled="!statusText" @click="saveStatus">
            Lagre
          </el-button>
        </el-col>
        <el-col v-if="currentStatusText" :span="24">
          <p style="white-space: pre-wrap">
            {{ currentStatusText }}
          </p>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ProjectStatus',
  props: ['project'],
  data() {
    return {
      currentStatusText: '',
      loading: false,
      newStatus: false,
      status: '',
      statusText: '',
    };
  },
  computed: {
    generatePlaceholder() {
      if (this.status === 'awaiting') return 'Hvorfor har dere ikke avtalt flere timer?';
      else if (this.status === 'quitting') return 'Når hadde dere sist kontakt? Hvorfor ønsker kunden å slutte?';
      else if (this.status === 'notAnswering') return 'Når hadde dere evt. sist kontakt? Hva sa kunden?';
      else if (this.status === 'badResultPossible') return 'Hvorfor oppnår dere ikke resultatene dere ønsker?';
      return 'Hva er status?';
    },
    showInput() { return this.status !== 'good' && this.status !== 'awaitingFirstLesson'; },
  },
  watch: {
    status(val) {
      if (val !== this.project.status) {
        this.saveStatus();
      }
    },
  },
  mounted() {
    this.mount();
  },
  methods: {
    mount() {
      this.currentStatusText = this.project.statusText;
      this.status = this.project.status;
    },
    async saveStatus() {
      this.project.status = this.status;

      if (this.statusText.length) {
        this.project.statusText =
          this.$moment().format('Do MMMM YYYY') + ' skrev ' + this.$store.state.profile.firstName + ' ' +
          this.$store.state.profile.lastName + '.:\n' + this.statusText + '\n\n' + this.project.statusText;
      }

      this.loading = true;

      const updateObject = {
        status: this.status,
        statusText: this.statusText,
        ID: this.project.ID,
      };

      await this.$axios.put('/projects/' + this.project.ID, updateObject);

      this.$store.dispatch('displayAlert', {
        color: 'green',
        message: 'Statusen er oppdatert.',
      });

      this.$emit('statusChanged', this.status);
      this.mount();
      this.statusText = '';
      this.loading = false;
    },
  },
};
</script>
