var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", [
        (_vm.project.lessons && _vm.project.lessons.length) ||
        _vm.newlyRegisterredLesson
          ? _c("p", [
              _c("strong", [_vm._v("Første time")]),
              !_vm.newlyRegisterredLesson
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$moment
                            .unix(_vm.project.firstLesson.startTime)
                            .format("Do MMM HH:mm")
                        ) +
                        " - " +
                        _vm._s(
                          _vm.$moment
                            .unix(_vm.project.firstLesson.endTime)
                            .format("HH:mm")
                        ) +
                        "\n      "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$moment
                            .unix(_vm.newlyRegisterredLesson.startTime)
                            .format("Do MMM HH:mm")
                        ) +
                        " - " +
                        _vm._s(
                          _vm.$moment
                            .unix(_vm.newlyRegisterredLesson.endTime)
                            .format("HH:mm")
                        ) +
                        "\n      "
                    )
                  ])
            ])
          : _c(
              "span",
              [
                _c("h4", [_vm._v("Registrer kartleggingstime")]),
                _c("lesson-editor", {
                  attrs: { project: _vm.project },
                  on: { success: _vm.updateFirstLesson }
                })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }