<template>
  <v-row>
    <v-col cols="8">
      <p v-if="!(editDescription && editing)" style="white-space: pre-line">
        {{ project.description }}
      </p>

      <el-form v-if="editDescription">
        <el-form-item>
          <el-input v-model="newDescription" type="textarea" :autosize="{ minRows: 4}" />
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-check" @click="updateDescription(); editDescription=false" />
          <el-button type="danger" icon="el-icon-close" @click="editDescription=false" />
        </el-form-item>
      </el-form>
    </v-col>

    <v-col>
      <v-btn
        v-if="!editDescription"
        @click="newDescription=''; editDescription = true; editing=false;"
      >
        Legg til en kommentar
      </v-btn>
      <v-btn
        v-if="!editDescription"
        @click="editDescription = true; editing=true; newDescription=project.description"
      >
        Rediger tekst
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['project'],
  data() {
    return {
      editDescription: false,
      newDescription: '',
    };
  },
  methods: {
    async updateDescription() {
      if (this.newDescription.length > 0 && this.editing === false) {
        this.project.description = this.project.description + '\n\n======================================\n   ' +
          this.$moment().format('dddd, Do MMMM YYYY, H:mm') +
          ' skrev ' + this.$store.state.profile.firstName + ' ' +
          this.$store.state.profile.lastName[0] + '.:\n' + this.newDescription;
      }
      else if (this.editing === true) {
        this.project.description = this.newDescription;
        this.newDescription = '';
      }

      try {
        await this.$axios.put('/projects/' + this.project.ID, {
          description: this.project.description,
          ID: this.project.ID,
        });

        this.$store.dispatch('displayAlert', {
          color: 'green',
          message: 'Beskrivelsen ble oppdatert.',
        });
      }
      catch (e) {
        this.$store.dispatch('displayAlert', {
          color: 'red',
          message: 'Kunne ikke oppdatere beskrivelse',
        });
      }
    },
  },
};
</script>
