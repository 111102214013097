var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("document-field-picker", {
    key: _vm.project.ID + "/location",
    attrs: {
      id: _vm.project.ID,
      collection: "projects",
      doc: _vm.project,
      field: "location",
      label: "Sted",
      alternatives: [
        {
          value: "home",
          text: "Hjemme"
        },
        {
          value: "online",
          text: "På nett"
        },
        {
          value: "public",
          text: "Offentlig sted"
        }
      ]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }