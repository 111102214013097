var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: "Sted",
          items: [
            {
              value: "home",
              text: "Hjemme"
            },
            {
              value: "online",
              text: "På nett"
            },
            {
              value: "public",
              text: "Offentlig sted"
            }
          ]
        },
        model: {
          value: _vm.lesson.location,
          callback: function($$v) {
            _vm.$set(_vm.lesson, "location", $$v)
          },
          expression: "lesson.location"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { lazy: "", "full-width": "", width: "870px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: { readonly: "", label: "Tid" },
                        model: {
                          value: _vm.dateField,
                          callback: function($$v) {
                            _vm.dateField = $$v
                          },
                          expression: "dateField"
                        }
                      },
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.timeDialog,
            callback: function($$v) {
              _vm.timeDialog = $$v
            },
            expression: "timeDialog"
          }
        },
        [
          _c(
            "v-layout",
            { staticStyle: { background: "#1976d2", color: "white" } },
            [
              _c(
                "v-flex",
                [
                  _c("h2", [_vm._v("Dato")]),
                  _c("v-date-picker", {
                    attrs: { "first-day-of-week": "1" },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c("h2", [_vm._v("Starttid")]),
                  _c("v-time-picker", {
                    attrs: { format: "24hr" },
                    model: {
                      value: _vm.startTime,
                      callback: function($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c("h2", [_vm._v("Sluttid")]),
                  _c("v-time-picker", {
                    attrs: { format: "24hr" },
                    model: {
                      value: _vm.endTime,
                      callback: function($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            color: "#009bff",
            dark: "",
            loading: _vm.loading,
            disabled: !_vm.lesson.startTime || !_vm.lesson.endTime
          },
          on: { click: _vm.createLesson }
        },
        [_vm._v("\n    Opprett ny time\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }