import { render, staticRenderFns } from "./SubjectPicker.vue?vue&type=template&id=e58e804c&"
import script from "./SubjectPicker.vue?vue&type=script&lang=js&"
export * from "./SubjectPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/Users/Jobb/Documents/Jobb/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e58e804c')) {
      api.createRecord('e58e804c', component.options)
    } else {
      api.reload('e58e804c', component.options)
    }
    module.hot.accept("./SubjectPicker.vue?vue&type=template&id=e58e804c&", function () {
      api.rerender('e58e804c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SubjectPicker.vue"
export default component.exports