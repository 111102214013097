<template>
  <v-select
    v-model="subjectsPicked"
    :items="Object.values(subjects)"
    label="Velg fag"
    multiple
    item-text="title"
    item-value="ID"
    @change="updateCategories()"
  >
    <template v-slot:selection="{ item }">
      <v-chip>
        <span>{{ item.title }}</span>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { subjects } from '../subjects';

export default {
  name: 'SubjectPicker',
  props: ['project'],
  data: () => ({
    subjects: subjects,
    subjectsPicked: [],
  }),
  mounted() {
    this.subjectsPicked = this.project.categories;
  },
  methods: {
    updateCategories() {
      return this.$axios.put('/projects/' + this.project.ID + '/categories', { categories: this.subjectsPicked });
    },
  },
};
</script>
