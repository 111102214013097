var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "studentName",
              label: "Elevens fornavn"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "general",
              label: "Generelt"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "level",
              label: "Nivå"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "challenge",
              label: "Utfordring"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "goal",
              label: "Mål"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "lessonFrequency",
              label: "Ønsker undervisning",
              placeholder: "F. eks. To ganger pr. uke"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "diagnosis",
              label: "Diagnose"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "interests",
              label: "Interesser"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("document-prop-text-field-editor", {
            attrs: {
              id: _vm.project.ID,
              collection: "projects",
              doc: _vm.project,
              field: "other",
              label: "Annet"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }