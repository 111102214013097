var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c(
            "v-expansion-panels",
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var open = ref.open
                          return [
                            !open
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(
                                        "Kontaktperson: " +
                                          _vm._s(_vm.project.customer.user.POC)
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(
                                        "Kontaktet: " +
                                          _vm._s(
                                            _vm.project.customer.user.contacted
                                          )
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(
                                        "Prioritet: " +
                                          _vm._s(
                                            _vm.project.customer.user.priority
                                          )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("project-categorization", {
                        attrs: { project: _vm.project }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _vm._v(
                                "Beskrivelse: " +
                                  _vm._s(
                                    _vm.project.description.substring(0, 50)
                                  ) +
                                  "..."
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("project-description", {
                        attrs: { project: _vm.project }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var open = ref.open
                          return [
                            !open
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", [
                                      _vm.project.categories.length &&
                                      _vm.project.categories[0].length <= 3
                                        ? _c(
                                            "p",
                                            { staticClass: "inline-list" },
                                            [
                                              _vm._v(
                                                "\n                  Fag:\n                  "
                                              ),
                                              _vm._l(
                                                _vm.project.categories,
                                                function(category) {
                                                  return _c(
                                                    "span",
                                                    { key: category },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.allCategories[
                                                            category
                                                          ].title
                                                        )
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]),
                                    _c("v-col", [
                                      _vm._v(
                                        "\n                Sted: " +
                                          _vm._s(_vm.project.location) +
                                          "\n              "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("project-subjects-or-categories", {
                                attrs: { project: _vm.project }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("project-location", {
                                attrs: { project: _vm.project }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var open = ref.open
                          return [
                            !open
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(
                                        "Status: " + _vm._s(_vm.project.status)
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [_c("project-status", { attrs: { project: _vm.project } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var open = ref.open
                          return [
                            !open
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v(
                                        "Elevens fornavn: " +
                                          _vm._s(_vm.project.studentName)
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v(
                                        "Generelt: " +
                                          _vm._s(
                                            _vm.project.general.substring(0, 45)
                                          ) +
                                          "..."
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v(
                                        "Nivå: " + _vm._s(_vm.project.level)
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _vm._v(
                                        "Ønsker undervisning: " +
                                          _vm._s(_vm.project.lessonFrequency)
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("project-information", {
                        attrs: { project: _vm.project }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("\n          Kartleggingstime\n        ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("first-lesson-editor", {
                        attrs: { project: _vm.project }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("\n          Datainnsamling\n        ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("customer-information", {
                        attrs: { project: _vm.project }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { dark: "", dense: "" } },
        [
          _c("v-spacer"),
          !_vm.project.sellerUID && !_vm.project.cancelled
            ? _c(
                "div",
                [
                  !_vm.project.approved
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "green",
                            dark: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.approveProject(_vm.project)
                            }
                          }
                        },
                        [_vm._v("\n        Godkjenn oppdraget\n      ")]
                      )
                    : _vm.project.approved
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "red",
                            small: "",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.unapproveProject(_vm.project.ID)
                            }
                          }
                        },
                        [_vm._v("\n        Fjern godkjenning\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.project.minutesSold && !_vm.project.completed
            ? _c(
                "div",
                [
                  !_vm.project.cancelled
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "red",
                            dark: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.cancelProject(_vm.project.ID)
                            }
                          }
                        },
                        [_vm._v("\n        Kanseller oppdraget\n      ")]
                      )
                    : _vm.project.cancelled
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "red",
                            dark: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.unCancelProject(_vm.project.ID)
                            }
                          }
                        },
                        [_vm._v("\n        Angre kansellering\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.project.completed &&
          !_vm.project.cancelled &&
          _vm.project.minutesSold
            ? _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "orange",
                    small: "",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.completeProject(_vm.project.ID)
                    }
                  }
                },
                [_vm._v("\n      Fullfør oppdraget\n    ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          _c("syncers", { attrs: { uid: _vm.project.customerUID } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }