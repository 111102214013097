var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      items: Object.values(_vm.subjects),
      label: "Velg fag",
      multiple: "",
      "item-text": "title",
      "item-value": "ID"
    },
    on: {
      change: function($event) {
        return _vm.updateCategories()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [_c("v-chip", [_c("span", [_vm._v(_vm._s(item.title))])])]
        }
      }
    ]),
    model: {
      value: _vm.subjectsPicked,
      callback: function($$v) {
        _vm.subjectsPicked = $$v
      },
      expression: "subjectsPicked"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }