<template>
  <v-row justify-space-around>
    <v-col>
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/POC'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'POC'"
        :label="'Kontaktperson'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'jonas',
            text: 'Jonas'
          },
          {
            value: 'tellef',
            text: 'Tellef'
          },
          {
            value: 'sigrid',
            text: 'Sigrid'
          },
          {
            value: 'hakon',
            text: 'Håkon'
          }
        ]"
      />
    </v-col>
    <v-col>
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/contacted'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'contacted'"
        :label="'Kontaktet?'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'yes',
            text: 'Ja'
          },
          {
            value: 'no',
            text: 'Nei'
          }
        ]"
      />
    </v-col>
    <v-col>
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/priority'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'priority'"
        :label="'Prioritet'"
        :alternatives="[
          {
            value: 0,
            text: '(Ikke valgt)'
          },
          {
            value: 1,
            text: '1 (høyest)'
          },
          {
            value: 2,
            text: '2 (vanlig)'
          },
          {
            value: 3,
            text: '3 (lavest)'
          }
        ]"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProjectCategoryation',
  props: ['project']
}
</script>
