<template>
  <document-field-picker
    :id="project.ID"
    :key="project.ID + '/location'"
    :collection="'projects'"
    :doc="project"
    :field="'location'"
    :label="'Sted'"
    :alternatives="[
      {
        value: 'home',
        text: 'Hjemme'
      },
      {
        value: 'online',
        text: 'På nett'
      },
      {
        value: 'public',
        text: 'Offentlig sted'
      }
    ]"
  />
</template>

<script>
export default {
  name: 'ProjectLocation',
  props: ['project']
}
</script>
