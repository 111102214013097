export const levels = {
  0: 'Førskole',
  1: '1. klasse',
  2: '2. klasse',
  3: '3. klasse',
  4: '4. klasse',
  5: '5. klasse',
  6: '6. klasse',
  7: '7. klasse',
  8: '8. klasse',
  9: '9. klasse',
  10: '10. klasse',
  11: 'VG1',
  12: 'VG2',
  13: 'VG1'
}

export const subjects = {
  'BI01-01': {
    ID: 'BI01-01',
    title: 'Biologi 1',
    hours: 8400,
    chapters: [
      {
        title: 'Den unge biologen',
        goals: [
          'Planleggje og gjennomføre undersøkingar i laboratorium frå alle hovudområda, rapportere frå arbeida med og utan digitale verktøy og peike på feilkjelder i undersøkingane',
          'Gjennomføre eit større feltarbeid og nytte biologiske metodar til å samle inn, kartleggje og utforske ulike typar organismar og leggje fram resultata frå undersøkingane',
          'Observere og namngje nokre vanlege artar frå ulike biotopar og samanlikne dei med omsyn til fellestrekk og variasjon ved å bruke kunnskapar frå systematikk',
          'Trekkje ut informasjon frå biologiske tekstar, brosjyrar, aviser, bøker og frå Internett, og vurdere korleis informasjonen er underbygd'
        ]
      },
      {
        title: 'Cellebiologi',
        goals: [
          'Gjere greie for oppbygginga av eukaryote celler og forklare kva for funksjonar ulike delar i cellene har',
          'Forklare transport gjennom cellemembranen ved å bruke kunnskap om passive og aktive transportmekanismar',
          'Gjere greie for oppbygginga og formeiringa til bakteriar og virus, og relatere det til prosessar i natur, industri og helsefagleg samanheng'
        ]
      },
      {
        title: 'Fysiologien til mennesket',
        goals: [
          'Gjere greie for oppbygginga av og funksjonen til sentrale organsystem i kroppen, og drøfte årsaker til sjukdommar som har samanheng med livsstil',
          'Samanlikne hormonsystemet og nervesystemet og forklare korleis desse systema blir påverka av ulike stoff',
          'Gjere greie for korleis immunforsvaret og andre delar av infeksjonsforsvaret verkar',
          'Diskutere problemstillingar som gjeld organdonasjonar og medisinske kriterium for død'
        ]
      },
      {
        title: 'Funksjon og tilpassing',
        goals: [
          'Samanlikne bygning og funksjon av organsystem hos ulike dyregrupper, med vekt på sirkulasjon, gassutveksling og utskiljing, sett i samanheng med tilpassing til ulike levevilkår',
          'Gjere greie for hovudtrekk i formeiringa av planter og dyr, sett i samanheng med utviklinga av livet på jorda',
          'Forklare korleis opptak og transport av vatn og oppløyste stoff skjer hos planter, og diskutere kva slag tilpassing planter kan ha til ulike levevilkår',
          'Drøfte korleis ytre faktorar påverkar vekst og utvikling hos planter',
          'Gje døme på og grunngje korleis åtferd som kjem av evolusjon, er ein del av tilpassinga til omgjevnadene'
        ]
      },
      {
        title: 'Biologisk mangfald',
        goals: [
          'Forklare kva omgrepet biologisk mangfald omfattar, og drøfte spørsmål kring ansvaret for å ta vare på biologisk mangfald lokalt og globalt',
          'Forklare korleis ein art blir definert, og korleis det biologiske mangfaldet blir organisert i taksonomiske system',
          'Gje døme på variasjon innanfor og mellom populasjonar av same art, og forklare kva denne variasjonen har å seie',
          'Forklare korleis biologisk mangfald heng saman med variasjon i habitat og nisjar i økosystema'
        ]
      }
    ],
    purpose: [
      'Menneske har alltid vore avhengige av kunnskapar om naturen for å overleve. Biologisk kunnskap inkluderer alt frå det forunderlege som skjer på mikronivå i det indre av cellene, til samspelet i økosystema på jordkloden. Tilfanget av kunnskap aukar raskt, og biologi handlar om å kunne bruke kjend fagkunnskap til å beskrive og forstå nye fenomen, skape ny kunnskap og delta med fagleg kompetanse i samfunnsdebatten.',
      'Programfaget skal gje grunnlag for å bruke biologisk fagkunnskap i ulike samanhengar, i praktiske situasjonar i kvardagen og til vurderingar kring etiske spørsmål. Gjennom programfaget biologi skal den einskilde bli medviten om at mennesket er ein av mange artar, at det er ein vekselverknad mellom menneska og naturen, og at biologisk kunnskap er ein vesentleg føresetnad for ei berekraftig forvalting av naturressursar og naturmiljø.',
      'Programfaget skal leggje til rette for å bli kjend med kulturen, språket og arbeidsmåtane i faget, og øve opp ulike former for kommunikasjon. Det skal øve opp evna til å fokusere på detaljar og til å byggje opp ei heilskapleg forståing, og det legg vekt på observasjonar, diskusjon, kritisk vurdering og krav til grunngjevingar. Programfaget gjev grunnlag for vidare studium innanfor biologi og biologirelaterte område. På same tid skal opplæringa leggje vekt på dei allmenndannande sidene ved biologifaget, som biologisk kunnskap knytt til miljøutfordringar, berekraftig utvikling, bioteknologiske spørsmål og spørsmål knytte til kropp og helse. Denne kompetansen kan leggje grunnlag for å tileigne seg ny kunnskap gjennom heile livet. Sidan biologifaget er i stadig utvikling, inngår òg eit historisk perspektiv i faget. Programfaget gjev gode høve til å ta opp variasjon og avvik frå teori og modellar som grunnlag for å utvikle ny teori og nye modellar.',
      'Programfaget skal gjere bruk av naturen som læringsarena og på den måten leggje eit grunnlag for å oppleve glede og undring over naturen og respekt for han. For å utvikle kunnskap om metodar og tenkjemåtar i biologi er det nødvendig å arbeide både praktisk og teoretisk med programfaget. Vekselverknaden mellom teoretiske kunnskapar og praktiske ferdigheiter og opplevingar frå laboratorium og feltarbeid er grunnleggjande for biologisk forståing. Opplæringa skal leggje til rette for at desse perspektiva blir varetekne i arbeidet med programfaget.'
    ],
    skills: [
      'Grunnleggjande ferdigheiter er integrerte i kompetansemåla, der dei medverkar til å utvikle fagkompetansen og er ein del av han. I biologi forstår ein grunnleggjande ferdigheiter slik:',
      'Å kunne uttrykkje seg munnleg og skriftleg i biologi inneber å gjere greie for eigne observasjonar og røynsler frå naturen og laboratoriet ved å bruke biologifaglege omgrep. Det omfattar å formulere spørsmål og hypotesar som kan undersøkjast, argumenterast for og drøftast i høve til annan biologisk informasjon. I tillegg vil det seie kritisk vurdering av ulike typar biologisk informasjon i fagtidsskrift og i media.',
      'Å kunne lese i biologi inneber å trekkje ut, tolke og reflektere over informasjonen som finst i biologifaglege tekstar, brosjyrar, aviser og bøker og på Internett. Det omfattar å forstå oppskrifter, tabellar, diagram og symbol. Å setje seg inn i nye område av faget ved å forstå stadig meir krevjande biologisk litteratur er ein del av kompetansen.',
      'Å kunne rekne i biologi inneber å bruke tal og utrekningar, registrere, gjere enkle statistiske analysar, arbeide med og presentere resultat av eigne observasjonar. Å setje seg inn i og bruke matematiske modellar frå biologisk forsking inngår òg. Like eins inneber det å forstå resultat i form av grafar og tabellar.',
      'Å kunne bruke digitale verktøy i biologi inneber å hente informasjon og utforske, registrere, arbeide med og presentere resultat av eigne observasjonar. Det omfattar å bruke animasjonar og simuleringar til å illustrere og utdjupe biologifagleg stoff. Å vurdere nettbasert biologifagleg informasjon inngår òg.'
    ],
    levels: [11, 12, 13]
  },
  'ENG1-03-1': {
    ID: 'ENG1-03-1',
    title: 'Engelsk 1.-2. trinn',
    hours: 4140,
    chapters: [
      {
        title: 'Språklæring',
        goals: [
          'Gi eksempler på noen situasjoner der det kan være nyttig å kunne engelsk',
          'Finne ord og uttrykk som er felles for engelsk og eget morsmål',
          'Bruke digitale ressurser i opplevelse av språket'
        ]
      },
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Lytte etter og bruke engelske språklyder gjennom praktisk-estetiske uttrykksmåter',
          'Lytte til og forstå enkle instruksjoner på engelsk',
          'Lytte til og forstå ord og uttrykk i engelskspråklige rim, regler, sanger, eventyr og fortellinger',
          'Forstå og bruke noen engelske ord, uttrykk og setningsmønstre knyttet til nære omgivelser og egne interesser',
          'Hilse, stille og svare på enkle spørsmål og bruke noen høflighetsuttrykk',
          'Delta i enkle innøvde dialoger og spontane samtaler knyttet til nære omgivelser og egne opplevelser',
          'Bruke tall i samtale om nære omgivelser og egne opplevelser'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Kjenne igjen sammenhengen mellom noen engelske språklyder og stavemønstre',
          'Eksperimentere med å lese og skrive engelske ord, uttrykk og enkle setninger knyttet til nære omgivelser og egne interesser'
        ]
      },
      {
        title: 'Kultur, samfunn og litteratur',
        goals: [
          'Samtale om sider ved barns dagligliv i engelskspråklige land',
          'Ta del i og oppleve barnekultur fra engelskspråklige land gjennom å bruke ord, bilder, musikk og bevegelse',
          'Gi uttrykk for egne opplevelser av engelskspråklige rim, regler, sanger, eventyr og fortellinger'
        ]
      }
    ],
    purpose: [
      'Engelsk er et verdensspråk. I møte med mennesker fra andre land, hjemme eller på reiser, har vi ofte bruk for engelsk. Engelsk brukes i film, litteratur, sang, sport, handel, produkter, vitenskap, og teknologi, og gjennom disse bruksområdene har mange engelske ord og uttrykk funnet veien til våre egne språk. Når vi skal lete etter informasjon ut fra våre egne interesser eller i faglig sammenheng, skjer dette ofte på engelsk. I tillegg brukes engelsk i økende grad i utdanning og som arbeidsspråk i mange bedrifter.',
      'For å klare oss i en verden der engelsk benyttes i internasjonal kommunikasjon, er det nødvendig å kunne bruke det engelske språket og ha kunnskap om hvordan språket brukes i ulike sammenhenger. Det betyr å utvikle ordforråd og ferdigheter i å bruke språkets lydsystem, rettskriving, grammatikk og prinsipper for setnings- og tekstbygging, og kunne tilpasse språket til ulike emner og kommunikasjonssituasjoner. Dette innebærer å kunne skille mellom muntlig og skriftlig språkføring og formell og uformell språkbruk. Videre betyr det å kunne ta hensyn til kulturelle omgangsformer og høflighetskonvensjoner når vi bruker språket.',
      'Språklæringen skjer i møte med et mangfold av tekster, der tekstbegrepet brukes i vid forstand. Det omfatter muntlige og skriftlige framstillinger i forskjellige kombinasjoner, og ulike muntlige og skriftlige tekster fra digitale medier. Når vi er bevisste på strategier som brukes for å lære språk, og strategier som hjelper oss til å forstå og å bli forstått, blir tilegnelsen av kunnskaper og ferdigheter enklere og mer meningsfylt. Det er også viktig å sette egne mål for læringen, finne ut hvordan målene kan nås og vurdere egen språkbruk. Å lære engelsk bidrar til flerspråklighet og kan bli en viktig del av vår personlige utvikling.',
      'I tillegg til språklæring skal engelskfaget bidra til å gi innsikt i levesett og kulturer der engelsk er hovedspråk eller et offisielt språk. Faget skal gi innsikt i hvordan engelsk brukes som et internasjonalt kommunikasjonsmiddel. Å lære om engelskspråklige land og den stadig økende bruken av engelsk i ulike internasjonale kontekster, vil gi et godt grunnlag for å forstå verden omkring oss og utviklingen av engelsk som et verdensspråk. Engelskspråklige litterære tekster kan gi leseglede for livet og en dypere forståelse for andre og oss selv. Muntlige, skriftlige og digitale tekster, film, musikk og andre kulturuttrykk kan videre inspirere til egen utfoldelse og kreativitet.',
      'Engelskfaget er både et redskapsfag og et dannelsesfag. Det skal gi mulighet til å delta i kommunikasjon om personlige, sosiale, litterære og faglige emner. Faget skal bygge opp den generelle språkkompetansen gjennom å lytte, snakke, lese og skrive, og gi mulighet til å tilegne seg informasjon og fagkunnskap via det engelske språket. Utviklingen av kommunikative og språklige ferdigheter og kulturell innsikt kan fremme økt samhandling, forståelse og respekt mellom mennesker med ulik kulturbakgrunn. Slik blir språk- og kulturkompetanse en del av allmenndannelsen og bidrar til å styrke demokratisk engasjement og medborgerskap.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I engelsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i engelsk er å kunne lytte, tale og samhandle ved å bruke det engelske språket. Det betyr å vurdere og tilpasse uttrykksmåter til formål, mottaker og situasjon. Det innebærer videre å lære om sosiale konvensjoner og omgangsformer i engelskspråklige land og i internasjonale kontekster. Utvikling av muntlige ferdigheter i engelsk innebærer å bruke det muntlige språket gradvis mer presist og nyansert i samtaler og i andre typer muntlig kommunikasjon. Videre innebærer det å lytte til, forstå og drøfte forskjellige emner og problemstillinger for å tilegne seg fagkunnskap. Det innebærer også å kunne forstå varianter av muntlig engelsk fra forskjellige deler av verden.',
      'Å kunne skrive i engelsk er å kunne uttrykke ideer og meninger på en forståelig og hensiktsmessig måte ved å bruke det engelske skriftspråket. Det betyr å planlegge, utforme og bearbeide tekster som kommuniserer, og som har god struktur og sammenheng. I tillegg er skriving et redskap for språklæring. Utvikling av skriveferdigheter i engelsk innebærer å lære rettskriving og utvikle et økende repertoar av engelske ord og språklige strukturer. Videre innebærer det å utvikle en allsidig kompetanse i å skrive ulike typer generelle, litterære og faglige tekster på engelsk ved bruken av uformelt og formelt språk, tilpasset formål og mottaker.',
      'Å kunne lese i engelsk er å kunne skape mening ut fra ulike typer tekster. Det betyr å lese engelskspråklige tekster for å forstå, reflektere over og tilegne seg innsikt og kunnskap på tvers av kulturer og fagfelt. Det innebærer videre å forberede, utføre og bearbeide lesing av engelskspråklige tekster til forskjellige formål, og av varierende lengde og kompleksitet. Utvikling av leseferdigheter i engelsk er å ta i bruk lesestrategier tilpasset formålet med lesingen i stadig mer krevende tekster. Videre innebærer det å lese engelskspråklige tekster med flyt og forståelse, og utforske, drøfte, lære av og reflektere over ulike typer informasjon.',
      'Å kunne regne i engelsk er å kunne bruke relevante matematiske begreper på engelsk i ulike situasjoner. Det innebærer å kjenne til måleenheter som brukes i engelskspråklige land, og forstå og kommunisere om tall, grafiske framstillinger, tabeller og statistikk på engelsk. Utvikling av regneferdigheter i engelsk innebærer å bruke tall og regning ved å utvikle et repertoar av matematiske termer på engelsk knyttet til dagliglivet, og generelle og faglige emner.',
      'Digitale ferdigheter i engelsk er å kunne bruke et variert utvalg digitale verktøy, medier og ressurser for å styrke språklæringen, kommunisere på engelsk og tilegne seg relevant kunnskap i engelskfaget. Bruk av digitale ressurser gir mulighet for å oppleve engelskspråklige tekster i autentiske situasjoner, det vil si naturlige, ikke-tilpassede situasjoner. Utvikling av digitale ferdigheter innebærer å innhente og behandle informasjon for å skape ulike typer tekster. Digitale formkrav i tekster betyr at effekter, bilder, tabeller, overskrifter og punkter er satt sammen for å understreke og formidle et budskap. Det innebærer videre å bruke digitale kilder i skriftlige og muntlige tekster, og å ha en kritisk og selvstendig holdning til kildebruk. Digitale ferdigheter innebærer å utvikle kunnskap om opphavsrett og personvern gjennom etterprøvbare kildehenvisninger.'
    ],
    levels: [1, 2]
  },
  'ENG1-03-2': {
    ID: 'ENG1-03',
    title: 'Engelsk 3.-4.trinn',
    hours: 4140,
    chapters: [
      {
        title: 'Språklæring',
        goals: [
          'Identifisere situasjoner der det kan være nyttig eller nødvendig å kunne engelsk',
          'Samtale om eget arbeid med å lære engelsk',
          'Finne likheter mellom ord og uttrykksmåter i engelsk og eget morsmål',
          'Bruke digitale ressurser og andre hjelpemidler i utforsking av språket'
        ]
      },
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Bruke enkle lytte- og talestrategier',
          'Lytte til og forstå betydningen av ord og uttrykk ut fra sammenhengen de er brukt i',
          'Forstå og bruke engelske ord, uttrykk og setningsmønstre knyttet til egne behov og følelser, dagligliv, fritid og interesser',
          'Forstå hovedinnholdet i rim, regler, sanger, eventyr og fortellinger',
          'Bruke noen høflighetsuttrykk og enkle fraser for å få hjelp til å forstå og bli forstått',
          'Delta i dagligdagse samtaler knyttet til nære omgivelser og egne opplevelser',
          'Si det engelske alfabetet og stave navn og bostedsnavn',
          'Forstå og bruke ord og uttrykk knyttet til priser, mengder, form og størrelser i kommunikasjon om dagligliv, fritid og interesser'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Bruke enkle lese- og skrivestrategier',
          'Forstå sammenhengen mellom engelske språklyder og bokstaver og trekke lyder sammen til ord',
          'Forstå betydningen av ord og uttrykk ut fra sammenhengen de er brukt i',
          'Lese, forstå og skrive engelske ord og uttrykk knyttet til egne behov og følelser, dagligliv, fritid og interesser',
          'Forstå hovedinnholdet i enkle tekster om kjente emner',
          'Skrive korte tekster som uttrykker meninger og interesser, og som beskriver, forteller og spør',
          'Bruke noen vanlige småord og enkle stave- og setningsmønstre',
          'Bruke digitale verktøy for å hente informasjon og eksperimentere med å skape tekst'
        ]
      },
      {
        title: 'Kultur, samfunn og litteratur',
        goals: [
          'Gi noen eksempler på engelskspråklige land og kjente personer derfra',
          'Samtale om noen sider ved ulike levesett, tradisjoner og skikker i engelskspråklige land og i Norge',
          'Delta i framføring av engelskspråklige rim, regler, sanger, korte skuespill og fortellinger',
          'Gi uttrykk for egne tanker og meninger i møte med engelskspråklig litteratur og barnekultur',
          'Lage egne tekster inspirert av engelskspråklig litteratur og barnekultur'
        ]
      }
    ],
    purpose: [
      'Engelsk er et verdensspråk. I møte med mennesker fra andre land, hjemme eller på reiser, har vi ofte bruk for engelsk. Engelsk brukes i film, litteratur, sang, sport, handel, produkter, vitenskap, og teknologi, og gjennom disse bruksområdene har mange engelske ord og uttrykk funnet veien til våre egne språk. Når vi skal lete etter informasjon ut fra våre egne interesser eller i faglig sammenheng, skjer dette ofte på engelsk. I tillegg brukes engelsk i økende grad i utdanning og som arbeidsspråk i mange bedrifter.',
      'For å klare oss i en verden der engelsk benyttes i internasjonal kommunikasjon, er det nødvendig å kunne bruke det engelske språket og ha kunnskap om hvordan språket brukes i ulike sammenhenger. Det betyr å utvikle ordforråd og ferdigheter i å bruke språkets lydsystem, rettskriving, grammatikk og prinsipper for setnings- og tekstbygging, og kunne tilpasse språket til ulike emner og kommunikasjonssituasjoner. Dette innebærer å kunne skille mellom muntlig og skriftlig språkføring og formell og uformell språkbruk. Videre betyr det å kunne ta hensyn til kulturelle omgangsformer og høflighetskonvensjoner når vi bruker språket.',
      'Språklæringen skjer i møte med et mangfold av tekster, der tekstbegrepet brukes i vid forstand. Det omfatter muntlige og skriftlige framstillinger i forskjellige kombinasjoner, og ulike muntlige og skriftlige tekster fra digitale medier. Når vi er bevisste på strategier som brukes for å lære språk, og strategier som hjelper oss til å forstå og å bli forstått, blir tilegnelsen av kunnskaper og ferdigheter enklere og mer meningsfylt. Det er også viktig å sette egne mål for læringen, finne ut hvordan målene kan nås og vurdere egen språkbruk. Å lære engelsk bidrar til flerspråklighet og kan bli en viktig del av vår personlige utvikling.',
      'I tillegg til språklæring skal engelskfaget bidra til å gi innsikt i levesett og kulturer der engelsk er hovedspråk eller et offisielt språk. Faget skal gi innsikt i hvordan engelsk brukes som et internasjonalt kommunikasjonsmiddel. Å lære om engelskspråklige land og den stadig økende bruken av engelsk i ulike internasjonale kontekster, vil gi et godt grunnlag for å forstå verden omkring oss og utviklingen av engelsk som et verdensspråk. Engelskspråklige litterære tekster kan gi leseglede for livet og en dypere forståelse for andre og oss selv. Muntlige, skriftlige og digitale tekster, film, musikk og andre kulturuttrykk kan videre inspirere til egen utfoldelse og kreativitet.',
      'Engelskfaget er både et redskapsfag og et dannelsesfag. Det skal gi mulighet til å delta i kommunikasjon om personlige, sosiale, litterære og faglige emner. Faget skal bygge opp den generelle språkkompetansen gjennom å lytte, snakke, lese og skrive, og gi mulighet til å tilegne seg informasjon og fagkunnskap via det engelske språket. Utviklingen av kommunikative og språklige ferdigheter og kulturell innsikt kan fremme økt samhandling, forståelse og respekt mellom mennesker med ulik kulturbakgrunn. Slik blir språk- og kulturkompetanse en del av allmenndannelsen og bidrar til å styrke demokratisk engasjement og medborgerskap.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I engelsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i engelsk er å kunne lytte, tale og samhandle ved å bruke det engelske språket. Det betyr å vurdere og tilpasse uttrykksmåter til formål, mottaker og situasjon. Det innebærer videre å lære om sosiale konvensjoner og omgangsformer i engelskspråklige land og i internasjonale kontekster. Utvikling av muntlige ferdigheter i engelsk innebærer å bruke det muntlige språket gradvis mer presist og nyansert i samtaler og i andre typer muntlig kommunikasjon. Videre innebærer det å lytte til, forstå og drøfte forskjellige emner og problemstillinger for å tilegne seg fagkunnskap. Det innebærer også å kunne forstå varianter av muntlig engelsk fra forskjellige deler av verden.',
      'Å kunne skrive i engelsk er å kunne uttrykke ideer og meninger på en forståelig og hensiktsmessig måte ved å bruke det engelske skriftspråket. Det betyr å planlegge, utforme og bearbeide tekster som kommuniserer, og som har god struktur og sammenheng. I tillegg er skriving et redskap for språklæring. Utvikling av skriveferdigheter i engelsk innebærer å lære rettskriving og utvikle et økende repertoar av engelske ord og språklige strukturer. Videre innebærer det å utvikle en allsidig kompetanse i å skrive ulike typer generelle, litterære og faglige tekster på engelsk ved bruken av uformelt og formelt språk, tilpasset formål og mottaker.',
      'Å kunne lese i engelsk er å kunne skape mening ut fra ulike typer tekster. Det betyr å lese engelskspråklige tekster for å forstå, reflektere over og tilegne seg innsikt og kunnskap på tvers av kulturer og fagfelt. Det innebærer videre å forberede, utføre og bearbeide lesing av engelskspråklige tekster til forskjellige formål, og av varierende lengde og kompleksitet. Utvikling av leseferdigheter i engelsk er å ta i bruk lesestrategier tilpasset formålet med lesingen i stadig mer krevende tekster. Videre innebærer det å lese engelskspråklige tekster med flyt og forståelse, og utforske, drøfte, lære av og reflektere over ulike typer informasjon.',
      'Å kunne regne i engelsk er å kunne bruke relevante matematiske begreper på engelsk i ulike situasjoner. Det innebærer å kjenne til måleenheter som brukes i engelskspråklige land, og forstå og kommunisere om tall, grafiske framstillinger, tabeller og statistikk på engelsk. Utvikling av regneferdigheter i engelsk innebærer å bruke tall og regning ved å utvikle et repertoar av matematiske termer på engelsk knyttet til dagliglivet, og generelle og faglige emner.',
      'Digitale ferdigheter i engelsk er å kunne bruke et variert utvalg digitale verktøy, medier og ressurser for å styrke språklæringen, kommunisere på engelsk og tilegne seg relevant kunnskap i engelskfaget. Bruk av digitale ressurser gir mulighet for å oppleve engelskspråklige tekster i autentiske situasjoner, det vil si naturlige, ikke-tilpassede situasjoner. Utvikling av digitale ferdigheter innebærer å innhente og behandle informasjon for å skape ulike typer tekster. Digitale formkrav i tekster betyr at effekter, bilder, tabeller, overskrifter og punkter er satt sammen for å understreke og formidle et budskap. Det innebærer videre å bruke digitale kilder i skriftlige og muntlige tekster, og å ha en kritisk og selvstendig holdning til kildebruk. Digitale ferdigheter innebærer å utvikle kunnskap om opphavsrett og personvern gjennom etterprøvbare kildehenvisninger.'
    ],
    levels: [3, 4]
  },
  'ENG1-03-3': {
    ID: 'ENG1-03',
    title: 'Engelsk 5.-7.trinn',
    hours: 13680,
    chpaters: [
      {
        title: 'Språklæring',
        goals: [
          'Identifisere og bruke ulike situasjoner og læringsstrategier for å utvide egne ferdigheter i engelsk',
          'Beskrive eget arbeid med å lære engelsk',
          'Identifisere noen språklige likheter og ulikheter mellom engelsk og eget morsmål',
          'Bruke digitale ressurser og andre hjelpemidler i egen språklæring'
        ]
      },
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Bruke lytte- og talestrategier',
          'Forstå og bruke et ordforråd knyttet til kjente emner',
          'Forstå hovedinnholdet i muntlige tekster om kjente emner',
          'Bruke høflighetsuttrykk og situasjonsrelaterte uttrykk',
          'Uttrykke seg for å få hjelp til å forstå og bli forstått i ulike situasjoner',
          'Uttrykke og begrunne egen mening om kjente emner',
          'Innlede, holde i gang og avslutte samtaler knyttet til kjente situasjoner',
          'Bruke grunnleggende mønstre for uttale, intonasjon, ordbøying og setningstyper i kommunikasjon',
          'Uttrykke seg om enkle beregninger, valuta og måleenheter i kommunikasjon om dagligdagse situasjoner'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Bruke lese- og skrivestrategier',
          'Forstå og bruke et ordforråd knyttet til kjente emner',
          'Forstå hovedinnholdet i selvvalgte tekster',
          'Lese og forstå ulike typer tekster av varierende omfang fra forskjellige kilder',
          'Ta notater for å lage ulike typer tekster',
          'Skrive sammenhengende tekster som forteller, gjenforteller, beskriver opplevelser og uttrykker egne meninger',
          'Bruke grunnleggende mønstre for rettskriving, ordbøying, setnings- og tekstbygging i produksjon av tekst',
          'Bruke digitale verktøy og andre hjelpemidler for å finne relevant informasjon og lage ulike typer tekster'
        ]
      },
      {
        title: 'Kultur, samfunn og litteratur',
        goals: [
          'Fortelle om personer, steder og begivenheter fra engelskspråklige land',
          'Samtale om levesett og omgangsformer i ulike kulturer i engelskspråklige land og i Norge, deriblant samisk kultur',
          'Lese engelskspråklig barne- og ungdomslitteratur og samtale om personer og innhold',
          'Gi uttrykk for egne reaksjoner på engelskspråklige litterære tekster, film, nettkultur, bilder og musikk',
          'Uttrykke seg på en kreativ måte inspirert av ulike typer engelskspråklige litterære tekster fra forskjellige kilder',
          'Formidle korte tekster om selvvalgte emner'
        ]
      }
    ],
    purpose: [
      'Engelsk er et verdensspråk. I møte med mennesker fra andre land, hjemme eller på reiser, har vi ofte bruk for engelsk. Engelsk brukes i film, litteratur, sang, sport, handel, produkter, vitenskap, og teknologi, og gjennom disse bruksområdene har mange engelske ord og uttrykk funnet veien til våre egne språk. Når vi skal lete etter informasjon ut fra våre egne interesser eller i faglig sammenheng, skjer dette ofte på engelsk. I tillegg brukes engelsk i økende grad i utdanning og som arbeidsspråk i mange bedrifter.',
      'For å klare oss i en verden der engelsk benyttes i internasjonal kommunikasjon, er det nødvendig å kunne bruke det engelske språket og ha kunnskap om hvordan språket brukes i ulike sammenhenger. Det betyr å utvikle ordforråd og ferdigheter i å bruke språkets lydsystem, rettskriving, grammatikk og prinsipper for setnings- og tekstbygging, og kunne tilpasse språket til ulike emner og kommunikasjonssituasjoner. Dette innebærer å kunne skille mellom muntlig og skriftlig språkføring og formell og uformell språkbruk. Videre betyr det å kunne ta hensyn til kulturelle omgangsformer og høflighetskonvensjoner når vi bruker språket.',
      'Språklæringen skjer i møte med et mangfold av tekster, der tekstbegrepet brukes i vid forstand. Det omfatter muntlige og skriftlige framstillinger i forskjellige kombinasjoner, og ulike muntlige og skriftlige tekster fra digitale medier. Når vi er bevisste på strategier som brukes for å lære språk, og strategier som hjelper oss til å forstå og å bli forstått, blir tilegnelsen av kunnskaper og ferdigheter enklere og mer meningsfylt. Det er også viktig å sette egne mål for læringen, finne ut hvordan målene kan nås og vurdere egen språkbruk. Å lære engelsk bidrar til flerspråklighet og kan bli en viktig del av vår personlige utvikling.',
      'I tillegg til språklæring skal engelskfaget bidra til å gi innsikt i levesett og kulturer der engelsk er hovedspråk eller et offisielt språk. Faget skal gi innsikt i hvordan engelsk brukes som et internasjonalt kommunikasjonsmiddel. Å lære om engelskspråklige land og den stadig økende bruken av engelsk i ulike internasjonale kontekster, vil gi et godt grunnlag for å forstå verden omkring oss og utviklingen av engelsk som et verdensspråk. Engelskspråklige litterære tekster kan gi leseglede for livet og en dypere forståelse for andre og oss selv. Muntlige, skriftlige og digitale tekster, film, musikk og andre kulturuttrykk kan videre inspirere til egen utfoldelse og kreativitet.',
      'Engelskfaget er både et redskapsfag og et dannelsesfag. Det skal gi mulighet til å delta i kommunikasjon om personlige, sosiale, litterære og faglige emner. Faget skal bygge opp den generelle språkkompetansen gjennom å lytte, snakke, lese og skrive, og gi mulighet til å tilegne seg informasjon og fagkunnskap via det engelske språket. Utviklingen av kommunikative og språklige ferdigheter og kulturell innsikt kan fremme økt samhandling, forståelse og respekt mellom mennesker med ulik kulturbakgrunn. Slik blir språk- og kulturkompetanse en del av allmenndannelsen og bidrar til å styrke demokratisk engasjement og medborgerskap.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I engelsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i engelsk er å kunne lytte, tale og samhandle ved å bruke det engelske språket. Det betyr å vurdere og tilpasse uttrykksmåter til formål, mottaker og situasjon. Det innebærer videre å lære om sosiale konvensjoner og omgangsformer i engelskspråklige land og i internasjonale kontekster. Utvikling av muntlige ferdigheter i engelsk innebærer å bruke det muntlige språket gradvis mer presist og nyansert i samtaler og i andre typer muntlig kommunikasjon. Videre innebærer det å lytte til, forstå og drøfte forskjellige emner og problemstillinger for å tilegne seg fagkunnskap. Det innebærer også å kunne forstå varianter av muntlig engelsk fra forskjellige deler av verden.',
      'Å kunne skrive i engelsk er å kunne uttrykke ideer og meninger på en forståelig og hensiktsmessig måte ved å bruke det engelske skriftspråket. Det betyr å planlegge, utforme og bearbeide tekster som kommuniserer, og som har god struktur og sammenheng. I tillegg er skriving et redskap for språklæring. Utvikling av skriveferdigheter i engelsk innebærer å lære rettskriving og utvikle et økende repertoar av engelske ord og språklige strukturer. Videre innebærer det å utvikle en allsidig kompetanse i å skrive ulike typer generelle, litterære og faglige tekster på engelsk ved bruken av uformelt og formelt språk, tilpasset formål og mottaker.',
      'Å kunne lese i engelsk er å kunne skape mening ut fra ulike typer tekster. Det betyr å lese engelskspråklige tekster for å forstå, reflektere over og tilegne seg innsikt og kunnskap på tvers av kulturer og fagfelt. Det innebærer videre å forberede, utføre og bearbeide lesing av engelskspråklige tekster til forskjellige formål, og av varierende lengde og kompleksitet. Utvikling av leseferdigheter i engelsk er å ta i bruk lesestrategier tilpasset formålet med lesingen i stadig mer krevende tekster. Videre innebærer det å lese engelskspråklige tekster med flyt og forståelse, og utforske, drøfte, lære av og reflektere over ulike typer informasjon.',
      'Å kunne regne i engelsk er å kunne bruke relevante matematiske begreper på engelsk i ulike situasjoner. Det innebærer å kjenne til måleenheter som brukes i engelskspråklige land, og forstå og kommunisere om tall, grafiske framstillinger, tabeller og statistikk på engelsk. Utvikling av regneferdigheter i engelsk innebærer å bruke tall og regning ved å utvikle et repertoar av matematiske termer på engelsk knyttet til dagliglivet, og generelle og faglige emner.',
      'Digitale ferdigheter i engelsk er å kunne bruke et variert utvalg digitale verktøy, medier og ressurser for å styrke språklæringen, kommunisere på engelsk og tilegne seg relevant kunnskap i engelskfaget. Bruk av digitale ressurser gir mulighet for å oppleve engelskspråklige tekster i autentiske situasjoner, det vil si naturlige, ikke-tilpassede situasjoner. Utvikling av digitale ferdigheter innebærer å innhente og behandle informasjon for å skape ulike typer tekster. Digitale formkrav i tekster betyr at effekter, bilder, tabeller, overskrifter og punkter er satt sammen for å understreke og formidle et budskap. Det innebærer videre å bruke digitale kilder i skriftlige og muntlige tekster, og å ha en kritisk og selvstendig holdning til kildebruk. Digitale ferdigheter innebærer å utvikle kunnskap om opphavsrett og personvern gjennom etterprøvbare kildehenvisninger.'
    ],
    levels: [5, 6, 7]
  },
  'ENG1-03-4': {
    ID: 'ENG1-03-4',
    title: 'Engelsk 8.-10. trinn',
    hours: 13320,
    chapters: [
      {
        title: 'Språklæring',
        goals: [
          'Bruke ulike situasjoner, arbeidsmåter og læringsstrategier for å utvikle egne ferdigheter i engelsk',
          'Kommentere eget arbeid med å lære engelsk',
          'Identifisere vesentlige språklige likheter og ulikheter mellom engelsk og eget morsmål, og bruke dette i egen språklæring',
          'Velge ulike digitale ressurser og andre hjelpemidler, og bruke dem på en selvstendig måte i egen språklæring'
        ]
      },
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Velge og bruke ulike lytte- og talestrategier tilpasset formålet',
          'Forstå og bruke et generelt ordforråd knyttet til forskjellige emner',
          'Vise evne til å skille mellom positivt og negativt ladede uttrykk som refererer til enkeltindivider og grupper av mennesker',
          'Forstå hovedinnhold og detaljer i ulike typer muntlige tekster om forskjellige emner',
          'Lytte til og forstå varianter av engelsk fra forskjellige autentiske situasjoner',
          'Uttrykke seg med flyt og sammenheng tilpasset formål og situasjon',
          'Uttrykke og begrunne egen mening om forskjellige emner',
          'Innlede, holde i gang og avslutte samtaler om forskjellige emner ved å stille spørsmål og følge opp innspill',
          'Bruke sentrale mønstre for uttale, intonasjon, ordbøying og ulike setningstyper i kommunikasjon',
          'Forstå og bruke ulike uttrykk for tall og andre data i kommunikasjon'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Velge og bruke ulike lese- og skrivestrategier tilpasset formålet',
          'Forstå og bruke et generelt ordforråd knyttet til forskjellige emner',
          'Vise evne til å skille mellom positivt og negativt ladede uttrykk som refererer til enkeltindivider og grupper av mennesker',
          'Forstå hovedinnhold og detaljer i selvvalgte tekster',
          'Lese, forstå og vurdere ulike typer tekster av varierende omfang om forskjellige emner',
          'Bruke egne notater og forskjellige kilder som grunnlag for skriving',
          'Skrive ulike typer tekster med struktur og sammenheng',
          'Bruke sentrale mønstre for rettskriving, ordbøying, setnings- og tekstbygging i produksjon av tekst',
          'Bruke digitale verktøy og formkrav for informasjonsbehandling, tekstproduksjon og kommunikasjon',
          'Kjenne til personvern og opphavsrett og velge og bruke innhold fra forskjellige kilder på en etterprøvbar måte'
        ]
      },
      {
        title: 'Kultur, samfunn og litteratur',
        goals: [
          'Drøfte levesett og omgangsformer i Storbritannia, USA, andre engelskspråklige land og Norge',
          'Gjøre rede for trekk ved historie og geografi i Storbritannia og USA',
          'Drøfte ulike typer engelskspråklige litterære tekster fra engelskspråklige land',
          'Beskrive og reflektere over situasjonen til urfolk i engelskspråklige land',
          'Lage, formidle og samtale om egne tekster inspirert av engelskspråklig litteratur, film og kulturelle uttrykksformer',
          'Samtale om og formidle aktuelle og faglige emner'
        ]
      }
    ],
    purpose: [
      'Engelsk er et verdensspråk. I møte med mennesker fra andre land, hjemme eller på reiser, har vi ofte bruk for engelsk. Engelsk brukes i film, litteratur, sang, sport, handel, produkter, vitenskap, og teknologi, og gjennom disse bruksområdene har mange engelske ord og uttrykk funnet veien til våre egne språk. Når vi skal lete etter informasjon ut fra våre egne interesser eller i faglig sammenheng, skjer dette ofte på engelsk. I tillegg brukes engelsk i økende grad i utdanning og som arbeidsspråk i mange bedrifter.',
      'For å klare oss i en verden der engelsk benyttes i internasjonal kommunikasjon, er det nødvendig å kunne bruke det engelske språket og ha kunnskap om hvordan språket brukes i ulike sammenhenger. Det betyr å utvikle ordforråd og ferdigheter i å bruke språkets lydsystem, rettskriving, grammatikk og prinsipper for setnings- og tekstbygging, og kunne tilpasse språket til ulike emner og kommunikasjonssituasjoner. Dette innebærer å kunne skille mellom muntlig og skriftlig språkføring og formell og uformell språkbruk. Videre betyr det å kunne ta hensyn til kulturelle omgangsformer og høflighetskonvensjoner når vi bruker språket.',
      'Språklæringen skjer i møte med et mangfold av tekster, der tekstbegrepet brukes i vid forstand. Det omfatter muntlige og skriftlige framstillinger i forskjellige kombinasjoner, og ulike muntlige og skriftlige tekster fra digitale medier. Når vi er bevisste på strategier som brukes for å lære språk, og strategier som hjelper oss til å forstå og å bli forstått, blir tilegnelsen av kunnskaper og ferdigheter enklere og mer meningsfylt. Det er også viktig å sette egne mål for læringen, finne ut hvordan målene kan nås og vurdere egen språkbruk. Å lære engelsk bidrar til flerspråklighet og kan bli en viktig del av vår personlige utvikling.',
      'I tillegg til språklæring skal engelskfaget bidra til å gi innsikt i levesett og kulturer der engelsk er hovedspråk eller et offisielt språk. Faget skal gi innsikt i hvordan engelsk brukes som et internasjonalt kommunikasjonsmiddel. Å lære om engelskspråklige land og den stadig økende bruken av engelsk i ulike internasjonale kontekster, vil gi et godt grunnlag for å forstå verden omkring oss og utviklingen av engelsk som et verdensspråk. Engelskspråklige litterære tekster kan gi leseglede for livet og en dypere forståelse for andre og oss selv. Muntlige, skriftlige og digitale tekster, film, musikk og andre kulturuttrykk kan videre inspirere til egen utfoldelse og kreativitet.',
      'Engelskfaget er både et redskapsfag og et dannelsesfag. Det skal gi mulighet til å delta i kommunikasjon om personlige, sosiale, litterære og faglige emner. Faget skal bygge opp den generelle språkkompetansen gjennom å lytte, snakke, lese og skrive, og gi mulighet til å tilegne seg informasjon og fagkunnskap via det engelske språket. Utviklingen av kommunikative og språklige ferdigheter og kulturell innsikt kan fremme økt samhandling, forståelse og respekt mellom mennesker med ulik kulturbakgrunn. Slik blir språk- og kulturkompetanse en del av allmenndannelsen og bidrar til å styrke demokratisk engasjement og medborgerskap.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I engelsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i engelsk er å kunne lytte, tale og samhandle ved å bruke det engelske språket. Det betyr å vurdere og tilpasse uttrykksmåter til formål, mottaker og situasjon. Det innebærer videre å lære om sosiale konvensjoner og omgangsformer i engelskspråklige land og i internasjonale kontekster. Utvikling av muntlige ferdigheter i engelsk innebærer å bruke det muntlige språket gradvis mer presist og nyansert i samtaler og i andre typer muntlig kommunikasjon. Videre innebærer det å lytte til, forstå og drøfte forskjellige emner og problemstillinger for å tilegne seg fagkunnskap. Det innebærer også å kunne forstå varianter av muntlig engelsk fra forskjellige deler av verden.',
      'Å kunne skrive i engelsk er å kunne uttrykke ideer og meninger på en forståelig og hensiktsmessig måte ved å bruke det engelske skriftspråket. Det betyr å planlegge, utforme og bearbeide tekster som kommuniserer, og som har god struktur og sammenheng. I tillegg er skriving et redskap for språklæring. Utvikling av skriveferdigheter i engelsk innebærer å lære rettskriving og utvikle et økende repertoar av engelske ord og språklige strukturer. Videre innebærer det å utvikle en allsidig kompetanse i å skrive ulike typer generelle, litterære og faglige tekster på engelsk ved bruken av uformelt og formelt språk, tilpasset formål og mottaker.',
      'Å kunne lese i engelsk er å kunne skape mening ut fra ulike typer tekster. Det betyr å lese engelskspråklige tekster for å forstå, reflektere over og tilegne seg innsikt og kunnskap på tvers av kulturer og fagfelt. Det innebærer videre å forberede, utføre og bearbeide lesing av engelskspråklige tekster til forskjellige formål, og av varierende lengde og kompleksitet. Utvikling av leseferdigheter i engelsk er å ta i bruk lesestrategier tilpasset formålet med lesingen i stadig mer krevende tekster. Videre innebærer det å lese engelskspråklige tekster med flyt og forståelse, og utforske, drøfte, lære av og reflektere over ulike typer informasjon.',
      'Å kunne regne i engelsk er å kunne bruke relevante matematiske begreper på engelsk i ulike situasjoner. Det innebærer å kjenne til måleenheter som brukes i engelskspråklige land, og forstå og kommunisere om tall, grafiske framstillinger, tabeller og statistikk på engelsk. Utvikling av regneferdigheter i engelsk innebærer å bruke tall og regning ved å utvikle et repertoar av matematiske termer på engelsk knyttet til dagliglivet, og generelle og faglige emner.',
      'Digitale ferdigheter i engelsk er å kunne bruke et variert utvalg digitale verktøy, medier og ressurser for å styrke språklæringen, kommunisere på engelsk og tilegne seg relevant kunnskap i engelskfaget. Bruk av digitale ressurser gir mulighet for å oppleve engelskspråklige tekster i autentiske situasjoner, det vil si naturlige, ikke-tilpassede situasjoner. Utvikling av digitale ferdigheter innebærer å innhente og behandle informasjon for å skape ulike typer tekster. Digitale formkrav i tekster betyr at effekter, bilder, tabeller, overskrifter og punkter er satt sammen for å understreke og formidle et budskap. Det innebærer videre å bruke digitale kilder i skriftlige og muntlige tekster, og å ha en kritisk og selvstendig holdning til kildebruk. Digitale ferdigheter innebærer å utvikle kunnskap om opphavsrett og personvern gjennom etterprøvbare kildehenvisninger.'
    ],
    levels: [8, 9, 10]
  },
  'FSP1-01': {
    ID: 'FSP1-01',
    title: 'Fremmedspråk nivå I',
    hours: 13620,
    chapters: [
      {
        title: 'Språklæring',
        goals: [
          'Utnytte egne erfaringer med språklæring i læring av det nye språket',
          'Undersøke likheter og ulikheter mellom morsmålet og det nye språket og utnytte dette i egen språklæring',
          'Bruke digitale verktøy og andre hjelpemidler',
          'Beskrive og vurdere eget arbeid med å lære det nye språket'
        ]
      },
      {
        title: 'Kommunikasjon',
        goals: [
          'Bruke språkets alfabet og tegn',
          'Finne relevante opplysninger og forstå hovedinnholdet i skriftlige og muntlige tilpassede og autentiske tekster i ulike sjangere',
          'Delta i enkle, spontane samtalesituasjoner',
          'Presentere ulike emner muntlig',
          'Gi uttrykk for egne meninger og følelser',
          'Forstå og bruke tall i praktiske situasjoner',
          'Kommunisere med forståelig uttale',
          'Forstå og bruke et ordforråd som dekker dagligdagse situasjoner',
          'Bruke grunnleggende språklige strukturer og former for tekstbinding',
          'Tilpasse språkbruken i noen grad til ulike kommunikasjonssituasjoner',
          'Skrive tekster som forteller, beskriver eller informerer',
          'Bruke lytte-, tale-, lese- og skrivestrategier tilpasset formålet',
          'Bruke kommunikasjonsteknologi til samarbeid og møte med autentisk språk'
        ]
      },
      {
        title: 'Språk, kultur og samfunn',
        goals: [
          'Samtale om dagligliv, personer og aktuelle hendelser i språkområdet og i Norge',
          'Sammenligne noen sider ved tradisjoner, skikker og levemåter i språkområdet og i Norge',
          'Samtale om språk og sider ved geografiske forhold i språkområdet',
          'Gi uttrykk for opplevelser knyttet til språkområdets kultur'
        ]
      }
    ],
    purpose: [
      'Språk åpner dører. Når vi lærer andre språk, får vi mulighet til å komme i kontakt med andre mennesker og kulturer, og dette kan øke vår forståelse for hvordan mennesker lever og ­tenker.',
      'Språkkompetanse gir mulighet for deltakelse i internasjonale sammenhenger og bidrar til å gi et mer nyansert bilde av internasjonale prosesser og hendelser. I en verden med økende mobilitet og digital samhandling er kompetanse i flere språk og interkulturell kompetanse en forutsetning for kommunikasjon og deltakelse på mange områder.',
      'Å lære et fremmedspråk dreier seg først og fremst om å bruke språket – å lese, lytte, snakke og skrive – i forskjellige sammenhenger. I tillegg innebærer det kommunikasjon om og innsikt i andres levemåter, livssyn, verdier og kulturer, noe som kan føre til større bevissthet om egen kultur og nye muligheter til opplevelser. Opplevelse av og innsikt i kulturelle ­forhold er en kilde til personlig vekst og dannelse og kan gi større muligheter i utdanning, arbeidsliv og fritid.',
      'Læring av et nytt fremmedspråk bygger på erfaringer fra tidligere språklæring både i og utenfor skolen. Når vi er bevisst hvilke strategier som brukes for å lære fremmedspråk, og hvilke strategier som hjelper oss til å forstå og å bli forstått, blir tilegnelsen av kunnskaper og ferdigheter enklere og mer meningsfylt. Det er også viktig å sette seg mål for læringen, finne ut hvordan disse kan nås og vurdere egen språkbruk. Det å utvikle evnen til å lære et fremmedspråk kan føre til bedre innsikt også i morsmålet og blir derfor en viktig del av den enkeltes personlige utvikling. Kompetanse i fremmedspråk skal fremme motivasjon for læring og innsikt i flere språk og kulturer, bidra til flerspråklighet og gi et viktig grunnlag for livslang læring.',
      'Kommunikative ferdigheter og kulturell innsikt kan fremme økt samhandling, forståelse og respekt mellom mennesker med ulik kulturbakgrunn. Slik ivaretar språk- og kulturkompetanse det allmenndannende perspektivet og bidrar til å styrke demokratisk engasjement og medborgerskap.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I fremmedspråk forstås grunnleggende ferdigheter slik:',
      'Å kunne uttrykke seg skriftlig og muntlig i fremmedspråk er sentralt i utviklingen av kompetanse i fremmedspråket og går igjen i kompetansemålene for begge trinn. Disse ­ferdighetene er viktige redskaper i arbeidet med å forstå og ta i bruk det nye språket i stadig mer varierte og krevende sammenhenger på tvers av kulturer og fagfelt. Muntlige ferdigheter innebærer både å kunne lytte og å kunne tale.',
      'Å kunne lese i fremmedspråk er en del av den praktiske språkkompetansen og innebærer å forstå, utforske og reflektere over stadig mer krevende tekster, og slik skaffe seg innsikt på tvers av kulturer og fagfelt. Å utvikle leseferdighet i det nye språket vil også bidra til å styrke leseferdigheten generelt.',
      'Å kunne regne i fremmedspråk er en forutsetning for å forstå og bruke fremmedspråket i forbindelse med kvantifisering, beregninger, målinger og grafiske framstillinger i hverdagslige sammenhenger.',
      'Å kunne bruke digitale verktøy i fremmedspråk bidrar til å utvide læringsarenaen for faget og tilfører læringsprosessen verdifulle dimensjoner gjennom muligheter for møte med autentisk språk og anvendelse av språket i autentiske kommunikasjonssituasjoner. Kilde­kritikk, opphavsrett og personvern er sentrale områder i digitale sammenhenger som også inngår i fremmedspråk.'
    ],
    levels: [8, 9, 10, 11, 12, 13]
  },
  'MAT1-04-1': {
    ID: 'MAT1-04-1',
    title: 'Matematikk 1. - 2. trinn',
    hours: 16800,
    chapters: [
      {
        title: 'Tal',
        goals: [
          'Telje til 100, dele opp og byggje mengder opp til 10, setje saman og dele opp tiargrupper opp til 100 og dele tosifra tal i tiarar og einarar',
          'Bruke tallinja til berekningar og til å vise talstorleikar',
          'Gjere overslag over mengder, telje opp, samanlikne tal og uttrykkje talstorleikar på varierte måtar',
          'Utvikle, bruke og samtale om varierte reknestrategiar for addisjon og subtraksjon av tosifra tal og vurdere kor rimelege svara er',
          'Doble og halvere',
          'Kjenne att, samtale om og vidareføre strukturar i enkle talmønster'
        ]
      },
      {
        title: 'Geometri',
        goals: [
          'Kjenne att og beskrive trekk ved enkle to- og tredimensjonale figurar i samband med hjørne, kantar og flater og sortere og setje namn på figurane etter desse trekka',
          'Kjenne att, bruke og samtale om spegelsymmetri i praktiske situasjonar',
          'Lage og utforske geometriske mønster, både med og utan digitale verktøy, og beskrive dei munnleg'
        ]
      },
      {
        title: 'Måling',
        goals: [
          'Måle og samanlikne storleikar som gjeld lengd og areal, ved hjelp av ikkje-standardiserte og standardiserte måleiningar, beskrive korleis og samtale om resultata',
          'Nemne dagar, månader og enkle klokkeslett',
          'Kjenne att norske myntar og setlar opp til 100 og bruke dei i kjøp og sal'
        ]
      },
      {
        title: 'Statistikk',
        goals: [
          'Samle, sortere, notere og illustrere data med teljestrekar, tabellar og søylediagram og samtale om prosessen og kva illustrasjonane fortel om datamaterialet'
        ]
      }

    ],
    purpose: [
      'Matematikk er ein del av den globale kulturarven vår. Mennesket har til alle tider brukt og utvikla matematikk for å systematisere erfaringar, for å beskrive og forstå samanhengar i naturen og i samfunnet og for å utforske universet. Ei anna inspirasjonskjelde til utviklinga av faget har vore glede hos menneske over arbeid med matematikk i seg sjølv. Faget grip inn i mange vitale samfunnsområde, som medisin, økonomi, teknologi, kommunikasjon, energiforvalting og byggjeverksemd. Solid kompetanse i matematikk er dermed ein føresetnad for utvikling av samfunnet. Eit aktivt demokrati treng borgarar som kan setje seg inn i, forstå og kritisk vurdere kvantitativ informasjon, statistiske analysar og økonomiske prognosar. På den måten er matematisk kompetanse nødvendig for å forstå og kunne påverke prosessar i samfunnet.',
      'Matematisk kompetanse inneber å bruke problemløysing og modellering til å analysere og omforme eit problem til matematisk form, løyse det og vurdere kor gyldig løysinga er. Dette har òg språklege aspekt, som det å formidle, samtale om og resonnere omkring idear. I det meste av matematisk aktivitet nyttar ein hjelpemiddel og teknologi. Både det å kunne bruke og vurdere ulike hjelpemiddel og det å kjenne til avgrensinga deira er viktige delar av faget. Kompetanse i matematikk er ein viktig reiskap for den einskilde, og faget kan leggje grunnlag for å ta vidare utdanning og for deltaking i yrkesliv og fritidsaktivitetar. Matematikk ligg til grunn for store delar av kulturhistoria vår og utviklinga av logisk tenking. På den måten spelar faget ei sentral rolle i den allmenne danninga ved å påverke identitet, tenkjemåte og sjølvforståing.',
      'Matematikkfaget i skolen medverkar til å utvikle den matematiske kompetansen som samfunnet og den einskilde treng. For å oppnå dette må elevane få høve til å arbeide både praktisk og teoretisk. Opplæringa vekslar mellom utforskande, leikande, kreative og problemløysande aktivitetar og ferdigheitstrening. I praktisk bruk viser matematikk sin nytte som reiskapsfag. I skolearbeidet utnyttar ein sentrale idear, former, strukturar og samanhengar i faget. Elevane må utfordrast til å kommunisere matematikk skriftleg, munnleg og digitalt. Det må leggjast til rette for at både jenter og gutar får rike erfaringar med matematikkfaget, som skaper positive haldningar og ein solid fagkompetanse. Slik blir det lagt eit grunnlag for livslang læring.'
    ],
    skills: [
      'Grunnleggjande ferdigheiter er integrerte i kompetansemåla, der dei medverkar til utvikling av og er ein del av fagkompetansen. I matematikk forstår ein grunnleggjande ferdigheiter slik:',
      'Munnlege ferdigheiter i matematikk inneber å skape meining gjennom å lytte, tale og samtale om matematikk. Det inneber å gjere seg opp ei meining, stille spørsmål og argumentere ved hjelp av både eit uformelt språk, presis fagterminologi og omgrepsbruk. Det vil seie å vere med i samtalar, kommunisere idear og drøfte matematiske problem, løysingar og strategiar med andre. Utvikling i munnlege ferdigheiter i matematikk går frå å delta i samtalar om matematikk til å presentere og drøfte komplekse faglege emne. Vidare går utviklinga frå å bruke eit enkelt matematisk språk til å bruke presis fagterminologi og uttrykksmåte og presise omgrep.',
      'Å kunne skrive i matematikk inneber å beskrive og forklare ein tankegang og setje ord på oppdagingar og idear. Det inneber å bruke matematiske symbol og det formelle matematiske språket til å løyse problem og presentere løysingar. Vidare vil det seie å lage teikningar, skisser, figurar, grafar, tabellar og diagram som er tilpassa mottakaren og situasjonen. Skriving i matematikk er ein reiskap for å utvikle eigne tankar og eiga læring. Utvikling i å skrive i matematikk går frå å bruke enkle uttrykksformer til gradvis å ta i bruk eit formelt symbolspråk og ein presis fagterminologi. Vidare går utviklinga frå å beskrive og systematisere enkle situasjonar med matematikkfagleg innhald til å byggje opp ein heilskapleg argumentasjon omkring komplekse samanhengar.',
      'Å kunne lese i matematikk inneber å forstå og bruke symbolspråk og uttrykksformer for å skape meining i tekstar frå daglegliv og yrkesliv så vel som matematikkfaglege tekstar. Matematikkfaget er prega av samansette tekstar som inneheld matematiske uttrykk, grafar, diagram, tabellar, symbol, formlar og logiske resonnement. Lesing i matematikk inneber å sortere informasjon, analysere og vurdere form og innhald og samanfatte informasjon frå ulike element i tekstar. Utvikling i å lese i matematikk går frå å finne og bruke informasjon i tekstar med enkelt symbolspråk til å finne meining og reflektere over komplekse fagtekstar med avansert symbolspråk og omgrepsbruk.',
      'Å kunne rekne i matematikk inneber å bruke symbolspråk, matematiske omgrep, framgangsmåtar og varierte strategiar til problemløysing og utforsking som tek utgangspunkt både i praktiske, daglegdagse situasjonar og i matematiske problem. Dette inneber å kjenne att og beskrive situasjonar der matematikk inngår, og bruke matematiske metodar til å behandle problemstillingar. Eleven må òg kommunisere og vurdere kor gyldige løysingane er. Utvikling av å rekne i matematikk går frå grunnleggjande talforståing og å kjenne att og løyse problem ut frå enkle situasjonar til å analysere og løyse eit spekter av komplekse problem med eit variert utval av strategiar og metodar. Vidare inneber dette i aukande grad å bruke ulike hjelpemiddel i berekningar, modellering og kommunikasjon.',
      'Digitale ferdigheiter i matematikk inneber å bruke digitale verktøy til læring gjennom spel, utforsking, visualisering og presentasjon. Det handlar òg om å kjenne til, bruke og vurdere digitale verktøy til berekningar, problemløysing, simulering og modellering. Vidare vil det seie å finne informasjon, analysere, behandle og presentere data med formålstenlege verktøy, og vere kritisk til kjelder, analysar og resultat. Utvikling i digitale ferdigheiter inneber å arbeide med samansette digitale tekstar med aukande grad av kompleksitet. Vidare inneber det å bli stadig meir merksam på den nytten digitale verktøy har for læring i matematikkfaget.'
    ],
    levels: [1, 2]
  },
  'MAT1-04-2': {
    ID: 'MAT1-04-2',
    title: 'Matematikk 3.-4.trinn',
    hours: 16800,
    chapters: [
      {
        title: 'Tal',
        goals: [
          'Beskrive og bruke plassverdisystemet for dei heile tala, bruke positive og negative heile tal, enkle brøkar og desimaltal i praktiske samanhengar og uttrykkje talstorleikar på varierte måtar',
          'Gjere overslag over og finne tal ved hjelp av hovudrekning, teljemateriell og skriftlege notat, gjennomføre overslagsrekning og vurdere svar',
          'Utvikle, bruke og samtale om ulike reknemetodar for addisjon og subtraksjon av fleirsifra tal både i hovudet og på papiret',
          'Utvikle og bruke varierte metodar for multiplikasjon og divisjon, bruke dei i praktiske situasjonar og bruke den vesle multiplikasjonstabellen i hovudrekning og i oppgåveløysing',
          'Finne informasjon i tekstar eller praktiske samanhengar, velje rekneart og grunngje valet, bruke tabellkunnskap og utnytte samanhengar mellom rekneartane, vurdere resultatet og presentere løysinga',
          'Kjenne att, eksperimentere med, beskrive og vidareføre strukturar i talmønster',
          'Bruke matematiske symbol og uttrykksmåtar for å uttrykkje matematiske samanhengar i oppgåveløysing'
        ]
      },
      {
        title: 'Geometri',
        goals: [
          'Kjenne att, beskrive trekk ved og sortere sirklar, mangekantar, kuler, sylindrar og polyeder',
          'Teikne, byggje, utforske og beskrive geometriske figurar og modellar i praktiske samanhengar, medrekna teknologi og design',
          'Kjenne att, bruke og beskrive spegelsymmetri og parallellforskyving i konkrete situasjonar',
          'Lage og utforske geometriske mønster og beskrive dei munnleg',
          'Lese av, plassere og beskrive posisjonar i rutenett, på kart og i koordinatsystem, både med og utan digitale verktøy'
        ]
      },
      {
        title: 'Måling',
        goals: [
          'Gjere overslag over og måle lengd, areal, volum, masse, temperatur, tid og vinklar, samtale om resultata og vurdere om dei er rimelege',
          'Bruke ikkje-standardiserte måleiningar og forklare formålet med å standardisere måleiningar og bruke og gjere om mellom vanlege måleiningar',
          'Samanlikne storleikar ved hjelp av høvelege målereiskapar og enkel berekning, presentere resultata og vurdere om dei er rimelege',
          'Løyse praktiske oppgåver som gjeld kjøp og sal'
        ]
      },
      {
        title: 'Statistikk',
        goals: [
          'Samle, sortere, notere og illustrere data på formålstenlege måtar med teljestrekar, tabellar og søylediagram, med og utan digitale verktøy, og samtale om prosess og framstilling'
        ]
      }
    ],
    purpose: [
      'Matematikk er ein del av den globale kulturarven vår. Mennesket har til alle tider brukt og utvikla matematikk for å systematisere erfaringar, for å beskrive og forstå samanhengar i naturen og i samfunnet og for å utforske universet. Ei anna inspirasjonskjelde til utviklinga av faget har vore glede hos menneske over arbeid med matematikk i seg sjølv. Faget grip inn i mange vitale samfunnsområde, som medisin, økonomi, teknologi, kommunikasjon, energiforvalting og byggjeverksemd. Solid kompetanse i matematikk er dermed ein føresetnad for utvikling av samfunnet. Eit aktivt demokrati treng borgarar som kan setje seg inn i, forstå og kritisk vurdere kvantitativ informasjon, statistiske analysar og økonomiske prognosar. På den måten er matematisk kompetanse nødvendig for å forstå og kunne påverke prosessar i samfunnet.',
      'Matematisk kompetanse inneber å bruke problemløysing og modellering til å analysere og omforme eit problem til matematisk form, løyse det og vurdere kor gyldig løysinga er. Dette har òg språklege aspekt, som det å formidle, samtale om og resonnere omkring idear. I det meste av matematisk aktivitet nyttar ein hjelpemiddel og teknologi. Både det å kunne bruke og vurdere ulike hjelpemiddel og det å kjenne til avgrensinga deira er viktige delar av faget. Kompetanse i matematikk er ein viktig reiskap for den einskilde, og faget kan leggje grunnlag for å ta vidare utdanning og for deltaking i yrkesliv og fritidsaktivitetar. Matematikk ligg til grunn for store delar av kulturhistoria vår og utviklinga av logisk tenking. På den måten spelar faget ei sentral rolle i den allmenne danninga ved å påverke identitet, tenkjemåte og sjølvforståing.',
      'Matematikkfaget i skolen medverkar til å utvikle den matematiske kompetansen som samfunnet og den einskilde treng. For å oppnå dette må elevane få høve til å arbeide både praktisk og teoretisk. Opplæringa vekslar mellom utforskande, leikande, kreative og problemløysande aktivitetar og ferdigheitstrening. I praktisk bruk viser matematikk sin nytte som reiskapsfag. I skolearbeidet utnyttar ein sentrale idear, former, strukturar og samanhengar i faget. Elevane må utfordrast til å kommunisere matematikk skriftleg, munnleg og digitalt. Det må leggjast til rette for at både jenter og gutar får rike erfaringar med matematikkfaget, som skaper positive haldningar og ein solid fagkompetanse. Slik blir det lagt eit grunnlag for livslang læring.'
    ],
    skills: [
      'Grunnleggjande ferdigheiter er integrerte i kompetansemåla, der dei medverkar til utvikling av og er ein del av fagkompetansen. I matematikk forstår ein grunnleggjande ferdigheiter slik:',
      'Munnlege ferdigheiter i matematikk inneber å skape meining gjennom å lytte, tale og samtale om matematikk. Det inneber å gjere seg opp ei meining, stille spørsmål og argumentere ved hjelp av både eit uformelt språk, presis fagterminologi og omgrepsbruk. Det vil seie å vere med i samtalar, kommunisere idear og drøfte matematiske problem, løysingar og strategiar med andre. Utvikling i munnlege ferdigheiter i matematikk går frå å delta i samtalar om matematikk til å presentere og drøfte komplekse faglege emne. Vidare går utviklinga frå å bruke eit enkelt matematisk språk til å bruke presis fagterminologi og uttrykksmåte og presise omgrep.',
      'Å kunne skrive i matematikk inneber å beskrive og forklare ein tankegang og setje ord på oppdagingar og idear. Det inneber å bruke matematiske symbol og det formelle matematiske språket til å løyse problem og presentere løysingar. Vidare vil det seie å lage teikningar, skisser, figurar, grafar, tabellar og diagram som er tilpassa mottakaren og situasjonen. Skriving i matematikk er ein reiskap for å utvikle eigne tankar og eiga læring. Utvikling i å skrive i matematikk går frå å bruke enkle uttrykksformer til gradvis å ta i bruk eit formelt symbolspråk og ein presis fagterminologi. Vidare går utviklinga frå å beskrive og systematisere enkle situasjonar med matematikkfagleg innhald til å byggje opp ein heilskapleg argumentasjon omkring komplekse samanhengar.',
      'Å kunne lese i matematikk inneber å forstå og bruke symbolspråk og uttrykksformer for å skape meining i tekstar frå daglegliv og yrkesliv så vel som matematikkfaglege tekstar. Matematikkfaget er prega av samansette tekstar som inneheld matematiske uttrykk, grafar, diagram, tabellar, symbol, formlar og logiske resonnement. Lesing i matematikk inneber å sortere informasjon, analysere og vurdere form og innhald og samanfatte informasjon frå ulike element i tekstar. Utvikling i å lese i matematikk går frå å finne og bruke informasjon i tekstar med enkelt symbolspråk til å finne meining og reflektere over komplekse fagtekstar med avansert symbolspråk og omgrepsbruk.',
      'Å kunne rekne i matematikk inneber å bruke symbolspråk, matematiske omgrep, framgangsmåtar og varierte strategiar til problemløysing og utforsking som tek utgangspunkt både i praktiske, daglegdagse situasjonar og i matematiske problem. Dette inneber å kjenne att og beskrive situasjonar der matematikk inngår, og bruke matematiske metodar til å behandle problemstillingar. Eleven må òg kommunisere og vurdere kor gyldige løysingane er. Utvikling av å rekne i matematikk går frå grunnleggjande talforståing og å kjenne att og løyse problem ut frå enkle situasjonar til å analysere og løyse eit spekter av komplekse problem med eit variert utval av strategiar og metodar. Vidare inneber dette i aukande grad å bruke ulike hjelpemiddel i berekningar, modellering og kommunikasjon.',
      'Digitale ferdigheiter i matematikk inneber å bruke digitale verktøy til læring gjennom spel, utforsking, visualisering og presentasjon. Det handlar òg om å kjenne til, bruke og vurdere digitale verktøy til berekningar, problemløysing, simulering og modellering. Vidare vil det seie å finne informasjon, analysere, behandle og presentere data med formålstenlege verktøy, og vere kritisk til kjelder, analysar og resultat. Utvikling i digitale ferdigheiter inneber å arbeide med samansette digitale tekstar med aukande grad av kompleksitet. Vidare inneber det å bli stadig meir merksam på den nytten digitale verktøy har for læring i matematikkfaget.'
    ],
    levels: [3, 4]
  },
  'MAT1-04-3': {
    ID: 'MAT1-04-3',
    title: 'Matematikk 5.-7.trinn',
    hours: 19680,
    chapters: [
      {
        title: 'Tal og algebra',
        goals: [
          'Beskrive og bruke plassverdisystemet for desimaltal, rekne med positive og negative heile tal, desimaltal, brøkar og prosent og plassere dei ulike storleikane på tallina',
          'Finne samnemnar (bm.: fellesnevner) og utføre addisjon, subtraksjon og multiplikasjon av brøkar',
          'Utvikle, bruke og diskutere metodar for hovudrekning, overslagsrekning og skriftleg rekning og bruke digitale verktøy i berekningar',
          'Beskrive referansesystemet og notasjonen som blir nytta for formlar i eit rekneark, og bruke rekneark til å utføre og presentere berekningar',
          'Finne informasjon i tekstar eller praktiske samanhengar, stille opp og forklare berekningar og framgangsmåtar, vurdere resultatet og presentere og diskutere løysinga',
          'Utforske og beskrive strukturar og forandringar i geometriske mønster og talmønster med figurar, ord og formlar',
          'Stille opp og løyse enkle likningar og løyse opp og rekne med parentesar i addisjon, subtraksjon og multiplikasjon av tal'
        ]
      },
      {
        title: 'Geometri',
        goals: [
          'Analysere eigenskapar ved to- og tredimensjonale figurar og beskrive fysiske gjenstandar innanfor daglegliv og teknologi ved hjelp av geometriske omgrep',
          'Byggje tredimensjonale modellar, teikne perspektiv med eitt forsvinningspunkt og diskutere prosessane og produkta',
          'Beskrive og gjennomføre spegling, rotasjon og parallellforskyving',
          'Beskrive plassering og flytting i rutenett, på kart og i koordinatsystem, med og utan digitale hjelpemiddel, og bruke koordinatar til å berekne avstandar parallelt med aksane i eit koordinatsystem'
        ]
      },
      {
        title: 'Måling',
        goals: [
          'Velje høvelege målereiskapar og gjere praktiske målingar i samband med daglegliv og teknologi og vurdere resultata ut frå presisjon og måleusikkerheit',
          'Gjere overslag over og måle storleikar for lengd, areal, masse, volum, vinkel og tid og bruke tidspunkt og tidsintervall i enkle berekningar, diskutere resultata og vurdere kor rimelege dei er',
          'Velje høvelege måleiningar og rekne om mellom ulike måleiningar',
          'Forklare oppbygginga av mål for lengd, areal og volum og berekne omkrins, areal, overflate og volum av to- og tredimensjonale figurar',
          'Bruke målestokk til å berekne avstandar og lage og samtale om kart og arbeidsteikningar, med og utan digitale verktøy',
          'Bruke forhold i praktiske samanhengar, rekne med fart og rekne om mellom valutaer'
        ]
      },
      {
        title: 'Statistikk og sansyn',
        goals: [
          'Planleggje og samle inn data i samband med observasjonar, spørjeundersøkingar og eksperiment',
          'Representere data i tabellar og diagram som er framstilte med og utan digitale verktøy, lese og tolke framstillingane og vurdere kor nyttige dei er',
          'Finne median, typetal og gjennomsnitt i enkle datasett og vurdere dei ulike sentralmåla i forhold til kvarandre',
          'Vurdere og samtale om sjansar i daglegdagse samanhengar, spel og eksperiment og berekne sannsyn i enkle situasjonar'
        ]
      }
    ],
    purpose: [
      'Matematikk er ein del av den globale kulturarven vår. Mennesket har til alle tider brukt og utvikla matematikk for å systematisere erfaringar, for å beskrive og forstå samanhengar i naturen og i samfunnet og for å utforske universet. Ei anna inspirasjonskjelde til utviklinga av faget har vore glede hos menneske over arbeid med matematikk i seg sjølv. Faget grip inn i mange vitale samfunnsområde, som medisin, økonomi, teknologi, kommunikasjon, energiforvalting og byggjeverksemd. Solid kompetanse i matematikk er dermed ein føresetnad for utvikling av samfunnet. Eit aktivt demokrati treng borgarar som kan setje seg inn i, forstå og kritisk vurdere kvantitativ informasjon, statistiske analysar og økonomiske prognosar. På den måten er matematisk kompetanse nødvendig for å forstå og kunne påverke prosessar i samfunnet.',
      'Matematisk kompetanse inneber å bruke problemløysing og modellering til å analysere og omforme eit problem til matematisk form, løyse det og vurdere kor gyldig løysinga er. Dette har òg språklege aspekt, som det å formidle, samtale om og resonnere omkring idear. I det meste av matematisk aktivitet nyttar ein hjelpemiddel og teknologi. Både det å kunne bruke og vurdere ulike hjelpemiddel og det å kjenne til avgrensinga deira er viktige delar av faget. Kompetanse i matematikk er ein viktig reiskap for den einskilde, og faget kan leggje grunnlag for å ta vidare utdanning og for deltaking i yrkesliv og fritidsaktivitetar. Matematikk ligg til grunn for store delar av kulturhistoria vår og utviklinga av logisk tenking. På den måten spelar faget ei sentral rolle i den allmenne danninga ved å påverke identitet, tenkjemåte og sjølvforståing.',
      'Matematikkfaget i skolen medverkar til å utvikle den matematiske kompetansen som samfunnet og den einskilde treng. For å oppnå dette må elevane få høve til å arbeide både praktisk og teoretisk. Opplæringa vekslar mellom utforskande, leikande, kreative og problemløysande aktivitetar og ferdigheitstrening. I praktisk bruk viser matematikk sin nytte som reiskapsfag. I skolearbeidet utnyttar ein sentrale idear, former, strukturar og samanhengar i faget. Elevane må utfordrast til å kommunisere matematikk skriftleg, munnleg og digitalt. Det må leggjast til rette for at både jenter og gutar får rike erfaringar med matematikkfaget, som skaper positive haldningar og ein solid fagkompetanse. Slik blir det lagt eit grunnlag for livslang læring.'
    ],
    skills: [
      'Grunnleggjande ferdigheiter er integrerte i kompetansemåla, der dei medverkar til utvikling av og er ein del av fagkompetansen. I matematikk forstår ein grunnleggjande ferdigheiter slik:',
      'Munnlege ferdigheiter i matematikk inneber å skape meining gjennom å lytte, tale og samtale om matematikk. Det inneber å gjere seg opp ei meining, stille spørsmål og argumentere ved hjelp av både eit uformelt språk, presis fagterminologi og omgrepsbruk. Det vil seie å vere med i samtalar, kommunisere idear og drøfte matematiske problem, løysingar og strategiar med andre. Utvikling i munnlege ferdigheiter i matematikk går frå å delta i samtalar om matematikk til å presentere og drøfte komplekse faglege emne. Vidare går utviklinga frå å bruke eit enkelt matematisk språk til å bruke presis fagterminologi og uttrykksmåte og presise omgrep.',
      'Å kunne skrive i matematikk inneber å beskrive og forklare ein tankegang og setje ord på oppdagingar og idear. Det inneber å bruke matematiske symbol og det formelle matematiske språket til å løyse problem og presentere løysingar. Vidare vil det seie å lage teikningar, skisser, figurar, grafar, tabellar og diagram som er tilpassa mottakaren og situasjonen. Skriving i matematikk er ein reiskap for å utvikle eigne tankar og eiga læring. Utvikling i å skrive i matematikk går frå å bruke enkle uttrykksformer til gradvis å ta i bruk eit formelt symbolspråk og ein presis fagterminologi. Vidare går utviklinga frå å beskrive og systematisere enkle situasjonar med matematikkfagleg innhald til å byggje opp ein heilskapleg argumentasjon omkring komplekse samanhengar.',
      'Å kunne lese i matematikk inneber å forstå og bruke symbolspråk og uttrykksformer for å skape meining i tekstar frå daglegliv og yrkesliv så vel som matematikkfaglege tekstar. Matematikkfaget er prega av samansette tekstar som inneheld matematiske uttrykk, grafar, diagram, tabellar, symbol, formlar og logiske resonnement. Lesing i matematikk inneber å sortere informasjon, analysere og vurdere form og innhald og samanfatte informasjon frå ulike element i tekstar. Utvikling i å lese i matematikk går frå å finne og bruke informasjon i tekstar med enkelt symbolspråk til å finne meining og reflektere over komplekse fagtekstar med avansert symbolspråk og omgrepsbruk.',
      'Å kunne rekne i matematikk inneber å bruke symbolspråk, matematiske omgrep, framgangsmåtar og varierte strategiar til problemløysing og utforsking som tek utgangspunkt både i praktiske, daglegdagse situasjonar og i matematiske problem. Dette inneber å kjenne att og beskrive situasjonar der matematikk inngår, og bruke matematiske metodar til å behandle problemstillingar. Eleven må òg kommunisere og vurdere kor gyldige løysingane er. Utvikling av å rekne i matematikk går frå grunnleggjande talforståing og å kjenne att og løyse problem ut frå enkle situasjonar til å analysere og løyse eit spekter av komplekse problem med eit variert utval av strategiar og metodar. Vidare inneber dette i aukande grad å bruke ulike hjelpemiddel i berekningar, modellering og kommunikasjon.',
      'Digitale ferdigheiter i matematikk inneber å bruke digitale verktøy til læring gjennom spel, utforsking, visualisering og presentasjon. Det handlar òg om å kjenne til, bruke og vurdere digitale verktøy til berekningar, problemløysing, simulering og modellering. Vidare vil det seie å finne informasjon, analysere, behandle og presentere data med formålstenlege verktøy, og vere kritisk til kjelder, analysar og resultat. Utvikling i digitale ferdigheiter inneber å arbeide med samansette digitale tekstar med aukande grad av kompleksitet. Vidare inneber det å bli stadig meir merksam på den nytten digitale verktøy har for læring i matematikkfaget.'
    ],
    levels: [5, 6, 7]
  },
  'MAT1-04-4': {
    ID: 'MAT1-04-4',
    title: 'Matematikk 8. - 10. trinn',
    hours: 18780,
    chapters: [
      {
        title: 'Tall og algebra',
        goals: [
          'Samanlikne og rekne om mellom heile tal, desimaltal, brøkar, prosent, promille og tal på standardform, uttrykkje slike tal på varierte måtar og vurdere i kva for situasjonar ulike representasjonar er formålstenlege',
          'Rekne med brøk, utføre divisjon av brøkar og forenkle brøkuttrykk',
          'Bruke faktorar, potensar, kvadratrøter og primtal i berekningar',
          'Utvikle, bruke og gjere greie for ulike metodar i hovudrekning, overslagsrekning og skriftleg rekning med dei fire rekneartane',
          'Behandle, faktorisere og forenkle algebrauttrykk, knyte uttrykka til praktiske situasjonar, rekne med formlar, parentesar og brøkuttrykk og bruke kvadratsetningane',
          'Løyse likningar og ulikskapar av første grad og likningssystem med to ukjende og bruke dette til å løyse praktiske og teoretiske problem',
          'Gjere berekningar om forbruk, bruk av kredittkort, inntekt, lån og sparing, setje opp budsjett og rekneskap ved å bruke rekneark og gjere greie for berekningar og presentere resultata',
          'Analysere samansette problemstillingar, identifisere faste og variable storleikar, kople samansette problemstillingar til kjende løysingsmetodar, gjennomføre berekningar og presentere resultata på ein formålstenleg måte',
          'Bruke tal og variablar i utforsking, eksperimentering og praktisk og teoretisk problemløysing og i prosjekt med teknologi og design'
        ]
      },
      {
        title: 'Geometri',
        goals: [
          'Undersøkje og beskrive eigenskapar ved to- og tredimensjonale figurar og bruke eigenskapane i samband med konstruksjonar og berekningar',
          'Utføre, beskrive og grunngje geometriske konstruksjonar med passar og linjal og dynamisk geometriprogram',
          'Bruke og grunngje bruken av formlikskap og Pytagoras’ setning i berekning av ukjende storleikar',
          'Tolke og lage arbeidsteikningar og perspektivteikningar med fleire forsvinningspunkt, med og utan digitale verktøy',
          'Bruke koordinatar til å avbilde figurar og utforske eigenskapar ved geometriske former, med og utan digitale verktøy',
          'Utforske, eksperimentere med og formulere logiske resonnement ved hjelp av geometriske idear og gjere greie for geometriske forhold som har særleg mykje å seie i teknologi, kunst og arkitektur'
        ]
      },
      {
        title: 'Måling',
        goals: [
          'Gjere overslag over og berekne lengd, omkrins, vinkel, areal, overflate, volum, tid, fart og massetettleik og bruke og endre målestokk',
          'Velje høvelege måleiningar, forklare samanhengar og rekne om mellom ulike måleiningar, bruke og vurdere måleinstrument og målemetodar i praktisk måling og drøfte presisjon og måleusikkerheit',
          'Gjere greie for talet π og bruke det i berekningar av omkrins, areal og volum'
        ]
      },
      {
        title: 'Statistikk, sannsynlighet og kombinatorikk',
        goals: [
          'Gjennomføre undersøkingar og bruke databasar til å søkje etter og analysere statistiske data og vise kjeldekritikk',
          'Ordne og gruppere data, finne og drøfte median, typetal, gjennomsnitt og variasjonsbreidd, presentere data, med og utan digitale verktøy, og drøfte ulike dataframstillingar og kva inntrykk dei kan gje',
          'Finne og diskutere sannsyn gjennom eksperimentering, simulering og berekning i daglegdagse samanhengar og spel',
          'Beskrive utfallsrom og uttrykkje sannsyn som brøk, prosent og desimaltal',
          'Drøfte og løyse enkle kombinatoriske problem'
        ]
      },
      {
        title: 'Funksjoner',
        goals: [
          'Lage funksjonar som beskriv numeriske samanhengar og praktiske situasjonar, med og utan digitale verktøy, beskrive og tolke dei og omsetje mellom ulike representasjonar av funksjonar, som grafar, tabellar, formlar og tekstar',
          'Identifisere og utnytte eigenskapane til proporsjonale, omvendt proporsjonale, lineære og kvadratiske funksjonar og gje døme på praktiske situasjonar som kan beskrivast med desse funksjonane'
        ]
      }
    ],
    purpose: [
      'Matematikk er ein del av den globale kulturarven vår. Mennesket har til alle tider brukt og utvikla matematikk for å systematisere erfaringar, for å beskrive og forstå samanhengar i naturen og i samfunnet og for å utforske universet. Ei anna inspirasjonskjelde til utviklinga av faget har vore glede hos menneske over arbeid med matematikk i seg sjølv. Faget grip inn i mange vitale samfunnsområde, som medisin, økonomi, teknologi, kommunikasjon, energiforvalting og byggjeverksemd. Solid kompetanse i matematikk er dermed ein føresetnad for utvikling av samfunnet. Eit aktivt demokrati treng borgarar som kan setje seg inn i, forstå og kritisk vurdere kvantitativ informasjon, statistiske analysar og økonomiske prognosar. På den måten er matematisk kompetanse nødvendig for å forstå og kunne påverke prosessar i samfunnet.',
      'Matematisk kompetanse inneber å bruke problemløysing og modellering til å analysere og omforme eit problem til matematisk form, løyse det og vurdere kor gyldig løysinga er. Dette har òg språklege aspekt, som det å formidle, samtale om og resonnere omkring idear. I det meste av matematisk aktivitet nyttar ein hjelpemiddel og teknologi. Både det å kunne bruke og vurdere ulike hjelpemiddel og det å kjenne til avgrensinga deira er viktige delar av faget. Kompetanse i matematikk er ein viktig reiskap for den einskilde, og faget kan leggje grunnlag for å ta vidare utdanning og for deltaking i yrkesliv og fritidsaktivitetar. Matematikk ligg til grunn for store delar av kulturhistoria vår og utviklinga av logisk tenking. På den måten spelar faget ei sentral rolle i den allmenne danninga ved å påverke identitet, tenkjemåte og sjølvforståing.',
      'Matematikkfaget i skolen medverkar til å utvikle den matematiske kompetansen som samfunnet og den einskilde treng. For å oppnå dette må elevane få høve til å arbeide både praktisk og teoretisk. Opplæringa vekslar mellom utforskande, leikande, kreative og problemløysande aktivitetar og ferdigheitstrening. I praktisk bruk viser matematikk sin nytte som reiskapsfag. I skolearbeidet utnyttar ein sentrale idear, former, strukturar og samanhengar i faget. Elevane må utfordrast til å kommunisere matematikk skriftleg, munnleg og digitalt. Det må leggjast til rette for at både jenter og gutar får rike erfaringar med matematikkfaget, som skaper positive haldningar og ein solid fagkompetanse. Slik blir det lagt eit grunnlag for livslang læring.'
    ],
    skills: [
      'Grunnleggjande ferdigheiter er integrerte i kompetansemåla, der dei medverkar til utvikling av og er ein del av fagkompetansen. I matematikk forstår ein grunnleggjande ferdigheiter slik:',
      'Munnlege ferdigheiter i matematikk inneber å skape meining gjennom å lytte, tale og samtale om matematikk. Det inneber å gjere seg opp ei meining, stille spørsmål og argumentere ved hjelp av både eit uformelt språk, presis fagterminologi og omgrepsbruk. Det vil seie å vere med i samtalar, kommunisere idear og drøfte matematiske problem, løysingar og strategiar med andre. Utvikling i munnlege ferdigheiter i matematikk går frå å delta i samtalar om matematikk til å presentere og drøfte komplekse faglege emne. Vidare går utviklinga frå å bruke eit enkelt matematisk språk til å bruke presis fagterminologi og uttrykksmåte og presise omgrep.',
      'Å kunne skrive i matematikk inneber å beskrive og forklare ein tankegang og setje ord på oppdagingar og idear. Det inneber å bruke matematiske symbol og det formelle matematiske språket til å løyse problem og presentere løysingar. Vidare vil det seie å lage teikningar, skisser, figurar, grafar, tabellar og diagram som er tilpassa mottakaren og situasjonen. Skriving i matematikk er ein reiskap for å utvikle eigne tankar og eiga læring. Utvikling i å skrive i matematikk går frå å bruke enkle uttrykksformer til gradvis å ta i bruk eit formelt symbolspråk og ein presis fagterminologi. Vidare går utviklinga frå å beskrive og systematisere enkle situasjonar med matematikkfagleg innhald til å byggje opp ein heilskapleg argumentasjon omkring komplekse samanhengar.',
      'Å kunne lese i matematikk inneber å forstå og bruke symbolspråk og uttrykksformer for å skape meining i tekstar frå daglegliv og yrkesliv så vel som matematikkfaglege tekstar. Matematikkfaget er prega av samansette tekstar som inneheld matematiske uttrykk, grafar, diagram, tabellar, symbol, formlar og logiske resonnement. Lesing i matematikk inneber å sortere informasjon, analysere og vurdere form og innhald og samanfatte informasjon frå ulike element i tekstar. Utvikling i å lese i matematikk går frå å finne og bruke informasjon i tekstar med enkelt symbolspråk til å finne meining og reflektere over komplekse fagtekstar med avansert symbolspråk og omgrepsbruk.',
      'Å kunne rekne i matematikk inneber å bruke symbolspråk, matematiske omgrep, framgangsmåtar og varierte strategiar til problemløysing og utforsking som tek utgangspunkt både i praktiske, daglegdagse situasjonar og i matematiske problem. Dette inneber å kjenne att og beskrive situasjonar der matematikk inngår, og bruke matematiske metodar til å behandle problemstillingar. Eleven må òg kommunisere og vurdere kor gyldige løysingane er. Utvikling av å rekne i matematikk går frå grunnleggjande talforståing og å kjenne att og løyse problem ut frå enkle situasjonar til å analysere og løyse eit spekter av komplekse problem med eit variert utval av strategiar og metodar. Vidare inneber dette i aukande grad å bruke ulike hjelpemiddel i berekningar, modellering og kommunikasjon.',
      'Digitale ferdigheiter i matematikk inneber å bruke digitale verktøy til læring gjennom spel, utforsking, visualisering og presentasjon. Det handlar òg om å kjenne til, bruke og vurdere digitale verktøy til berekningar, problemløysing, simulering og modellering. Vidare vil det seie å finne informasjon, analysere, behandle og presentere data med formålstenlege verktøy, og vere kritisk til kjelder, analysar og resultat. Utvikling i digitale ferdigheiter inneber å arbeide med samansette digitale tekstar med aukande grad av kompleksitet. Vidare inneber det å bli stadig meir merksam på den nytten digitale verktøy har for læring i matematikkfaget.'
    ],
    levels: [8, 9, 10]
  },
  'NAT1-03-1': {
    ID: 'NAT1-03-1',
    title: 'Naturfag 1.-2.trinn',
    hours: 5610,
    chapters: [
      {
        title: 'Forskerspiren',
        goals: [
          'Stille spørsmål, samtale og filosofere rundt naturopplevelser og menneskets plass i naturen',
          'Bruke sansene til å utforske verden i det nære miljøet',
          'Beskrive, illustrere og samtale om egne observasjoner fra forsøk og fra naturen',
          'Gjenkjenne faresymbol for farlige stoffer og for farlig lys'
        ]
      },
      {
        title: 'Mangfold i naturen',
        goals: [
          'Gjennomføre aktiviteter i nærområdet for å lære om naturen og samtale om hvorfor dette er viktig',
          'Bruke observasjoner til å beskrive kjennetegn ved årstidene og fortelle om hvordan man i samisk kultur deler inn året',
          'Gjenkjenne og beskrive noen plante- og dyrearter i nærområder og sortere dem i grupper'
        ]
      },
      {
        title: 'Kropp og helse',
        goals: [
          'Sette navn på og beskrive funksjonen til noen ytre og indre deler av menneskekroppen',
          'Samtale om grensesetting, forståelse og respekt for egen og andres kropp',
          'Beskrive og samtale om sansene og bruke dem bevisst i observasjoner ved aktiviteter ute og inne'
        ]
      },
      {
        title: 'Fenomener og stoffer',
        goals: [
          'Beskrive og illustrere hvordan jorda, månen og sola beveger seg i forhold til hverandre, og fortelle om årstider, døgn og månefaser',
          'Beskrive og sortere stoffer etter observerbare kjennetegn',
          'Gjøre forsøk med vann og lys og samtale om observasjonene'
        ]
      },
      {
        title: 'Teknologi og design',
        goals: [
          'Lage gjenstander som kan bevege seg ved hjelp av vann eller luft, og samtale om hvordan de virker',
          'Lage gjenstander som bruker refleksjon av lys, og samtale om hvordan de virker'
        ]
      }
    ],
    purpose: [
      'Naturvitenskapen har vokst fram som følge av menneskers nysgjerrighet og behov for å finne svar på spørsmål om sin egen eksistens, om liv og livsformer og vår plass i naturen og i universet, og er på den måten en del av vår kultur.',
      'Lover og teorier i naturvitenskapen er modeller av en sammensatt virkelighet, og disse modellene endres eller videreutvikles gjennom nye observasjoner, eksperimenter og ideer. En viktig del av allmennkunnskapen er å kjenne til at naturvitenskapen er i utvikling, og at forskning og ny kunnskap i naturvitenskap og teknologi har stor betydning for samfunnsutviklingen og for livsmiljøet.',
      'Selv om naturvitenskapen er delt opp i ulike fagdisipliner, som biologi, fysikk og kjemi og geofag, er målet at skolefaget naturfag både teoretisk og praktisk framstår som et helhetlig fag.',
      'Kunnskap om, forståelse av og opplevelser i naturen kan fremme viljen til å verne om naturressursene, bevare biologisk mangfold og bidra til bærekraftig utvikling. I denne sammenhengen har samer og andre urfolk kunnskap om naturen som det er viktig å vise respekt for. Samtidig skal naturfag bidra til at barn og unge utvikler kunnskaper og holdninger som gir dem et gjennomtenkt syn på samspillet mellom natur, individ, teknologi, samfunn og forskning. Dette er viktig for den enkeltes mulighet til å forstå ulike typer naturvitenskapelig og teknologisk informasjon. Dette skal gi den enkelte et grunnlag for å delta i prosesser i samfunnet.',
      'Å arbeide både praktisk og teoretisk i laboratorier og i naturen med ulike problemstillinger er nødvendig for å få erfaring med og utvikle kunnskap om metoder og tenkemåter i naturvitenskapen. Dette kan bidra til å utvikle kreativitet, kritisk evne, åpenhet og aktiv deltakelse i situasjoner der naturfaglig kunnskap og ekspertise inngår. Varierte læringsmiljøer, som feltarbeid i naturen, eksperimenter i laboratoriet og ekskursjoner til museer, vitensentre og bedrifter, vil berike opplæringen i naturfag og gi rom for undring, nysgjerrighet og fascinasjon. Kompetanse i å forstå ulike typer naturvitenskapelige tekster, metoder og teknologiske løsninger gir et godt grunnlag for yrkesfaglige utdanninger, videre studier og livslang læring i yrke og fritid.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I naturfag forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i naturfag er å lytte, tale og samtale for å beskrive, dele og utvikle kunnskap med naturfaglig innhold som er knyttet til observasjoner og erfaringer. Det innebærer å bruke naturfaglige begreper for å formidle kunnskap, formulere spørsmål, argumenter og forklaringer. Videre innebærer det å tilpasse uttrykksform, begreper og eksempler til formål og mottakere. Utviklingen av muntlige ferdigheter i naturfag går fra å kunne lytte og samtale om opplevelser og observasjoner til å kunne presentere og diskutere stadig mer komplekse emner. Dette innebærer i økende grad å kunne bruke naturfaglige begreper til å uttrykke forståelse, til å ha egne vurderinger og til å delta i faglige diskusjoner.',
      'Å kunne skrive i naturfag er å bruke naturfaglige tekstsjangere til å formulere spørsmål og hypoteser, skrive planer og forklaringer, sammenligne og reflektere over informasjon og bruke kilder hensiktsmessig. Det innebærer også å beskrive observasjoner og erfaringer, sammenstille informasjon, argumentere for synspunkter og rapportere fra feltarbeid, eksperimenter og teknologiske utviklingsprosesser. Skriveprosessen fra planlegging til bearbeiding og presentasjon av tekster innebærer bruk av naturfaglige begreper, figurer og symboler tilpasset formål og mottaker. Utviklingen av skriveferdigheter i naturfag går fra å bruke enkle uttrykksformer til gradvis å ta i bruk mer presise naturfaglige begreper, symboler, grafikk og argumentasjon. Dette innebærer å kunne skrive stadig mer komplekse tekster som bygger på kritisk og variert kildebruk tilpasset formål og mottaker.',
      'Å kunne lese i naturfag er å forstå og bruke naturfaglige begreper, symboler, figurer og argumenter gjennom målrettet arbeid med naturfaglige tekster. Dette innebærer å kunne identifisere, tolke og bruke informasjon fra sammensatte tekster i bøker, aviser, bruksanvisninger, regelverk, brosjyrer og digitale kilder. Lesing i naturfag inkluderer kritisk vurdering av hvordan informasjon framstilles og brukes i argumenter, blant annet gjennom å kunne skille mellom data, antakelser, påstander, hypoteser og konklusjoner. Utviklingen av leseferdighet i naturfag går fra å finne og bruke uttrykt informasjon i enkle tekster til å forstå tekster med stadig flere fagbegreper, symboler, figurer, tabeller og implisitt informasjon. Kravet til kritisk lesing og evne til å identifisere relevant informasjon og vurdere kilders troverdighet øker, fra å kunne bruke tilrettelagte kilder til å kunne innhente og sammenligne informasjon fra ulike kilder og vurdere relevansen.',
      'Å kunne regne i naturfag er å innhente, bearbeide og framstille tallmateriale. Det innebærer å bruke begreper, måleinstrumenter, måleenheter, formler og grafikk. Regning i naturfag er også å kunne sammenligne, vurdere og argumentere for gyldigheten av beregninger, resultater og framstillinger. Utviklingen av regneferdigheter i naturfag går fra å bruke enkle metoder for opptelling og klassifisering til å kunne vurdere valg av metoder, begreper, formler og måleinstrumenter. Videre innebærer det å kunne gjøre gradvis mer avanserte framstillinger og vurderinger og bruke regning i faglig argumentasjon.',
      'Digitale ferdigheter i naturfag er å bruke digitale verktøy til å utforske, registrere, gjøre beregninger, visualisere, dokumentere og publisere data fra egne og andres studier, forsøk og feltarbeid. Det innebærer også å bruke søkeverktøy, beherske søkestrategier og kritisk vurdere kilder og velge ut relevant informasjon om naturfaglige tema. Utviklingen av digitale ferdigheter i naturfag går fra å kunne bruke digitale verktøy til i økende grad å utvise selvstendighet og dømmekraft i valg og bruk av digitale kilder, verktøy, medier og informasjon.'
    ],
    levels: [1, 2]
  },
  'NAT1-03-2': {
    ID: 'NAT1-03-2',
    title: 'Naturfag 1.-2.trinn',
    hours: 5610,
    chapters: [
      {
        title: 'Forskerspiren',
        goals: [
          'Stille spørsmål, samtale og filosofere rundt naturopplevelser og menneskets plass i naturen',
          'Bruke sansene til å utforske verden i det nære miljøet',
          'Beskrive, illustrere og samtale om egne observasjoner fra forsøk og fra naturen',
          'Gjenkjenne faresymbol for farlige stoffer og for farlig lys'
        ]
      },
      {
        title: 'Mangfold i naturen',
        goals: [
          'Gjennomføre aktiviteter i nærområdet for å lære om naturen og samtale om hvorfor dette er viktig',
          'Bruke observasjoner til å beskrive kjennetegn ved årstidene og fortelle om hvordan man i samisk kultur deler inn året',
          'Gjenkjenne og beskrive noen plante- og dyrearter i nærområder og sortere dem i grupper'
        ]
      },
      {
        title: 'Kropp og helse',
        goals: [
          'Sette navn på og beskrive funksjonen til noen ytre og indre deler av menneskekroppen',
          'Samtale om grensesetting, forståelse og respekt for egen og andres kropp',
          'Beskrive og samtale om sansene og bruke dem bevisst i observasjoner ved aktiviteter ute og inne'
        ]
      },
      {
        title: 'Fenomener og stoffer',
        goals: [
          'Beskrive og illustrere hvordan jorda, månen og sola beveger seg i forhold til hverandre, og fortelle om årstider, døgn og månefaser',
          'Beskrive og sortere stoffer etter observerbare kjennetegn',
          'Gjøre forsøk med vann og lys og samtale om observasjonene'
        ]
      },
      {
        title: 'Teknologi og design',
        goals: [
          'Lage gjenstander som kan bevege seg ved hjelp av vann eller luft, og samtale om hvordan de virker',
          'Lage gjenstander som bruker refleksjon av lys, og samtale om hvordan de virker'
        ]
      }
    ],
    purpose: [
      'Naturvitenskapen har vokst fram som følge av menneskers nysgjerrighet og behov for å finne svar på spørsmål om sin egen eksistens, om liv og livsformer og vår plass i naturen og i universet, og er på den måten en del av vår kultur.',
      'Lover og teorier i naturvitenskapen er modeller av en sammensatt virkelighet, og disse modellene endres eller videreutvikles gjennom nye observasjoner, eksperimenter og ideer. En viktig del av allmennkunnskapen er å kjenne til at naturvitenskapen er i utvikling, og at forskning og ny kunnskap i naturvitenskap og teknologi har stor betydning for samfunnsutviklingen og for livsmiljøet.',
      'Selv om naturvitenskapen er delt opp i ulike fagdisipliner, som biologi, fysikk og kjemi og geofag, er målet at skolefaget naturfag både teoretisk og praktisk framstår som et helhetlig fag.',
      'Kunnskap om, forståelse av og opplevelser i naturen kan fremme viljen til å verne om naturressursene, bevare biologisk mangfold og bidra til bærekraftig utvikling. I denne sammenhengen har samer og andre urfolk kunnskap om naturen som det er viktig å vise respekt for. Samtidig skal naturfag bidra til at barn og unge utvikler kunnskaper og holdninger som gir dem et gjennomtenkt syn på samspillet mellom natur, individ, teknologi, samfunn og forskning. Dette er viktig for den enkeltes mulighet til å forstå ulike typer naturvitenskapelig og teknologisk informasjon. Dette skal gi den enkelte et grunnlag for å delta i prosesser i samfunnet.',
      'Å arbeide både praktisk og teoretisk i laboratorier og i naturen med ulike problemstillinger er nødvendig for å få erfaring med og utvikle kunnskap om metoder og tenkemåter i naturvitenskapen. Dette kan bidra til å utvikle kreativitet, kritisk evne, åpenhet og aktiv deltakelse i situasjoner der naturfaglig kunnskap og ekspertise inngår. Varierte læringsmiljøer, som feltarbeid i naturen, eksperimenter i laboratoriet og ekskursjoner til museer, vitensentre og bedrifter, vil berike opplæringen i naturfag og gi rom for undring, nysgjerrighet og fascinasjon. Kompetanse i å forstå ulike typer naturvitenskapelige tekster, metoder og teknologiske løsninger gir et godt grunnlag for yrkesfaglige utdanninger, videre studier og livslang læring i yrke og fritid.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I naturfag forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i naturfag er å lytte, tale og samtale for å beskrive, dele og utvikle kunnskap med naturfaglig innhold som er knyttet til observasjoner og erfaringer. Det innebærer å bruke naturfaglige begreper for å formidle kunnskap, formulere spørsmål, argumenter og forklaringer. Videre innebærer det å tilpasse uttrykksform, begreper og eksempler til formål og mottakere. Utviklingen av muntlige ferdigheter i naturfag går fra å kunne lytte og samtale om opplevelser og observasjoner til å kunne presentere og diskutere stadig mer komplekse emner. Dette innebærer i økende grad å kunne bruke naturfaglige begreper til å uttrykke forståelse, til å ha egne vurderinger og til å delta i faglige diskusjoner.',
      'Å kunne skrive i naturfag er å bruke naturfaglige tekstsjangere til å formulere spørsmål og hypoteser, skrive planer og forklaringer, sammenligne og reflektere over informasjon og bruke kilder hensiktsmessig. Det innebærer også å beskrive observasjoner og erfaringer, sammenstille informasjon, argumentere for synspunkter og rapportere fra feltarbeid, eksperimenter og teknologiske utviklingsprosesser. Skriveprosessen fra planlegging til bearbeiding og presentasjon av tekster innebærer bruk av naturfaglige begreper, figurer og symboler tilpasset formål og mottaker. Utviklingen av skriveferdigheter i naturfag går fra å bruke enkle uttrykksformer til gradvis å ta i bruk mer presise naturfaglige begreper, symboler, grafikk og argumentasjon. Dette innebærer å kunne skrive stadig mer komplekse tekster som bygger på kritisk og variert kildebruk tilpasset formål og mottaker.',
      'Å kunne lese i naturfag er å forstå og bruke naturfaglige begreper, symboler, figurer og argumenter gjennom målrettet arbeid med naturfaglige tekster. Dette innebærer å kunne identifisere, tolke og bruke informasjon fra sammensatte tekster i bøker, aviser, bruksanvisninger, regelverk, brosjyrer og digitale kilder. Lesing i naturfag inkluderer kritisk vurdering av hvordan informasjon framstilles og brukes i argumenter, blant annet gjennom å kunne skille mellom data, antakelser, påstander, hypoteser og konklusjoner. Utviklingen av leseferdighet i naturfag går fra å finne og bruke uttrykt informasjon i enkle tekster til å forstå tekster med stadig flere fagbegreper, symboler, figurer, tabeller og implisitt informasjon. Kravet til kritisk lesing og evne til å identifisere relevant informasjon og vurdere kilders troverdighet øker, fra å kunne bruke tilrettelagte kilder til å kunne innhente og sammenligne informasjon fra ulike kilder og vurdere relevansen.',
      'Å kunne regne i naturfag er å innhente, bearbeide og framstille tallmateriale. Det innebærer å bruke begreper, måleinstrumenter, måleenheter, formler og grafikk. Regning i naturfag er også å kunne sammenligne, vurdere og argumentere for gyldigheten av beregninger, resultater og framstillinger. Utviklingen av regneferdigheter i naturfag går fra å bruke enkle metoder for opptelling og klassifisering til å kunne vurdere valg av metoder, begreper, formler og måleinstrumenter. Videre innebærer det å kunne gjøre gradvis mer avanserte framstillinger og vurderinger og bruke regning i faglig argumentasjon.',
      'Digitale ferdigheter i naturfag er å bruke digitale verktøy til å utforske, registrere, gjøre beregninger, visualisere, dokumentere og publisere data fra egne og andres studier, forsøk og feltarbeid. Det innebærer også å bruke søkeverktøy, beherske søkestrategier og kritisk vurdere kilder og velge ut relevant informasjon om naturfaglige tema. Utviklingen av digitale ferdigheter i naturfag går fra å kunne bruke digitale verktøy til i økende grad å utvise selvstendighet og dømmekraft i valg og bruk av digitale kilder, verktøy, medier og informasjon.'
    ],
    levels: [3, 4]
  },
  'NAT1-03-3': {
    ID: 'NAT1-03-3',
    title: 'Naturfag 5. - 7.trinn',
    hours: 10750,
    chapters: [
      {
        title: 'Forskerspiren',
        goals: [
          'Formulere naturfaglige spørsmål om noe eleven lurer på, foreslå mulige forklaringer, lage en plan og gjennomføre undersøkelser',
          'Samtale om hvorfor det i naturvitenskapen er viktig å lage og teste hypoteser ved systematiske observasjoner og forsøk, og hvorfor det er viktig å sammenligne resultater',
          'Bruke digitale hjelpemidler til å registrere, bearbeide og publisere data fra eksperimentelt arbeid og feltarbeid',
          'Trekke ut og bearbeide naturfaglig informasjon fra tekster i ulike medier og lage en presentasjon',
          'Lese og forstå faremerking på hverdagsprodukter'
        ]
      },
      {
        title: 'Mangfold i naturen',
        goals: [
          'Planlegge og gjennomføre undersøkelser i minst ett naturområde, registrere observasjoner og systematisere resultatene',
          'Undersøke og beskrive blomsterplanter og forklare funksjonene til de ulike plantedelene med tekst og illustrasjon',
          'Undersøke og diskutere noen faktorer som kan påvirke frøspiring og vekst hos planter',
          'Beskrive kjennetegn på noen plante-, sopp- og dyrearter og ordne dem systematisk',
          'Fortelle om hvordan noen planter, sopp og dyr brukes i ulike tradisjoner, blant annet den samiske, og diskutere om bruken er bærekraftig'
        ]
      },
      {
        title: 'Kropp og helse',
        goals: [
          'Beskrive utviklingen av menneskekroppen fra befruktning til voksen',
          'Forklare hva som skjer under puberteten og samtale om ulik kjønnsidentitet og variasjon i seksuell orientering',
          'Beskrive i hovedtrekk hjerte- og lungesystemet og hvilken funksjon det har i kroppen',
          'Forklare hvordan kroppen selv beskytter seg mot sykdom, og hvordan man forebygger og behandler infeksjonssykdommer',
          'Samle informasjon og tallmateriale og diskutere helseskader som kan oppstå ved bruk av ulike rusmidler'
        ]
      },
      {
        title: 'Fenomener og stoffer',
        goals: [
          'Bruke animasjoner og andre modeller til å beskrive planetenes og månens bevegelser, og forklare hvordan årstider og månefaser oppstår',
          'Beskrive hvordan noen mineraler og bergarter har blitt dannet, og undersøke noen typer som finnes i nærområdet',
          'Gjøre rede for bruken av noen energikilder før og nå, og innhente informasjon og statistikk fra ulike kilder for å beskrive og diskutere mulige konsekvenser av energibruken for miljøet lokalt og globalt',
          'Forklare begrepet klima, kjenne til noen årsaker til klimaendringer og undersøke og registrere konsekvenser av ekstremvær',
          'Undersøke fenomener knyttet til lyd, hørsel og støy, diskutere observasjonene og forklare hvordan lyd kan skade hørselen',
          'Gjøre forsøk med magnetisme og elektrisitet og forklare og presentere resultatene',
          'Beskrive sentrale egenskaper ved gasser, væsker, faste stoffer og faseoverganger ved hjelp av partikkelmodellen',
          'Forklare hvordan stoffer er bygd opp, og hvordan stoffer kan omdannes ved å bruke begrepene atomer og molekyler',
          'Gjennomføre forsøk med ulike kjemiske reaksjoner og beskrive hva som kjennetegner dem'
        ]
      },
      {
        title: 'Teknologi og design',
        goals: [
          'Planlegge, bygge og teste mekaniske leker og forklare prinsipper for mekaniske overføringer',
          'Planlegge, lage og teste enkle produkter som gjør bruk av elektrisk energi, og reklamere for ferdig framstilt produkt',
          'Beskrive livsløpet til et produkt og diskutere i hvilken grad produktet er forenelig med bærekraftig utvikling'
        ]
      }
    ],
    purpose: [
      'Naturvitenskapen har vokst fram som følge av menneskers nysgjerrighet og behov for å finne svar på spørsmål om sin egen eksistens, om liv og livsformer og vår plass i naturen og i universet, og er på den måten en del av vår kultur.',
      'Lover og teorier i naturvitenskapen er modeller av en sammensatt virkelighet, og disse modellene endres eller videreutvikles gjennom nye observasjoner, eksperimenter og ideer. En viktig del av allmennkunnskapen er å kjenne til at naturvitenskapen er i utvikling, og at forskning og ny kunnskap i naturvitenskap og teknologi har stor betydning for samfunnsutviklingen og for livsmiljøet.',
      'Selv om naturvitenskapen er delt opp i ulike fagdisipliner, som biologi, fysikk og kjemi og geofag, er målet at skolefaget naturfag både teoretisk og praktisk framstår som et helhetlig fag.',
      'Kunnskap om, forståelse av og opplevelser i naturen kan fremme viljen til å verne om naturressursene, bevare biologisk mangfold og bidra til bærekraftig utvikling. I denne sammenhengen har samer og andre urfolk kunnskap om naturen som det er viktig å vise respekt for. Samtidig skal naturfag bidra til at barn og unge utvikler kunnskaper og holdninger som gir dem et gjennomtenkt syn på samspillet mellom natur, individ, teknologi, samfunn og forskning. Dette er viktig for den enkeltes mulighet til å forstå ulike typer naturvitenskapelig og teknologisk informasjon. Dette skal gi den enkelte et grunnlag for å delta i prosesser i samfunnet.',
      'Å arbeide både praktisk og teoretisk i laboratorier og i naturen med ulike problemstillinger er nødvendig for å få erfaring med og utvikle kunnskap om metoder og tenkemåter i naturvitenskapen. Dette kan bidra til å utvikle kreativitet, kritisk evne, åpenhet og aktiv deltakelse i situasjoner der naturfaglig kunnskap og ekspertise inngår. Varierte læringsmiljøer, som feltarbeid i naturen, eksperimenter i laboratoriet og ekskursjoner til museer, vitensentre og bedrifter, vil berike opplæringen i naturfag og gi rom for undring, nysgjerrighet og fascinasjon. Kompetanse i å forstå ulike typer naturvitenskapelige tekster, metoder og teknologiske løsninger gir et godt grunnlag for yrkesfaglige utdanninger, videre studier og livslang læring i yrke og fritid.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I naturfag forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i naturfag er å lytte, tale og samtale for å beskrive, dele og utvikle kunnskap med naturfaglig innhold som er knyttet til observasjoner og erfaringer. Det innebærer å bruke naturfaglige begreper for å formidle kunnskap, formulere spørsmål, argumenter og forklaringer. Videre innebærer det å tilpasse uttrykksform, begreper og eksempler til formål og mottakere. Utviklingen av muntlige ferdigheter i naturfag går fra å kunne lytte og samtale om opplevelser og observasjoner til å kunne presentere og diskutere stadig mer komplekse emner. Dette innebærer i økende grad å kunne bruke naturfaglige begreper til å uttrykke forståelse, til å ha egne vurderinger og til å delta i faglige diskusjoner.',
      'Å kunne skrive i naturfag er å bruke naturfaglige tekstsjangere til å formulere spørsmål og hypoteser, skrive planer og forklaringer, sammenligne og reflektere over informasjon og bruke kilder hensiktsmessig. Det innebærer også å beskrive observasjoner og erfaringer, sammenstille informasjon, argumentere for synspunkter og rapportere fra feltarbeid, eksperimenter og teknologiske utviklingsprosesser. Skriveprosessen fra planlegging til bearbeiding og presentasjon av tekster innebærer bruk av naturfaglige begreper, figurer og symboler tilpasset formål og mottaker. Utviklingen av skriveferdigheter i naturfag går fra å bruke enkle uttrykksformer til gradvis å ta i bruk mer presise naturfaglige begreper, symboler, grafikk og argumentasjon. Dette innebærer å kunne skrive stadig mer komplekse tekster som bygger på kritisk og variert kildebruk tilpasset formål og mottaker.',
      'Å kunne lese i naturfag er å forstå og bruke naturfaglige begreper, symboler, figurer og argumenter gjennom målrettet arbeid med naturfaglige tekster. Dette innebærer å kunne identifisere, tolke og bruke informasjon fra sammensatte tekster i bøker, aviser, bruksanvisninger, regelverk, brosjyrer og digitale kilder. Lesing i naturfag inkluderer kritisk vurdering av hvordan informasjon framstilles og brukes i argumenter, blant annet gjennom å kunne skille mellom data, antakelser, påstander, hypoteser og konklusjoner. Utviklingen av leseferdighet i naturfag går fra å finne og bruke uttrykt informasjon i enkle tekster til å forstå tekster med stadig flere fagbegreper, symboler, figurer, tabeller og implisitt informasjon. Kravet til kritisk lesing og evne til å identifisere relevant informasjon og vurdere kilders troverdighet øker, fra å kunne bruke tilrettelagte kilder til å kunne innhente og sammenligne informasjon fra ulike kilder og vurdere relevansen.',
      'Å kunne regne i naturfag er å innhente, bearbeide og framstille tallmateriale. Det innebærer å bruke begreper, måleinstrumenter, måleenheter, formler og grafikk. Regning i naturfag er også å kunne sammenligne, vurdere og argumentere for gyldigheten av beregninger, resultater og framstillinger. Utviklingen av regneferdigheter i naturfag går fra å bruke enkle metoder for opptelling og klassifisering til å kunne vurdere valg av metoder, begreper, formler og måleinstrumenter. Videre innebærer det å kunne gjøre gradvis mer avanserte framstillinger og vurderinger og bruke regning i faglig argumentasjon.',
      'Digitale ferdigheter i naturfag er å bruke digitale verktøy til å utforske, registrere, gjøre beregninger, visualisere, dokumentere og publisere data fra egne og andres studier, forsøk og feltarbeid. Det innebærer også å bruke søkeverktøy, beherske søkestrategier og kritisk vurdere kilder og velge ut relevant informasjon om naturfaglige tema. Utviklingen av digitale ferdigheter i naturfag går fra å kunne bruke digitale verktøy til i økende grad å utvise selvstendighet og dømmekraft i valg og bruk av digitale kilder, verktøy, medier og informasjon.'
    ],
    levels: [5, 6, 7]
  },
  'NAT1-03-4': {
    ID: 'NAT1-03-4',
    title: 'Naturfag 8. - 10. trinn',
    hours: 14940,
    chapters: [
      {
        title: 'Forskerspiren',
        goals: [
          'Formulere testbare hypoteser, planlegge og gjennomføre undersøkelser av dem og diskutere observasjoner og resultater i en rapport',
          'Innhente og bearbeide naturfaglige data, gjøre beregninger og framstille resultater grafisk',
          'Skrive forklarende og argumenterende tekster med referanser til relevante kilder, vurdere kvaliteten ved egne og andres tekster og revidere tekstene',
          'Forklare betydningen av å se etter sammenhenger mellom årsak og virkning og forklare hvorfor argumentering, uenighet og publisering er viktig i naturvitenskapen',
          'Identifisere naturfaglige argumenter, fakta og påstander i tekster og grafikk fra aviser, brosjyrer og andre medier, og vurdere innholdet kritisk',
          'Følge sikkerhetstiltak som er beskrevet i HMS-rutiner og risikovurderinger'
        ]
      },
      {
        title: 'Mangfold i naturen',
        goals: [
          'Forklare hovedtrekkene i evolusjonsteorien og gjøre rede for observasjoner som støtter teorien',
          'Beskrive oppbygningen av dyre- og planteceller og forklare hovedtrekkene i fotosyntese og celleånding',
          'Gjøre rede for celledeling og for genetisk variasjon og arv',
          'Forklare hovedtrekk i teorier for hvordan jorda endrer seg og har endret seg gjennom tidene, og grunnlaget for disse teoriene',
          'Undersøke og registrere biotiske og abiotiske faktorer i et økosystem i nærområdet og forklare sammenhenger mellom faktorene',
          'Observere og gi eksempler på hvordan menneskelig aktivitet har påvirket et naturområde, undersøke ulike interessegruppers syn på påvirkningen og foreslå tiltak som kan verne naturen for framtidige generasjoner',
          'Gi varierte eksempler på hvordan samer utnytter ressurser i naturen'
        ]
      },
      {
        title: 'Kropp og helse',
        goals: [
          'Beskrive nervesystemet og hormonsystemet og forklare hvordan de styrer prosesser i kroppen',
          'Beskrive kort fosterutviklingen og hvordan en fødsel foregår',
          'Formulere og drøfte problemstillinger knyttet til seksualitet, seksuell orientering, kjønnsidentitet, grensesetting og respekt, seksuelt overførbare sykdommer, prevensjon og abort',
          'Forklare hvordan egen livsstil kan påvirke helsen, herunder slanking og spiseforstyrrelser, sammenligne informasjon fra ulike kilder, og diskutere hvordan helseskader kan forebygges',
          'Gi eksempler på samisk og annen folkemedisin og diskutere forskjellen på alternativ medisin og skolemedisin'
        ]
      },
      {
        title: 'Fenomener og stoffer',
        goals: [
          'Beskrive universet og ulike teorier for hvordan det har utviklet seg',
          'Undersøke et emne fra utforskingen av verdensrommet, og sammenstille og presentere informasjon fra ulike kilder',
          'Vurdere egenskaper til grunnstoffer og forbindelser ved bruk av periodesystemet',
          'Undersøke egenskaper til noen stoffer fra hverdagen og gjøre enkle beregninger knyttet til fortynning av løsninger',
          'Undersøke og klassifisere rene stoffer og stoffblandinger etter løselighet i vann, brennbarhet og sure og basiske egenskaper',
          'Planlegge og gjennomføre forsøk med påvisningsreaksjoner, separasjon av stoffer i en blanding og analyse av ukjent stoff',
          'Undersøke hydrokarboner, alkoholer, karboksylsyrer og karbohydrater, beskrive stoffene og gi eksempler på framstillingsmåter og bruksområder',
          'Forklare hvordan råolje og naturgass er blitt til',
          'Bruke begrepene strøm, spenning, resistans, effekt og induksjon til å forklare resultater fra forsøk med strømkretser',
          'Forklare hvordan vi kan produsere elektrisk energi fra fornybare og ikke-fornybare energikilder, og diskutere hvilke miljøeffekter som følger med ulike måter å produsere energi på',
          'Gjøre rede for begrepene fart og akselerasjon, måle størrelsene med enkle hjelpemidler og gi eksempler på hvordan kraft er knyttet til akselerasjon',
          'Gjøre forsøk og enkle beregninger med arbeid, energi og effekt',
          'Gjøre greie for hvordan trafikksikkerhetsutstyr hindrer og minsker skader ved uhell og ulykker',
          'Gjennomføre forsøk med lys, syn og farger, og beskrive og forklare resultatene'
        ]
      },
      {
        title: 'Teknologi og design',
        goals: [
          'Utvikle produkter ut fra kravspesifikasjoner og vurdere produktenes funksjonalitet, brukervennlighet og livsløp i forhold til bærekraftig utvikling',
          'Teste og beskrive egenskaper ved materialer som brukes i en produksjonsprosess, og vurdere materialbruken ut fra miljøhensyn',
          'Beskrive et elektronisk kommunikasjonssystem, forklare hvordan informasjon overføres fra avsender til mottaker, og gjøre rede for positive og negative konsekvenser'
        ]
      }
    ],
    purpose: [
      'Naturvitenskapen har vokst fram som følge av menneskers nysgjerrighet og behov for å finne svar på spørsmål om sin egen eksistens, om liv og livsformer og vår plass i naturen og i universet, og er på den måten en del av vår kultur.',
      'Lover og teorier i naturvitenskapen er modeller av en sammensatt virkelighet, og disse modellene endres eller videreutvikles gjennom nye observasjoner, eksperimenter og ideer. En viktig del av allmennkunnskapen er å kjenne til at naturvitenskapen er i utvikling, og at forskning og ny kunnskap i naturvitenskap og teknologi har stor betydning for samfunnsutviklingen og for livsmiljøet.',
      'Selv om naturvitenskapen er delt opp i ulike fagdisipliner, som biologi, fysikk og kjemi og geofag, er målet at skolefaget naturfag både teoretisk og praktisk framstår som et helhetlig fag.',
      'Kunnskap om, forståelse av og opplevelser i naturen kan fremme viljen til å verne om naturressursene, bevare biologisk mangfold og bidra til bærekraftig utvikling. I denne sammenhengen har samer og andre urfolk kunnskap om naturen som det er viktig å vise respekt for. Samtidig skal naturfag bidra til at barn og unge utvikler kunnskaper og holdninger som gir dem et gjennomtenkt syn på samspillet mellom natur, individ, teknologi, samfunn og forskning. Dette er viktig for den enkeltes mulighet til å forstå ulike typer naturvitenskapelig og teknologisk informasjon. Dette skal gi den enkelte et grunnlag for å delta i prosesser i samfunnet.',
      'Å arbeide både praktisk og teoretisk i laboratorier og i naturen med ulike problemstillinger er nødvendig for å få erfaring med og utvikle kunnskap om metoder og tenkemåter i naturvitenskapen. Dette kan bidra til å utvikle kreativitet, kritisk evne, åpenhet og aktiv deltakelse i situasjoner der naturfaglig kunnskap og ekspertise inngår. Varierte læringsmiljøer, som feltarbeid i naturen, eksperimenter i laboratoriet og ekskursjoner til museer, vitensentre og bedrifter, vil berike opplæringen i naturfag og gi rom for undring, nysgjerrighet og fascinasjon. Kompetanse i å forstå ulike typer naturvitenskapelige tekster, metoder og teknologiske løsninger gir et godt grunnlag for yrkesfaglige utdanninger, videre studier og livslang læring i yrke og fritid.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I naturfag forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i naturfag er å lytte, tale og samtale for å beskrive, dele og utvikle kunnskap med naturfaglig innhold som er knyttet til observasjoner og erfaringer. Det innebærer å bruke naturfaglige begreper for å formidle kunnskap, formulere spørsmål, argumenter og forklaringer. Videre innebærer det å tilpasse uttrykksform, begreper og eksempler til formål og mottakere. Utviklingen av muntlige ferdigheter i naturfag går fra å kunne lytte og samtale om opplevelser og observasjoner til å kunne presentere og diskutere stadig mer komplekse emner. Dette innebærer i økende grad å kunne bruke naturfaglige begreper til å uttrykke forståelse, til å ha egne vurderinger og til å delta i faglige diskusjoner.',
      'Å kunne skrive i naturfag er å bruke naturfaglige tekstsjangere til å formulere spørsmål og hypoteser, skrive planer og forklaringer, sammenligne og reflektere over informasjon og bruke kilder hensiktsmessig. Det innebærer også å beskrive observasjoner og erfaringer, sammenstille informasjon, argumentere for synspunkter og rapportere fra feltarbeid, eksperimenter og teknologiske utviklingsprosesser. Skriveprosessen fra planlegging til bearbeiding og presentasjon av tekster innebærer bruk av naturfaglige begreper, figurer og symboler tilpasset formål og mottaker. Utviklingen av skriveferdigheter i naturfag går fra å bruke enkle uttrykksformer til gradvis å ta i bruk mer presise naturfaglige begreper, symboler, grafikk og argumentasjon. Dette innebærer å kunne skrive stadig mer komplekse tekster som bygger på kritisk og variert kildebruk tilpasset formål og mottaker.',
      'Å kunne lese i naturfag er å forstå og bruke naturfaglige begreper, symboler, figurer og argumenter gjennom målrettet arbeid med naturfaglige tekster. Dette innebærer å kunne identifisere, tolke og bruke informasjon fra sammensatte tekster i bøker, aviser, bruksanvisninger, regelverk, brosjyrer og digitale kilder. Lesing i naturfag inkluderer kritisk vurdering av hvordan informasjon framstilles og brukes i argumenter, blant annet gjennom å kunne skille mellom data, antakelser, påstander, hypoteser og konklusjoner. Utviklingen av leseferdighet i naturfag går fra å finne og bruke uttrykt informasjon i enkle tekster til å forstå tekster med stadig flere fagbegreper, symboler, figurer, tabeller og implisitt informasjon. Kravet til kritisk lesing og evne til å identifisere relevant informasjon og vurdere kilders troverdighet øker, fra å kunne bruke tilrettelagte kilder til å kunne innhente og sammenligne informasjon fra ulike kilder og vurdere relevansen.',
      'Å kunne regne i naturfag er å innhente, bearbeide og framstille tallmateriale. Det innebærer å bruke begreper, måleinstrumenter, måleenheter, formler og grafikk. Regning i naturfag er også å kunne sammenligne, vurdere og argumentere for gyldigheten av beregninger, resultater og framstillinger. Utviklingen av regneferdigheter i naturfag går fra å bruke enkle metoder for opptelling og klassifisering til å kunne vurdere valg av metoder, begreper, formler og måleinstrumenter. Videre innebærer det å kunne gjøre gradvis mer avanserte framstillinger og vurderinger og bruke regning i faglig argumentasjon.',
      'Digitale ferdigheter i naturfag er å bruke digitale verktøy til å utforske, registrere, gjøre beregninger, visualisere, dokumentere og publisere data fra egne og andres studier, forsøk og feltarbeid. Det innebærer også å bruke søkeverktøy, beherske søkestrategier og kritisk vurdere kilder og velge ut relevant informasjon om naturfaglige tema. Utviklingen av digitale ferdigheter i naturfag går fra å kunne bruke digitale verktøy til i økende grad å utvise selvstendighet og dømmekraft i valg og bruk av digitale kilder, verktøy, medier og informasjon.'
    ],
    levels: [8, 9, 10]
  },
  'NOR1-05-1': {
    ID: 'NOR1-05-1',
    title: 'Norsk 1. - 2. trinn',
    hours: 27930,
    chapters: [
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Lytte, ta ordet etter tur og gi respons til andre i samtaler',
          'Lytte til tekster på bokmål og nynorsk og samtale om dem',
          'Lytte etter, forstå, gjengi og kombinere informasjon',
          'Leke, improvisere og eksperimentere med rim, rytme, språklyder, stavelser, meningsbærende elementer og ord',
          'Samtale om hvordan valg av ord, stemmebruk og intonasjon skaper mening',
          'Fortelle sammenhengende om opplevelser og erfaringer',
          'Sette ord på egne følelser og meninger',
          'Uttrykke egne tekstopplevelser gjennom ord, tegning, sang og andre estetiske uttrykk'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Vise forståelse for sammenhengen mellom språklyd og bokstav og mellom talespråk og skriftspråk',
          'Trekke lyder sammen til ord',
          'Lese store og små trykte bokstaver',
          'Lese enkle tekster med sammenheng og forståelse på papir og skjerm',
          'Bruke egne kunnskaper og erfaringer for å forstå og kommentere innholdet i leste tekster',
          'Skrive setninger med store og små bokstaver og punktum i egen håndskrift og på tastatur',
          'Skrive enkle beskrivende og fortellende tekster',
          'Arbeide kreativt med tegning og skriving i forbindelse med lesing',
          'Skrive etter mønster av enkle eksempeltekster og ut fra andre kilder for skriving'
        ]
      },
      {
        title: 'Språk, litteratur og kultur',
        goals: [
          'Samtale om opphavet til og betydningen av noen kjente ordtak, begreper og faste uttrykk',
          'Samtale om begrepene dialekt, bokmål og nynorsk',
          'Samtale om hvordan ord og bilde virker sammen i bildebøker og andre bildemedier',
          'Samtale om innhold og form i eldre og nyere sanger, regler og dikt',
          'Samtale om personer og handling i eventyr og fortellinger',
          'Finne skjønnlitteratur og sakprosa på biblioteket til egen lesing'
        ]
      }
    ],
    purpose: [
      'Norsk er et sentralt fag for kulturforståelse, kommunikasjon, dannelse og identitetsutvikling. Gjennom aktiv bruk av det norske språket innlemmes barn og unge i kultur og samfunnsliv, og rustes til deltakelse i arbeidsliv og demokratiske prosesser. Norskfaget åpner en arena der de får anledning til å finne sine egne stemmer, ytre seg, bli hørt og få svar.',
      'Samtidig skal norskfaget utvikle elevenes språkkompetanse ut fra de evner og forutsetninger den enkelte har. Muntlige ferdigheter, lese- og skrivekompetanse er både et mål i seg selv og et nødvendig grunnlag for læring og forståelse i alle fag på alle trinn. Faget skal motivere til lese- og skrivelyst og bidra til å utvikle gode læringsstrategier.',
      'I norskfaget møter elevene et bredt spekter av tekster. Faget bygger på et tekstbegrep som inkluderer muntlige, skriftlige og sammensatte tekster, der skrift, lyd og bilder spiller sammen. Elevene skal lære å orientere seg i mangfoldet av tekster, og faget skal gi rom for både opplevelse og refleksjon. I løpet av opplæringen skal de lese skjønnlitteratur og sakprosa, utvikle evnen til kritisk tenkning og få perspektiv på teksthistorien. Gjennom muntlig og skriftlig kommunikasjon kan de sette ord på egne tanker og stå fram med meninger og vurderinger. De skal selv produsere ulike typer tekster med hensiktsmessige verktøy, og tilpasse språk og form til ulike formål, mottakere og medier. Etter hvert vil de også kunne fordype seg i faglige emner og bli dyktigere til å formidle et faglig innhold til andre.',
      'Norskfaget befinner seg i spenningsfeltet mellom det historiske og det samtidige, det nasjonale og det globale. Å se norsk språk, kultur og litteratur i et historisk og internasjonalt perspektiv kan gi elevene større forståelse for det samfunnet de er en del av. Norsk kulturarv byr på et forråd av tekster som kan få ny og uventet betydning når perspektivene utvides. Kulturarven er slik sett en levende tradisjon som forandres og skapes på nytt, og norskfaget skal oppmuntre elevene til å bli aktive bidragsytere i denne prosessen.',
      'I Norge er norsk og samisk offisielle språk, og bokmål og nynorsk er likestilte skriftlige målformer. Vi bruker mange ulike dialekter og talemålsvarianter, men også andre språk enn norsk. Det språklige mangfoldet er en ressurs for utviklingen av barn og unges språkkompetanse. Med utgangspunkt i denne språksituasjonen skal barn og unge få et bevisst forhold til språklig mangfold, og lære å lese og skrive både bokmål og nynorsk. Formålet med opplæringen er å styrke elevenes språklige trygghet og identitet, utvikle deres språkforståelse og gi et godt grunnlag for mestring av begge målformene i samfunns- og yrkesliv.',
      'Læreplanen i norsk gjelder også for elever med samisk eller finsk som andrespråk. Opplæringen i norsk skal sammen med opplæringen i andrespråket bidra til disse elevenes tospråklige kompetanse. For elever med tospråklig opplæring er det derfor viktig med et nært samarbeid mellom de to fagene.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I norsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i norsk er å skape mening gjennom å lytte, samtale og tale, og tilpasse språket til formål og mottaker. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å mestre ulike muntlige kommunikasjonssituasjoner og kunne planlegge og framføre muntlige presentasjoner av ulik art. Utviklingen av muntlige ferdigheter i norskfaget forutsetter systematisk arbeid med ulike muntlige sjangere og strategier i stadig mer komplekse lytte- og talesituasjoner. Det innebærer å tilegne seg fagkunnskap ved å lytte aktivt og å forstå og å bruke det muntlige språket stadig mer nyansert og presist i samtale om norskfaglige emner, problemstillinger og tekster av økende omfang og kompleksitet.',
      'Å kunne skrive i norsk er å uttrykke seg i norskfaglige sjangere på en hensiktsmessig måte. Det vil si å kunne skrive teksttyper som er relevante for faget, og å kunne ta i bruk norskfaglige begreper. Å skrive i norskfaget er også en måte å utvikle og strukturere tanker på og en metode for å lære. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å planlegge, utforme og bearbeide stadig mer komplekse tekster som er tilpasset formål og mottaker. Utviklingen av skriftlige ferdigheter i norskfaget forutsetter systematisk arbeid med formelle skriveferdigheter, tekstkunnskap og ulike skrivestrategier. Det innebærer å kunne uttrykke seg med stadig større språklig sikkerhet på både hovedmål og sidemål.',
      'Å kunne lese i norsk er å skape mening fra tekster fra nåtid og fortid i et bredt utvalg sjangere. Det innebærer å engasjere seg i tekster og få innsikt i andre menneskers tanker, opplevelser og skaperkraft. Det innebærer videre å kunne finne informasjon og forstå resonnementer og framstillinger i ulike typer tekster på skjerm og papir, og å kunne forholde seg kritisk og selvstendig til de leste tekstene. Utviklingen av leseferdigheter i norskfaget forutsetter at elevene leser ofte og mye, og at de arbeider systematisk med lesestrategier som er tilpasset formålet med lesingen, og med ulike typer tekster i faget. Utviklingen går fra grunnleggende avkoding og forståelse av enkle tekster til å kunne forstå, tolke, reflektere over og vurdere stadig mer komplekse tekster i ulike sjangere.',
      'Å kunne regne i norsk er å tolke og forstå informasjon i tekster som inneholder tall, størrelser eller geometriske figurer. Det vil si å kunne vurdere, reflektere over og kommunisere om sammensatte tekster som inneholder grafiske framstillinger, tabeller og statistikk. Utviklingen i regneferdigheter i norskfaget innebærer å skape helhetlig mening i stadig mer krevende tekster der ulike uttrykksformer må ses i sammenheng.',
      'Digitale ferdigheter i norsk er å bruke digitale verktøy, medier og ressurser for å innhente og behandle informasjon, skape og redigere ulike typer tekster og kommunisere med andre. I denne sammenhengen er det viktig å kunne vurdere og bruke kilder på en bevisst måte. Utviklingen av digitale ferdigheter er en del av lese- og skriveopplæringen i norskfaget, og innebærer å finne, bruke og etter hvert vurdere og referere til digitale kilder i skriftlige og muntlige tekster, og selv produsere stadig mer komplekse tekster. Videre innebærer det å utvikle kunnskap om opphavsrett og personvern, og ha en kritisk og selvstendig holdning til ulike typer digitale kilder.'
    ],
    levels: [1, 2]
  },
  'NOR1-05-2': {
    ID: 'NOR1-05-2',
    title: 'Norsk 3. - 4. trinn',
    hours: 27930,
    chapters: [
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Lytte etter, gjenfortelle, forklare og reflektere over innholdet i muntlige tekster',
          'Forstå noe svensk og dansk tale',
          'Bruke et egnet ordforråd til å samtale om faglige emner, fortelle om egne erfaringer og uttrykke egne meninger',
          'Samhandle med andre gjennom lek, dramatisering, samtale og diskusjon',
          'Følge opp innspill fra andre i faglige samtaler og stille oppklarende og utdypende spørsmål',
          'Variere stemmebruk og intonasjon i framføring av tekster'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Lese tekster av ulike typer på bokmål og nynorsk med sammenheng og forståelse',
          'Finne informasjon ved å kombinere ord og illustrasjon i tekster på skjerm og papir',
          'Gjenkjenne og bruke språklige virkemidler som gjentakelse, kontrast og enkle språklige bilder',
          'Lese, reflektere over og samtale om egne og andres tekster',
          'Bruke ulike typer notater og eksempeltekster som grunnlag for egen skriving',
          'Skrive enkle fortellende, beskrivende og argumenterende tekster',
          'Skrive med sammenhengende og funksjonell håndskrift og bruke tastatur i egen skriving',
          'Strukturere tekster med overskrift, innledning, hoveddel og avslutning',
          'Variere ordvalg og setningsbygning i egen skriving',
          'Lage tekster som kombinerer ord, lyd og bilde, med og uten digitale verktøy',
          'Søke etter informasjon, skape, lagre og gjenfinne tekster ved hjelp av digitale verktøy',
          'Bruke bibliotek og Internett til å finne stoff til egen skriving'
        ]
      },
      {
        title: 'Språk, litteratur og kultur',
        goals: [
          'Beskrive ordklasser og deres funksjon',
          'Forklare hvordan man gjennom språkbruk kan krenke andre',
          'Beskrive eget talemål og sammenligne med andres',
          'Samtale om innhold og form i sammensatte tekster',
          'Gi uttrykk for egne tanker og opplevelser om barnelitteratur, teater, film, dataspill og TV-program',
          'Samtale om sanger, regler, dikt, fortellinger og eventyr fra fortid og nåtid på bokmål, nynorsk og i oversettelse fra samisk og andre språk',
          'Gi uttrykk for egne tanker om språk, personer og handling i tekster fra ulike tider og kulturer'
        ]
      }
    ],
    purpose: [
      'Norsk er et sentralt fag for kulturforståelse, kommunikasjon, dannelse og identitetsutvikling. Gjennom aktiv bruk av det norske språket innlemmes barn og unge i kultur og samfunnsliv, og rustes til deltakelse i arbeidsliv og demokratiske prosesser. Norskfaget åpner en arena der de får anledning til å finne sine egne stemmer, ytre seg, bli hørt og få svar.',
      'Samtidig skal norskfaget utvikle elevenes språkkompetanse ut fra de evner og forutsetninger den enkelte har. Muntlige ferdigheter, lese- og skrivekompetanse er både et mål i seg selv og et nødvendig grunnlag for læring og forståelse i alle fag på alle trinn. Faget skal motivere til lese- og skrivelyst og bidra til å utvikle gode læringsstrategier.',
      'I norskfaget møter elevene et bredt spekter av tekster. Faget bygger på et tekstbegrep som inkluderer muntlige, skriftlige og sammensatte tekster, der skrift, lyd og bilder spiller sammen. Elevene skal lære å orientere seg i mangfoldet av tekster, og faget skal gi rom for både opplevelse og refleksjon. I løpet av opplæringen skal de lese skjønnlitteratur og sakprosa, utvikle evnen til kritisk tenkning og få perspektiv på teksthistorien. Gjennom muntlig og skriftlig kommunikasjon kan de sette ord på egne tanker og stå fram med meninger og vurderinger. De skal selv produsere ulike typer tekster med hensiktsmessige verktøy, og tilpasse språk og form til ulike formål, mottakere og medier. Etter hvert vil de også kunne fordype seg i faglige emner og bli dyktigere til å formidle et faglig innhold til andre.',
      'Norskfaget befinner seg i spenningsfeltet mellom det historiske og det samtidige, det nasjonale og det globale. Å se norsk språk, kultur og litteratur i et historisk og internasjonalt perspektiv kan gi elevene større forståelse for det samfunnet de er en del av. Norsk kulturarv byr på et forråd av tekster som kan få ny og uventet betydning når perspektivene utvides. Kulturarven er slik sett en levende tradisjon som forandres og skapes på nytt, og norskfaget skal oppmuntre elevene til å bli aktive bidragsytere i denne prosessen.',
      'I Norge er norsk og samisk offisielle språk, og bokmål og nynorsk er likestilte skriftlige målformer. Vi bruker mange ulike dialekter og talemålsvarianter, men også andre språk enn norsk. Det språklige mangfoldet er en ressurs for utviklingen av barn og unges språkkompetanse. Med utgangspunkt i denne språksituasjonen skal barn og unge få et bevisst forhold til språklig mangfold, og lære å lese og skrive både bokmål og nynorsk. Formålet med opplæringen er å styrke elevenes språklige trygghet og identitet, utvikle deres språkforståelse og gi et godt grunnlag for mestring av begge målformene i samfunns- og yrkesliv.',
      'Læreplanen i norsk gjelder også for elever med samisk eller finsk som andrespråk. Opplæringen i norsk skal sammen med opplæringen i andrespråket bidra til disse elevenes tospråklige kompetanse. For elever med tospråklig opplæring er det derfor viktig med et nært samarbeid mellom de to fagene.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I norsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i norsk er å skape mening gjennom å lytte, samtale og tale, og tilpasse språket til formål og mottaker. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å mestre ulike muntlige kommunikasjonssituasjoner og kunne planlegge og framføre muntlige presentasjoner av ulik art. Utviklingen av muntlige ferdigheter i norskfaget forutsetter systematisk arbeid med ulike muntlige sjangere og strategier i stadig mer komplekse lytte- og talesituasjoner. Det innebærer å tilegne seg fagkunnskap ved å lytte aktivt og å forstå og å bruke det muntlige språket stadig mer nyansert og presist i samtale om norskfaglige emner, problemstillinger og tekster av økende omfang og kompleksitet.',
      'Å kunne skrive i norsk er å uttrykke seg i norskfaglige sjangere på en hensiktsmessig måte. Det vil si å kunne skrive teksttyper som er relevante for faget, og å kunne ta i bruk norskfaglige begreper. Å skrive i norskfaget er også en måte å utvikle og strukturere tanker på og en metode for å lære. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å planlegge, utforme og bearbeide stadig mer komplekse tekster som er tilpasset formål og mottaker. Utviklingen av skriftlige ferdigheter i norskfaget forutsetter systematisk arbeid med formelle skriveferdigheter, tekstkunnskap og ulike skrivestrategier. Det innebærer å kunne uttrykke seg med stadig større språklig sikkerhet på både hovedmål og sidemål.',
      'Å kunne lese i norsk er å skape mening fra tekster fra nåtid og fortid i et bredt utvalg sjangere. Det innebærer å engasjere seg i tekster og få innsikt i andre menneskers tanker, opplevelser og skaperkraft. Det innebærer videre å kunne finne informasjon og forstå resonnementer og framstillinger i ulike typer tekster på skjerm og papir, og å kunne forholde seg kritisk og selvstendig til de leste tekstene. Utviklingen av leseferdigheter i norskfaget forutsetter at elevene leser ofte og mye, og at de arbeider systematisk med lesestrategier som er tilpasset formålet med lesingen, og med ulike typer tekster i faget. Utviklingen går fra grunnleggende avkoding og forståelse av enkle tekster til å kunne forstå, tolke, reflektere over og vurdere stadig mer komplekse tekster i ulike sjangere.',
      'Å kunne regne i norsk er å tolke og forstå informasjon i tekster som inneholder tall, størrelser eller geometriske figurer. Det vil si å kunne vurdere, reflektere over og kommunisere om sammensatte tekster som inneholder grafiske framstillinger, tabeller og statistikk. Utviklingen i regneferdigheter i norskfaget innebærer å skape helhetlig mening i stadig mer krevende tekster der ulike uttrykksformer må ses i sammenheng.',
      'Digitale ferdigheter i norsk er å bruke digitale verktøy, medier og ressurser for å innhente og behandle informasjon, skape og redigere ulike typer tekster og kommunisere med andre. I denne sammenhengen er det viktig å kunne vurdere og bruke kilder på en bevisst måte. Utviklingen av digitale ferdigheter er en del av lese- og skriveopplæringen i norskfaget, og innebærer å finne, bruke og etter hvert vurdere og referere til digitale kilder i skriftlige og muntlige tekster, og selv produsere stadig mer komplekse tekster. Videre innebærer det å utvikle kunnskap om opphavsrett og personvern, og ha en kritisk og selvstendig holdning til ulike typer digitale kilder.'
    ],
    levels: [3, 4]
  },
  'NOR1-05-3': {
    ID: 'NOR1-05-3',
    title: 'Norsk 5. - 7. trinn',
    hours: 26460,
    chapters: [
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Lytte til og videreutvikle innspill fra andre og skille mellom meninger og fakta',
          'Uttrykke og grunngi egne standpunkter og vise respekt for andres',
          'Bruke sang, musikk og bilder i framføringer og presentasjoner',
          'Opptre i ulike roller gjennom drama-aktiviteter, opplesing og presentasjon',
          'Uttrykke seg med et variert ordforråd tilpasset kommunikasjonssituasjonen',
          'Presentere et fagstoff tilpasset formål og mottaker, med eller uten digitale verktøy',
          'Vurdere andres muntlige framføringer ut fra faglige kriterier'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Lese et bredt utvalg norske og oversatte tekster i ulike sjangere på bokmål og nynorsk, og reflektere over innhold og form i teksten',
          'Referere, oppsummere og reflektere over hovedmomenter i en tekst',
          'Lese enkle tekster på svensk og dansk og gjengi og kommentere innholdet',
          'Forstå og tolke opplysninger fra flere uttrykksformer i en sammensatt tekst',
          'Mestre sentrale regler i formverk og ortografi og skrive tekster med variert setningsbygning og funksjonell tegnsetting',
          'Skrive sammenhengende med personlig og funksjonell håndskrift, og bruke tastatur på en hensiktsmessig måte',
          'Skrive tekster med klart uttrykt tema og skape sammenheng mellom setninger og avsnitt',
          'Skrive fortellende, beskrivende, reflekterende og argumenterende tekster etter mønster av eksempeltekster og andre kilder, og tilpasse egne tekster til formål og mottaker',
          'Eksperimentere med skriving av enkle tekster på sidemål',
          'Gi tilbakemelding på andres tekster ut fra faglige kriterier og bearbeide egne tekster på bakgrunn av tilbakemeldinger',
          'Bruke digitale kilder og verktøy til å lage sammensatte tekster med hyperkoplinger og varierte estetiske virkemidler',
          'Velge ut og vurdere informasjon fra bibliotek og digitale informasjonskanaler'
        ]
      },
      {
        title: 'Språk, litteratur og kultur',
        goals: [
          'Gi eksempler på noen likheter og forskjeller mellom muntlig og skriftlig språk',
          'Utføre grunnleggende setningsanalyse og vise hvordan tekster er bygd opp ved hjelp av begreper fra grammatikk og tekstkunnskap',
          'Sammenligne språk og språkbruk i tekster på bokmål og nynorsk',
          'Lese og uttale stedsnavn som inneholder spesielle nordsamiske bokstaver, og kjenne til enkelte ord og uttrykk på ett av de samiske språkene',
          'Sammenligne talemål i eget miljø med noen andre talemålsvarianter og med de skriftlige målformene bokmål og nynorsk',
          'Gi eksempler på og reflektere over hvordan språk kan uttrykke og skape holdninger til enkeltindivider og grupper av mennesker',
          'Presentere egne tolkinger av personer, handling og tema i et variert utvalg av barne- og ungdomslitteratur på bokmål og nynorsk og i oversettelse fra samisk',
          'Vurdere tekster med utgangspunkt i egne opplevelser og med forståelse for språk og innhold',
          'Kjenne til opphavsrettslige regler for bruk av kilder',
          'Bruke forskjellige former for digitale og papirbaserte ordbøker'
        ]
      }
    ],
    purpose: [
      'Norsk er et sentralt fag for kulturforståelse, kommunikasjon, dannelse og identitetsutvikling. Gjennom aktiv bruk av det norske språket innlemmes barn og unge i kultur og samfunnsliv, og rustes til deltakelse i arbeidsliv og demokratiske prosesser. Norskfaget åpner en arena der de får anledning til å finne sine egne stemmer, ytre seg, bli hørt og få svar.',
      'Samtidig skal norskfaget utvikle elevenes språkkompetanse ut fra de evner og forutsetninger den enkelte har. Muntlige ferdigheter, lese- og skrivekompetanse er både et mål i seg selv og et nødvendig grunnlag for læring og forståelse i alle fag på alle trinn. Faget skal motivere til lese- og skrivelyst og bidra til å utvikle gode læringsstrategier.',
      'I norskfaget møter elevene et bredt spekter av tekster. Faget bygger på et tekstbegrep som inkluderer muntlige, skriftlige og sammensatte tekster, der skrift, lyd og bilder spiller sammen. Elevene skal lære å orientere seg i mangfoldet av tekster, og faget skal gi rom for både opplevelse og refleksjon. I løpet av opplæringen skal de lese skjønnlitteratur og sakprosa, utvikle evnen til kritisk tenkning og få perspektiv på teksthistorien. Gjennom muntlig og skriftlig kommunikasjon kan de sette ord på egne tanker og stå fram med meninger og vurderinger. De skal selv produsere ulike typer tekster med hensiktsmessige verktøy, og tilpasse språk og form til ulike formål, mottakere og medier. Etter hvert vil de også kunne fordype seg i faglige emner og bli dyktigere til å formidle et faglig innhold til andre.',
      'Norskfaget befinner seg i spenningsfeltet mellom det historiske og det samtidige, det nasjonale og det globale. Å se norsk språk, kultur og litteratur i et historisk og internasjonalt perspektiv kan gi elevene større forståelse for det samfunnet de er en del av. Norsk kulturarv byr på et forråd av tekster som kan få ny og uventet betydning når perspektivene utvides. Kulturarven er slik sett en levende tradisjon som forandres og skapes på nytt, og norskfaget skal oppmuntre elevene til å bli aktive bidragsytere i denne prosessen.',
      'I Norge er norsk og samisk offisielle språk, og bokmål og nynorsk er likestilte skriftlige målformer. Vi bruker mange ulike dialekter og talemålsvarianter, men også andre språk enn norsk. Det språklige mangfoldet er en ressurs for utviklingen av barn og unges språkkompetanse. Med utgangspunkt i denne språksituasjonen skal barn og unge få et bevisst forhold til språklig mangfold, og lære å lese og skrive både bokmål og nynorsk. Formålet med opplæringen er å styrke elevenes språklige trygghet og identitet, utvikle deres språkforståelse og gi et godt grunnlag for mestring av begge målformene i samfunns- og yrkesliv.',
      'Læreplanen i norsk gjelder også for elever med samisk eller finsk som andrespråk. Opplæringen i norsk skal sammen med opplæringen i andrespråket bidra til disse elevenes tospråklige kompetanse. For elever med tospråklig opplæring er det derfor viktig med et nært samarbeid mellom de to fagene.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I norsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i norsk er å skape mening gjennom å lytte, samtale og tale, og tilpasse språket til formål og mottaker. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å mestre ulike muntlige kommunikasjonssituasjoner og kunne planlegge og framføre muntlige presentasjoner av ulik art. Utviklingen av muntlige ferdigheter i norskfaget forutsetter systematisk arbeid med ulike muntlige sjangere og strategier i stadig mer komplekse lytte- og talesituasjoner. Det innebærer å tilegne seg fagkunnskap ved å lytte aktivt og å forstå og å bruke det muntlige språket stadig mer nyansert og presist i samtale om norskfaglige emner, problemstillinger og tekster av økende omfang og kompleksitet.',
      'Å kunne skrive i norsk er å uttrykke seg i norskfaglige sjangere på en hensiktsmessig måte. Det vil si å kunne skrive teksttyper som er relevante for faget, og å kunne ta i bruk norskfaglige begreper. Å skrive i norskfaget er også en måte å utvikle og strukturere tanker på og en metode for å lære. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å planlegge, utforme og bearbeide stadig mer komplekse tekster som er tilpasset formål og mottaker. Utviklingen av skriftlige ferdigheter i norskfaget forutsetter systematisk arbeid med formelle skriveferdigheter, tekstkunnskap og ulike skrivestrategier. Det innebærer å kunne uttrykke seg med stadig større språklig sikkerhet på både hovedmål og sidemål.',
      'Å kunne lese i norsk er å skape mening fra tekster fra nåtid og fortid i et bredt utvalg sjangere. Det innebærer å engasjere seg i tekster og få innsikt i andre menneskers tanker, opplevelser og skaperkraft. Det innebærer videre å kunne finne informasjon og forstå resonnementer og framstillinger i ulike typer tekster på skjerm og papir, og å kunne forholde seg kritisk og selvstendig til de leste tekstene. Utviklingen av leseferdigheter i norskfaget forutsetter at elevene leser ofte og mye, og at de arbeider systematisk med lesestrategier som er tilpasset formålet med lesingen, og med ulike typer tekster i faget. Utviklingen går fra grunnleggende avkoding og forståelse av enkle tekster til å kunne forstå, tolke, reflektere over og vurdere stadig mer komplekse tekster i ulike sjangere.',
      'Å kunne regne i norsk er å tolke og forstå informasjon i tekster som inneholder tall, størrelser eller geometriske figurer. Det vil si å kunne vurdere, reflektere over og kommunisere om sammensatte tekster som inneholder grafiske framstillinger, tabeller og statistikk. Utviklingen i regneferdigheter i norskfaget innebærer å skape helhetlig mening i stadig mer krevende tekster der ulike uttrykksformer må ses i sammenheng.',
      'Digitale ferdigheter i norsk er å bruke digitale verktøy, medier og ressurser for å innhente og behandle informasjon, skape og redigere ulike typer tekster og kommunisere med andre. I denne sammenhengen er det viktig å kunne vurdere og bruke kilder på en bevisst måte. Utviklingen av digitale ferdigheter er en del av lese- og skriveopplæringen i norskfaget, og innebærer å finne, bruke og etter hvert vurdere og referere til digitale kilder i skriftlige og muntlige tekster, og selv produsere stadig mer komplekse tekster. Videre innebærer det å utvikle kunnskap om opphavsrett og personvern, og ha en kritisk og selvstendig holdning til ulike typer digitale kilder.'
    ],
    levels: [5, 6, 7]
  },
  'NOR1-05-4': {
    ID: 'NOR1-05-4',
    title: 'Norsk 8. - 10. trinn',
    hours: 23880,
    chapters: [
      {
        title: 'Muntlig kommunikasjon',
        goals: [
          'Lytte til, oppsummere hovedinnhold og trekke ut relevant informasjon i muntlige tekster',
          'Lytte til, forstå og gjengi informasjon fra svensk og dansk',
          'Samtale om form, innhold og formål i litteratur, teater og film og framføre tolkende opplesing og dramatisering',
          'Delta i diskusjoner med begrunnede meninger og saklig argumentasjon',
          'Presentere norskfaglige og tverrfaglige emner med relevant terminologi og formålstjenlig bruk av digitale verktøy og medier',
          'Vurdere egne og andres muntlige framføringer ut fra faglige kriterier'
        ]
      },
      {
        title: 'Skriftlig kommunikasjon',
        goals: [
          'Orientere seg i store tekstmengder på skjerm og papir for å finne, kombinere og vurdere relevant informasjon i arbeid med faget',
          'Lese og analysere et bredt utvalg tekster i ulike sjangere og medier på bokmål og nynorsk og formidle mulige tolkninger',
          'Gjengi innholdet og finne tema i et utvalg tekster på svensk og dansk',
          'Skrive ulike typer tekster etter mønster av eksempeltekster og andre kilder',
          'Gjenkjenne virkemidlene humor, ironi, kontraster og sammenligninger, symboler og språklige bilder og bruke noen av dem i egne tekster',
          'Planlegge, utforme og bearbeide egne tekster manuelt og digitalt, og vurdere dem underveis i prosessen ved hjelp av kunnskap om språk og tekst',
          'Uttrykke seg med et variert ordforråd og mestre formverk, ortografi og tekstbinding',
          'Skrive kreative, informative, reflekterende og argumenterende tekster på hovedmål og sidemål med begrunnede synspunkter og tilpasset mottaker, formål og medium',
          'Integrere, referere og sitere relevante kilder på en etterprøvbar måte der det er hensiktsmessig'
        ]
      },
      {
        title: 'Språk, litteratur og kultur',
        goals: [
          'Gjenkjenne retoriske appellformer og måter å argumentere på',
          'Drøfte hvordan språkbruk kan virke diskriminerende og trakasserende',
          'Gjøre rede for noen kjennetegn ved hovedgrupper av talemål i Norge, og diskutere holdninger til ulike talemål og til de skriftlige målformene nynorsk og bokmål',
          'Beherske grammatiske begreper som beskriver hvordan språk er bygd opp',
          'Bruke grammatiske begreper til å sammenligne nynorsk og bokmål',
          'Forklare bakgrunnen for at det er to likestilte norske målformer, og gjøre rede for språkdebatt og språklig variasjon i Norge i dag',
          'Gjøre rede for utbredelsen av de samiske språkene og for rettigheter i forbindelse med samisk språk i Norge',
          'Presentere tema og uttrykksmåter i et utvalg sentrale samtidstekster og noen klassiske tekster i norsk litteratur',
          'Gi eksempler på og kommentere hvordan samfunnsforhold, verdier og tenkemåter framstilles i oversatte tekster fra samisk og andre språk',
          'Beskrive samspillet mellom estetiske virkemidler i sammensatte tekster, og reflektere over hvordan vi påvirkes av lyd, språk og bilder',
          'Presentere resultatet av fordypning i to selvvalgte emner: et forfatterskap, et litterært emne eller et språklig emne, og begrunne valg av tekster og emne',
          'Forklare og bruke grunnleggende prinsipper for personvern og opphavsrett ved publisering og bruk av tekster'
        ]
      }
    ],
    purpose: [
      'Norsk er et sentralt fag for kulturforståelse, kommunikasjon, dannelse og identitetsutvikling. Gjennom aktiv bruk av det norske språket innlemmes barn og unge i kultur og samfunnsliv, og rustes til deltakelse i arbeidsliv og demokratiske prosesser. Norskfaget åpner en arena der de får anledning til å finne sine egne stemmer, ytre seg, bli hørt og få svar.',
      'Samtidig skal norskfaget utvikle elevenes språkkompetanse ut fra de evner og forutsetninger den enkelte har. Muntlige ferdigheter, lese- og skrivekompetanse er både et mål i seg selv og et nødvendig grunnlag for læring og forståelse i alle fag på alle trinn. Faget skal motivere til lese- og skrivelyst og bidra til å utvikle gode læringsstrategier.',
      'I norskfaget møter elevene et bredt spekter av tekster. Faget bygger på et tekstbegrep som inkluderer muntlige, skriftlige og sammensatte tekster, der skrift, lyd og bilder spiller sammen. Elevene skal lære å orientere seg i mangfoldet av tekster, og faget skal gi rom for både opplevelse og refleksjon. I løpet av opplæringen skal de lese skjønnlitteratur og sakprosa, utvikle evnen til kritisk tenkning og få perspektiv på teksthistorien. Gjennom muntlig og skriftlig kommunikasjon kan de sette ord på egne tanker og stå fram med meninger og vurderinger. De skal selv produsere ulike typer tekster med hensiktsmessige verktøy, og tilpasse språk og form til ulike formål, mottakere og medier. Etter hvert vil de også kunne fordype seg i faglige emner og bli dyktigere til å formidle et faglig innhold til andre.',
      'Norskfaget befinner seg i spenningsfeltet mellom det historiske og det samtidige, det nasjonale og det globale. Å se norsk språk, kultur og litteratur i et historisk og internasjonalt perspektiv kan gi elevene større forståelse for det samfunnet de er en del av. Norsk kulturarv byr på et forråd av tekster som kan få ny og uventet betydning når perspektivene utvides. Kulturarven er slik sett en levende tradisjon som forandres og skapes på nytt, og norskfaget skal oppmuntre elevene til å bli aktive bidragsytere i denne prosessen.',
      'I Norge er norsk og samisk offisielle språk, og bokmål og nynorsk er likestilte skriftlige målformer. Vi bruker mange ulike dialekter og talemålsvarianter, men også andre språk enn norsk. Det språklige mangfoldet er en ressurs for utviklingen av barn og unges språkkompetanse. Med utgangspunkt i denne språksituasjonen skal barn og unge få et bevisst forhold til språklig mangfold, og lære å lese og skrive både bokmål og nynorsk. Formålet med opplæringen er å styrke elevenes språklige trygghet og identitet, utvikle deres språkforståelse og gi et godt grunnlag for mestring av begge målformene i samfunns- og yrkesliv.',
      'Læreplanen i norsk gjelder også for elever med samisk eller finsk som andrespråk. Opplæringen i norsk skal sammen med opplæringen i andrespråket bidra til disse elevenes tospråklige kompetanse. For elever med tospråklig opplæring er det derfor viktig med et nært samarbeid mellom de to fagene.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de bidrar til utvikling av og er en del av fagkompetansen. I norsk forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i norsk er å skape mening gjennom å lytte, samtale og tale, og tilpasse språket til formål og mottaker. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å mestre ulike muntlige kommunikasjonssituasjoner og kunne planlegge og framføre muntlige presentasjoner av ulik art. Utviklingen av muntlige ferdigheter i norskfaget forutsetter systematisk arbeid med ulike muntlige sjangere og strategier i stadig mer komplekse lytte- og talesituasjoner. Det innebærer å tilegne seg fagkunnskap ved å lytte aktivt og å forstå og å bruke det muntlige språket stadig mer nyansert og presist i samtale om norskfaglige emner, problemstillinger og tekster av økende omfang og kompleksitet.',
      'Å kunne skrive i norsk er å uttrykke seg i norskfaglige sjangere på en hensiktsmessig måte. Det vil si å kunne skrive teksttyper som er relevante for faget, og å kunne ta i bruk norskfaglige begreper. Å skrive i norskfaget er også en måte å utvikle og strukturere tanker på og en metode for å lære. Norskfaget har et særlig ansvar for å utvikle elevenes evne til å planlegge, utforme og bearbeide stadig mer komplekse tekster som er tilpasset formål og mottaker. Utviklingen av skriftlige ferdigheter i norskfaget forutsetter systematisk arbeid med formelle skriveferdigheter, tekstkunnskap og ulike skrivestrategier. Det innebærer å kunne uttrykke seg med stadig større språklig sikkerhet på både hovedmål og sidemål.',
      'Å kunne lese i norsk er å skape mening fra tekster fra nåtid og fortid i et bredt utvalg sjangere. Det innebærer å engasjere seg i tekster og få innsikt i andre menneskers tanker, opplevelser og skaperkraft. Det innebærer videre å kunne finne informasjon og forstå resonnementer og framstillinger i ulike typer tekster på skjerm og papir, og å kunne forholde seg kritisk og selvstendig til de leste tekstene. Utviklingen av leseferdigheter i norskfaget forutsetter at elevene leser ofte og mye, og at de arbeider systematisk med lesestrategier som er tilpasset formålet med lesingen, og med ulike typer tekster i faget. Utviklingen går fra grunnleggende avkoding og forståelse av enkle tekster til å kunne forstå, tolke, reflektere over og vurdere stadig mer komplekse tekster i ulike sjangere.',
      'Å kunne regne i norsk er å tolke og forstå informasjon i tekster som inneholder tall, størrelser eller geometriske figurer. Det vil si å kunne vurdere, reflektere over og kommunisere om sammensatte tekster som inneholder grafiske framstillinger, tabeller og statistikk. Utviklingen i regneferdigheter i norskfaget innebærer å skape helhetlig mening i stadig mer krevende tekster der ulike uttrykksformer må ses i sammenheng.',
      'Digitale ferdigheter i norsk er å bruke digitale verktøy, medier og ressurser for å innhente og behandle informasjon, skape og redigere ulike typer tekster og kommunisere med andre. I denne sammenhengen er det viktig å kunne vurdere og bruke kilder på en bevisst måte. Utviklingen av digitale ferdigheter er en del av lese- og skriveopplæringen i norskfaget, og innebærer å finne, bruke og etter hvert vurdere og referere til digitale kilder i skriftlige og muntlige tekster, og selv produsere stadig mer komplekse tekster. Videre innebærer det å utvikle kunnskap om opphavsrett og personvern, og ha en kritisk og selvstendig holdning til ulike typer digitale kilder.'
    ],
    levels: [8, 9, 10]
  },
  'RLE1-02': {
    ID: 'RLE1-02',
    title: 'Kristendom, religion, livssyn og etikk (KRLE)',
    hours: 9180,
    chapters: [
      {
        title: 'Kristendom',
        goals: [
          'Forklare særpreget ved kristendom og kristen tro som livstolkning i forhold til andre tradisjoner: likhetstrekk og grunnleggende forskjeller',
          'Finne fram til sentrale skrifter i Bibelen og forklare forholdet mellom Det gamle og Det nye testamente',
          'Drøfte utvalgte bibeltekster fra profetene, den poetiske litteraturen og visdomslitteraturen, et evangelium og et Paulus-brev og forklare særpreg og hovedtanker i disse',
          'Drøfte ulike syn på Bibelen',
          'Innhente digital informasjon om og presentere aktuelle spørsmål som opptar mange kristne',
          'Gjøre rede for viktige hendelser i kristendommens historie fra reformasjonen til vår tid i Norge og i verden og for kristendommens stilling i dag',
          'Forklare hovedtrekk ved romersk-katolsk, ortodoks og protestantisk kristendomstradisjon',
          'Gi en presentasjon av Den norske kirke, læstadianisme og samisk kirkeliv',
          'Gi en oversikt over andre frikirkesamfunn og kristne bevegelser, herunder pinsebevegelsen',
          'Drøfte kristendommens betydning for kultur og samfunnsliv',
          'Utforske kristendommens stilling og særpreg i et land utenfor Europa og drøfte spørsmål knyttet til kristen misjon, humanitært arbeid og økumenisk virksomhet',
          'Beskrive og reflektere over særtrekk ved kunst, arkitektur og musikk knyttet til kristendommen'
        ]
      },
      {
        title: 'Jødedom',
        goals: [
          'Forklare særpreget ved jødedom og jødisk tro som livstolkning i forhold til andre tradisjoner: likhetstrekk og grunnleggende forskjeller',
          'Drøfte utvalgte tekster fra jødisk skrifttradisjon',
          'Innhente digital informasjon om og presentere aktuelle spørsmål som opptar mange jøder',
          'Gi en oversikt over mangfoldet i jødedommen, viktige historiske hendelser og jødedommens stilling i Norge og verden i dag',
          'Beskrive og reflektere over særtrekk ved kunst, arkitektur og musikk knyttet til jødedommen'
        ]
      },
      {
        title: 'Islam',
        goals: [
          'Forklare særpreget ved islam og islamsk tro som livstolkning i forhold til andre tradisjoner: likhetstrekk og grunnleggende forskjeller',
          'Drøfte utvalgte tekster fra islamsk skrifttradisjon',
          'Innhente digital informasjon om og presentere aktuelle spørsmål som opptar mange muslimer',
          'Gi en oversikt over mangfoldet i islam, viktige historiske hendelser og islams stilling i Norge og verden i dag',
          'Beskrive og reflektere over særtrekk ved kunst, arkitektur og musikk knyttet til islam'
        ]
      },
      {
        title: 'Hinduisme',
        goals: [
          'Forklare særpreget ved hinduisme og hinduistisk tro som livstolkning i forhold til andre tradisjoner; likhetstrekk og grunnleggende forskjeller',
          'Drøfte utvalgte tekster fra hinduistisk skrifttradisjon',
          'Innhente digital informasjon om og presentere aktuelle spørsmål som opptar mange hinduer',
          'Gi en oversikt over mangfoldet i hinduismen, viktige historiske hendelser og hinduismens stilling i Norge og verden i dag',
          'Beskrive og reflektere over særtrekk ved kunst, arkitektur og musikk knyttet til hinduismen'
        ]
      },
      {
        title: 'Buddhisme',
        goals: [
          'Forklare særpreget ved buddhisme og buddhistisk tro som livstolkning i forhold til andre tradisjoner: likhetstrekk og grunnleggende forskjeller',
          'Drøfte utvalgte tekster fra buddhistisk skrifttradisjon',
          'Innhente digital informasjon om og presentere aktuelle spørsmål som opptar mange buddhister',
          'Gi en oversikt over mangfoldet i buddhismen, viktige historiske hendelser og buddhismens stilling i Norge og verden i dag',
          'Beskrive og reflektere over særtrekk ved kunst, arkitektur og musikk knyttet til buddhismen'
        ]
      },
      {
        title: 'Livssyn',
        goals: [
          'Forklare hva livssyn er, og vise hvordan livssyn kommer til uttrykk på ulike måter',
          'Forklare særpreget ved livssynshumanisme i forhold til religioner og andre livssynstradisjoner: likhetstrekk og grunnleggende forskjeller',
          'Drøfte ulike tekster som presenterer livssynshumanisme, og diskutere aktuelle spørsmål som opptar livssynshumanister',
          'Gi en oversikt over mangfoldet i humanistisk livssynstradisjon, viktige historiske hendelser og livssynshumanismens stilling i Norge og verden i dag',
          'Gjenkjenne og beskrive humanistiske ideer og verdier i kunst, arkitektur og musikk',
          'Beskrive hovedtrekk ved et livssyn utenom livssynshumanismen',
          'Presentere eksempler på religionskritikk fra ulike livssynstradisjoner'
        ]
      },
      {
        title: 'Religiøst mangfold',
        goals: [
          'Samtale om og forklare hva religion er, og vise hvordan religion kommer til uttrykk på ulike måter',
          'Vise evne til dialog om religions- og livssynsspørsmål og vise respekt for ulike religioner og livssyn',
          'Diskutere aktuelle spørsmål som oppstår i møte mellom religion, kultur og samfunn',
          'Innhente informasjon om og finne særtrekk ved noen religions- og trossamfunn lokalt og nasjonalt, herunder sikhisme, Bahá’í-religionen, Jehovas vitner og Jesu Kristi Kirke av Siste Dagers Hellige',
          'Gjøre rede for nye religiøse bevegelser og samtale om ulike former for nyreligiøs og naturreligiøs praksis, herunder urfolks naturreligion',
          'Utforske religioners stilling og særpreg i et land utenfor Europa med og uten digitale verktøy'
        ]
      },
      {
        title: 'Filosofi og etikk',
        goals: [
          'Presentere noen betydningsfulle filosofer og diskutere deres ideer',
          'Reflektere over filosofiske temaer knyttet til identitet og livstolkning, natur og kultur, liv og død, rett og galt',
          'Gjøre rede for begrepene etikk og moral og bruke etisk analyse med utgangspunkt i grunnleggende etiske tenkemåter',
          'Føre dialog med andre om sammenhenger mellom etikk, religioner og livssyn',
          'Drøfte etiske spørsmål knyttet til menneskeverd og menneskerettigheter, likeverd og likestilling, blant annet ved å ta utgangspunkt i kjente forbilder',
          'Drøfte verdivalg og aktuelle temaer i samfunnet lokalt og globalt: sosialt og økologisk ansvar, teknologiske utfordringer, fredsarbeid og demokrati',
          'Reflektere over etiske spørsmål knyttet til mellommenneskelige relasjoner, familie og venner, samliv, heterofili og homofili, ungdomskultur og kroppskultur',
          'Reflektere over forholdet mellom religion, livssyn og vitenskap',
          'Vise respekt for menneskers tros- og livssynsoppfatninger, ritualer, hellige gjenstander og steder'
        ]
      }
    ],
    purpose: [
      'Religioner og livssyn gjenspeiler menneskers dypeste spørsmål og har gjennom historien bidratt til å forme individ, fellesskap og samfunn. Kunnskap om religioner og livssyn er vesentlig for enkeltmennesket, for å kunne tolke tilværelsen og for å forstå kulturer i ens eget samfunn og i samfunn verden over. Barn og unge i dag møter et mangfold av kulturpåvirkninger og verditradisjoner. Kristen tro og tradisjon har gjennom århundrer preget europeisk og norsk kultur. Samtidig har humanistiske verditradisjoner gitt kulturarven et videre tilfang. Religiøst og livssynsmessig mangfold setter i stadig større grad preg på samfunnet. Kjennskap til ulike religioner og livssyn, etikk og filosofi er en viktig forutsetning for livstolkning, etisk bevissthet og forståelse på tvers av tros- og livssynsgrenser.',
      'Kristendom, religion, livssyn og etikk som allmenndannende fag skal bidra til felles kunnskapsgrunnlag og referanserammer, bidra til ny innsikt og gi rom for dialog tilpasset de ulike alderstrinn. Kunnskaper om religioner og livssyn og om den funksjon disse har både som tradisjon og som aktuelle kilder til tro, moral og livstolkning, står sentralt i faget. Faget skal være en møteplass for elever med ulik bakgrunn, der alle skal bli møtt med respekt. Undervisningen skal stimulere til allsidig dannelse og gi rom for undring og refleksjon. Videre skal faget bidra til evne til dialog mellom mennesker med ulik oppfatning av tros- og livssynsspørsmål. Dette innebærer respekt for religiøse verdier, menneskerettighetene og menneskerettighetenes etiske grunnlag.',
      'Faget skal gi kjennskap til kristendommen, andre verdensreligioner og livssyn og til etiske og filosofiske emner. Det skal gi kjennskap til den betydning kristendommen har som kulturarv for samfunnet vårt. Om lag halvparten av undervisningstiden i faget skal av den grunn brukes på kristendomskunnskap. Dette innebærer at det vil være rom for lokale variasjoner i fordelingen mellom hovedområdene i faget for å oppfylle kompetansemålene.',
      'Kristendom, religion, livssyn og etikk er et ordinært skolefag som normalt skal samle alle elever. Opplæringsloven legger til grunn at undervisningen skal være objektiv, kritisk og pluralistisk. Det innebærer at den skal være saklig og upartisk og at de ulike verdensreligioner og livssyn skal presenteres med respekt. I undervisningen skal det ikke være forkynnelse eller religionsutøvelse. Likeverdige pedagogiske prinsipper skal legges til grunn. Det innebærer at alle religioner og livssyn skal behandles på en faglig og sakssvarende måte ut fra sitt særpreg og mangfold.',
      'Tilpasset opplæring er et overordnet prinsipp. I undervisningen skal det benyttes varierte og engasjerende arbeidsmåter som skal bidra til å formidle alle sider ved faget. Det må samtidig utøves varsomhet ved valg av arbeidsmåter. Spesielt gjelder varsomheten arbeidsmåter som foresatte og elever ut fra egen religion eller eget livssyn kan oppleve som utøvelse av annen religion eller tilslutning til annet livssyn. Både individuelle og lokale hensyn bør ivaretas.',
      'Dersom eleven melder om fritak fra deler av den ordinære opplæringen i henhold til opplæringslovens § 2-3 a, skal det følges opp med tilpasset opplæring. Det forutsettes løpende samarbeid mellom hjem og skole og god informasjon om hvordan opplæringen planlegges gjennomført.'
    ],
    skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene der de bidrar til utvikling av og er en del av fagkompetansen. I kristendom, religion, livssyn og etikk (KRLE) forstås grunnleggende ferdigheter slik:',
      'Å kunne uttrykke seg muntlig i KRLE innebærer å bruke talespråket til å kommunisere og forklare religioner og livssyn, etikk og filosofi. Muntlige ferdigheter som samtale, dialog, fortelling og utgreiing er midler til undring, refleksjon og argumentasjon. I faget legges det stor vekt på fortellingen som muntlig uttrykk.',
      'Å kunne uttrykke seg skriftlig i KRLE innebærer å kunne uttrykke kunnskaper om og synspunkter på religion og livssyn, etikk og filosofi. Skriving klargjør tanker, erfaringer og meninger og er en hjelp til å tolke, argumentere og kommunisere. Skriving i KRLE innebærer også å møte ulike estetiske skriftuttrykk og gjøre bruk av dem.',
      'Å kunne lese i KRLE innebærer å oppleve og forstå tekster. Lesing brukes for å innhente informasjon, tolke, reflektere over og forholde seg saklig og analytisk til fortellinger og fagstoff i så vel tradisjonell som multimedial formidlingsform.',
      'Å kunne regne i KRLE innebærer å kunne anvende ulike tidsregninger og måter å framstille årsrytmen på, finne fram i religiøse skrifter, møte matematiske uttrykk og tallsymbolikk og tolke og bruke statistikk. Å kunne gjenkjenne og bruke geometriske mønstre i estetiske uttrykk og arkitektur forutsetter regneferdigheter.',
      'Å kunne bruke digitale verktøy i KRLE er en hjelp til å utforske religioner og livssyn for å finne ulike presentasjoner og perspektiver. En viktig ferdighet er å kunne benytte digitalt tilgjengelig materiale, som bilder, tekster, musikk og film på måter som forener kreativitet med kildekritisk bevissthet. Digitale medier gir nye muligheter for kommunikasjon og dialog om religioner og livssyn. Disse mediene gir også muligheter for bred tilgang til materiale om aktuelle etiske problemstillinger.'
    ],
    levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  'SAF1-03': {
    ID: 'SAF1-03',
    title: 'Samfunnsfag 8. - 10. trinn',
    hours: 14940,
    chapters: [
      {
        title: 'Utforskeren',
        goals: [
          'Formulere spørsmål om forhold i samfunnet, planlegge og gjennomføre en undersøkelse og drøfte funn og resultater muntlig og skriftlig',
          'Bruke samfunnsfaglige begreper i fagsamtaler og presentasjoner med ulike digitale verktøy og bygge videre på bidrag fra andre',
          'Bruke statistiske kilder til å beregne og beskrive tendenser og variasjoner i samfunnsfaglige drøftinger og vurdere om statistikken gir pålitelig informasjon',
          'Vise hvordan hendelser kan framstilles ulikt, og drøfte hvordan interesser og ideologi kan prege synet på hva som oppleves som fakta og sanning',
          'Reflektere over samfunnsfaglige spørsmål ved hjelp av informasjon fra ulike digitale og papirbaserte kilder, og diskutere formål og relevans til kildene',
          'Identifisere samfunnsfaglige argumenter, fakta og påstander i samfunnsdebatter og diskusjoner på Internett, vurdere dem kritisk og vurdere rettigheter og konsekvenser når man offentliggjør noe på Internett',
          'Skape fortellinger om mennesker fra ulike samfunn i fortid og nåtid og vise hvordan livsvilkår og verdier påvirker tanker og handlinger',
          'Skrive samfunnsfaglige tekster med presis bruk av fagbegreper, grunngitte konklusjoner og kildehenvisninger'
        ]
      },
      {
        title: 'Historie',
        goals: [
          'Finne eksempler på hendelser som har vært med på å forme dagens Norge, og diskutere hvordan samfunnet kunne ha blitt dersom disse hendelsene hadde utviklet seg annerledes',
          'Drøfte idealer om menneskeverd, diskriminering og utvikling av rasisme i et historisk og nåtidig perspektiv',
          'Presentere hovedtrekk ved samenes historie og kultur fra midt på 1800-tallet til i dag og konsekvenser av fornorskingspolitikken og samenes kamp for rettighetene sine',
          'Presentere viktige utviklingstrekk i norsk historie på 1800-tallet og første halvdel av 1900-tallet og beskrive hvordan de peker fram mot samfunnet i dag',
          'Gjøre rede for framveksten av velferdsstaten og beskrive trekk ved Norge i dag',
          'Gjøre rede for teknologiske og samfunnsmessige endringer som følge av den industrielle revolusjonen',
          'Gjøre rede for ideer og krefter som førte til den amerikanske frihetskampen og den franske revolusjonen, og beskrive følger dette fikk for den demokratiske utviklingen i Norge',
          'Gjøre rede for kolonialisme og imperialisme og gi eksempler på avkolonisering',
          'Drøfte årsaker til og virkninger av sentrale internasjonale konflikter på 1900- og 2000-tallet',
          'Drøfte viktige omveltninger i samfunnet i nyere tid, og reflektere over hvordan dagens samfunn åpner for nye omveltninger'
        ]
      },
      {
        title: 'Geografi',
        goals: [
          'Lokalisere og dokumentere oversikt over geografiske hovedtrekk i verden og sammenligne ulike land og regioner',
          'Lese, tolke og bruke papirbaserte og digitale kart, målestokk og karttegn',
          'Gjøre rede for indre og ytre krefter på jorda, bevegelser i luftmassene, vannets kretsløp, vær, klima og vegetasjon, og drøfte sammenhenger mellom natur og samfunn',
          'Utforske, beskrive og forklare natur- og kulturlandskapet i lokalsamfunnet',
          'Undersøke hvordan mennesker nyttiggjør seg av naturgrunnlaget, andre ressurser og teknologi i Norge og i andre land i verden, og drøfte premisser for bærekraftig utvikling',
          'Undersøke og diskutere bruk og misbruk av ressurser, konsekvenser det kan få for miljøet og samfunnet, og konflikter det kan skape lokalt og globalt',
          'Sammenligne størrelse, struktur og vekst i befolkninger og analysere befolkningsutvikling, urbanisering og flytting i nyere tid',
          'Kartlegge variasjoner i levekår i ulike deler av verden, forklare de store forskjellene mellom fattige og rike og drøfte tiltak for jevnere fordeling'
        ]
      },
      {
        title: 'Samfunnskunnskap',
        goals: [
          'Gjøre rede for hvordan ulike politiske partier fremmer ulike verdier og interesser, knytte dette til aktuelle samfunnsspørsmål og argumentere for eget syn',
          'Gjøre rede for politiske institusjoner i Norge og deres rollefordeling og sammenligne dem med institusjoner i andre land',
          'Gi eksempler på hva samarbeid, medvirkning og demokrati innebærer nasjonalt, lokalt, i organisasjoner og i skolen',
          'Gjøre rede for hovedprinsippene i FN-pakten, FNs menneskerettighetserklæring og sentrale FN-konvensjoner, blant annet ILO-konvensjonen om urbefolkningenes rettigheter, vise hvordan de kommer til syne i lovgivning, og drøfte konsekvenser av brudd på menneskerettigheter',
          'Gi eksempler på hvordan oppfatninger av forholdet mellom kjærlighet og seksualitet kan variere i og mellom kulturer',
          'Analysere kjønnsroller i beskrivelser av seksualitet og forklare forskjellen på ønsket seksuell kontakt og seksuelle overgrep',
          'Gi eksempler på hvordan et lovbrudd er behandlet, diskutere årsaker til og følger av kriminalitet og forklare hvordan rettsstaten fungerer',
          'Gjøre rede for begrepene holdninger, fordommer og rasisme og vurdere hvordan holdninger kan bli påvirket, og hvordan den enkelte og samfunnet kan motarbeide fordommer og rasisme',
          'Gi eksempler på og diskutere kulturelle variasjoner og drøfte muligheter og utfordringer i flerkulturelle samfunn',
          'Beskrive hvordan forbruksmønsteret har utviklet seg i Norge, og gjøre rede for forbrukernes rettigheter',
          'Beskrive utvikling og konsekvenser av tobakks- og rusmiddelbruk i Norge og diskutere holdninger til rusmidler',
          'Beskrive hovedtrekk i norsk økonomi og hvordan den henger sammen med den globale økonomien'
        ]
      }
    ],
    purpose: [
      'I et demokratisk samfunn er verdier som medvirkning og likeverd viktige prinsipper. Sentralt i arbeidet med samfunnsfaget står forståelse av og oppslutning om grunnleggende menneskerettigheter, demokratiske verdier og likestilling. Faget skal stimulere til og gi erfaring med aktivt medborgerskap og demokratisk deltakelse.',
      'Menneskene er preget av den kulturen de vokser opp i, og dette påvirker holdninger, kunnskaper og handlinger. En dypere forståelse av forholdet mellom det personlige livet og samfunnslivet kan bidra til å erkjenne mangfoldet i samfunnsformer og levevis. I samfunnsfaget står forståelse av likestilling og likeverd sentralt. I den sammenhengen inngår kunnskap om kultur i Sápmi/Sábme/Saepmie og situasjonen til samene som urbefolkning. Kunnskap om andre urbefolkninger og minoriteter i verden er også viktig.',
      'Som menneske inngår vi i en historisk sammenheng, og en lang rekke historiske hendelser har påvirket utviklingen av samfunnet. I samfunnsfaget skal elevene lære om det kulturelle mangfoldet i verden i fortid og samtid, og lære seg å reflektere omkring det tradisjonelle og det moderne. Dermed skal faget bidra til bevisst identitetsdannelse og trygg forankring i eget samfunn og egen kultur for alle elever.',
      'Som reflekterende og handlende individer og som fellesskap kan menneskene bidra til å forme seg selv, og både påvirke og bli påvirket av omgivelsene sine. Som moralsk individ er mennesket ansvarlig for handlingene sine, også for handlinger som andre har tatt initiativ til. Gjennom samfunnsfaget utvikler elevene bevissthet om hvordan de kan påvirke det lokale og globale fellesskapet og sin egen livssituasjon.',
      'Kunnskap om samfunn og politikk har verdi i seg selv, og er samtidig en forutsetning for demokratisk deltakelse. Kunnskap om det politiske systemet i Norge og i det internasjonale samfunnet gjør at man blir bevisst på at politikk er preget av samarbeid, konflikt, påvirkning og bruk av makt i ulike former. Gjennom samfunnsfaget får elevene verktøy til å analysere og drøfte historiske og aktuelle samfunnsspørsmål, og til å identifisere og diskutere ulike maktrelasjoner.',
      'For å oppnå bærekraftig utvikling er det nødvendig å forstå forholdet mellom naturen og de menneskeskapte omgivelsene. Samfunnsfaget skal stimulere til økt bevissthet omkring sammenhenger mellom produksjon og forbruk, og konsekvenser som ressursbruk og livsutfolding kan ha på natur, klima og en bærekraftig utvikling. Gjennom arbeid med faget vil elevene lettere forstå verdien av og utfordringer knyttet til teknologi og entreprenørskap, og de vil utvikle kunnskap om arbeidsliv og global, nasjonal og personlig økonomi.',
      'Samfunnsfaget er delt inn i hovedområder som utgjør en helhet. Faget skal fremme evnen til å diskutere, resonnere og løse problemer i samfunnet ved å påvirke lysten og evnen til å søke kunnskap om samfunn og kulturer. Gjennom kunnskap om samfunnet omkring seg blir elevene undrende og nysgjerrige og stimulert til refleksjon og skapende arbeid. Slik kan den enkelte forstå seg selv og andre bedre, mestre og påvirke verden vi lever i, og bli motivert til ny innsikt og livslang læring.'
    ],
    Skills: [
      'Grunnleggende ferdigheter er integrert i kompetansemålene, der de er en del av fagkompetansen og bidrar til å utvikle den. I samfunnsfag forstås grunnleggende ferdigheter slik:',
      'Muntlige ferdigheter i samfunnsfag innebærer å kunne forstå, beskrive, sammenligne og analysere kilder og problemstillinger ved å bruke fakta, teorier, definisjoner og fagbegreper i innlegg, presentasjoner og meningsytringer. Muntlige ferdigheter handler også om å lytte til, vurdere, gi respons på og videreutvikle innspill fra andre. Utviklingen av muntlige ferdigheter i samfunnsfag går fra å lytte til og uttrykke meninger i enkle muntlige tekster til å ytre seg med grunngitte synsmåter og lytte til andre med faglig trygghet. Muntlige ferdigheter i samfunnsfag oppøves i en prosess som begynner med refererende ytringer, ofte av personlig karakter, og utvikles til fagrelevante og fagspesifikke tankerekker med økende grad av argumentasjon, drøfting og presis bruk av fagbegreper. Forståelse for ulike syn, evne til perspektivtaking og evne til å uttrykke uenighet saklig og med respekt for andre oppfatninger er også en del av muntlige ferdigheter.',
      'Å kunne skrive i samfunnsfag innebærer å kunne uttrykke, begrunne og argumentere for standpunkter, og formidle og dele kunnskap skriftlig. Det innebærer også å sammenligne og drøfte årsaker, virkninger og sammenhenger. Videre handler det om å kunne vurdere verdier i kilder, hypoteser og modeller, og å kunne presentere resultater av samfunnsfaglige undersøkelser skriftlig. Evne til å vurdere og gjennomarbeide egne tekster er også en del av ferdighetene. Utviklingen av skriveferdighetene i samfunnsfag innebærer gradvis oppøving, fra å formulere enkle faktasetninger og konkrete spørsmål, over evne til å kunne gjengi og oppsummere tekster, til å kunne formulere problemstillinger og strukturere drøftende tekst med bruk av kildehenvisninger. Oppøving i kritisk og variert kildebruk og det å kunne trekke grunngitte konklusjoner med økende bruk av fagbegreper og stigende refleksjon omkring tema, er en sentral del av prosessen.',
      'Å kunne lese i samfunnsfag innebærer å utforske, tolke og reflektere over faglige tekster for å forstå eget og andre samfunn og andre tider, steder og mennesker. Det innebærer også å kunne behandle og bruke informasjon fra bilder, film, tegninger, grafer, tabeller og kart, og å gjøre målrettede informasjonssøk, kritisk vurdering og bevisste valg og bortvalg av kilder. Utviklingen av leseferdighetene i samfunnsfag innebærer gradvis oppøving, fra forståelse av tekst og visuelle framstillinger, gjennom tolking og vurdering til utvikling av strategier for kritisk kunnskapstilegnelse. Lesing for å innhente informasjon og kritisk lesing av kilder begynner med å finne informasjon i enkle, tilrettelagte kilder og å vurdere om informasjonen er nyttig. Senere kommer oppøving av evnen til å gjøre egne informasjonssøk, sammenligne informasjon fra ulike kilder og kritisk vurdere deres relevans, troverdighet og formål.',
      'Å kunne regne i samfunnsfag innebærer å kunne innhente, arbeide med og vurdere talltilfang om faglige tema, og å framstille dette i tabeller, grafer og figurer. Regning i samfunnsfag handler også om å bruke og sammenligne, analysere og presentere statistisk tallmateriale som illustrerer utvikling og variasjon. Evnen til å gjennomføre undersøkelser med telling og regning, bruke samfunnsfaglige databaser og tolke tallmateriale kritisk er sentral. Det innebærer også å bruke målestokk, regne med tid og bruke regning til å forvalte pengebruk og personlig økonomi. Regneferdighetene blir gradvis oppøvd fra å finne og mestre strategier for telling, klassifisering, bruk og framstilling av data. Videre blir evnen til å sammenfatte, sammenligne og tolke statistisk informasjon utviklet, og evnen til analyse, kritisk bruk og vurdering av data. Arbeid med data som illustrerer utvikling og variasjon ved hjelp av statistiske mål, er sentralt.',
      'Digitale ferdigheter i samfunnsfag innebærer å kunne bruke digitale ressurser til å utforske nettsteder, søke etter informasjon, utøve kildekritikk og velge ut relevant informasjon om samfunnsfaglige tema. Ferdighetene omfatter også bruk av digitale presentasjons- og samarbeidsverktøy til å utarbeide, presentere og publisere multimediale produkter. Digitale ferdigheter vil videre si å kunne kommunisere og samarbeide digitalt om samfunnsfaglige tema, og å følge regler og normer for nettbasert kommunikasjon, medregnet personvern og opphavsrett. Utviklingen av digitale ferdigheter i samfunnsfag innebærer å lære å bruke digitale verktøy og medier for å tilegne seg faglig kunnskap, uttrykke egen kompetanse og forsterke faglige budskaper. Digitale ferdigheter i samfunnsfag blir oppøvd i en prosess som begynner med å bruke digitale verktøy til å finne og gjengi samfunnsfaglig innhold. Videre blir evnen til å bruke varierte søkestrategier utviklet, til å gjøre kritiske valg og til å uttrykke faglig refleksjon.'
    ],
    levels: [8, 9, 10]
  }
}
