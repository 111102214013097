var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _vm.project.categories.length && _vm.project.categories[0].length <= 3
          ? _c(
              "p",
              { staticClass: "inline-list" },
              [
                _vm._v("\n      Fag:\n      "),
                _vm._l(_vm.project.categories, function(category) {
                  return _c("span", { key: category }, [
                    _vm._v(_vm._s(_vm.allCategories[category].title))
                  ])
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm.project.categories.length && _vm.project.categories[0].length > 3
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [_c("subject-picker", { attrs: { project: _vm.project } })],
            1
          )
        : _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("category-picker", {
                key: "choosing",
                attrs: {
                  hidden: false,
                  type: 0,
                  expanded: false,
                  accordion: false,
                  searchable: true,
                  "pre-checked": _vm.project.categories
                },
                on: {
                  check: function($event) {
                    return _vm.saveCategories.apply(void 0, arguments)
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }