<template>
  <v-row>
    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.customerType ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/customerType'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'customerType'"
        :label="'Kundetype'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'aheadOfClass',
            text: 'Ligger foran pensum / klassen'
          },
          {
            value: 'behindClass',
            text: 'Ligger bak pensum / klassen'
          },
          {
            value: 'motivation',
            text: 'Motivasjon og mestring'
          },
          {
            value: 'improveGrades',
            text: 'Bedre karakter og studieteknikk'
          },
          {
            value: 'retakingExam',
            text: 'Tar eksamen på nytt'
          },
          {
            value: 'socialProblems',
            text: 'Sosiale problemer'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.genderPreference ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/genderPreference'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'genderPreference'"
        :label="'Kjønnspreferanse'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'male',
            text: 'Mann'
          },
          {
            value: 'female',
            text: 'Kvinne'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.studentBirthYear ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/studentBirthYear'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'studentBirthYear'"
        :label="'Elevens fødselsår'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: '1980',
            text: '1980'
          },
          {
            value: '1981',
            text: '1981'
          },
          {
            value: '1982',
            text: '1982'
          },
          {
            value: '1983',
            text: '1983'
          },
          {
            value: '1984',
            text: '1984'
          },
          {
            value: '1985',
            text: '1985'
          },
          {
            value: '1986',
            text: '1986'
          },
          {
            value: '1987',
            text: '1987'
          },
          {
            value: '1988',
            text: '1988'
          },
          {
            value: '1989',
            text: '1989'
          },
          {
            value: '1990',
            text: '1990'
          },
          {
            value: '1991',
            text: '1991'
          },
          {
            value: '1992',
            text: '1992'
          },
          {
            value: '1993',
            text: '1993'
          },
          {
            value: '1994',
            text: '1994'
          },
          {
            value: '1995',
            text: '1995'
          },
          {
            value: '1996',
            text: '1996'
          },
          {
            value: '1997',
            text: '1997'
          },
          {
            value: '1998',
            text: '1998'
          },
          {
            value: '1999',
            text: '1999'
          },
          {
            value: '2000',
            text: '2000'
          },
          {
            value: '2001',
            text: '2001'
          },
          {
            value: '2002',
            text: '2002'
          },
          {
            value: '2003',
            text: '2003'
          },
          {
            value: '2004',
            text: '2004'
          },
          {
            value: '2005',
            text: '2005'
          },
          {
            value: '2006',
            text: '2006'
          },
          {
            value: '2007',
            text: '2007'
          },
          {
            value: '2008',
            text: '2008'
          },
          {
            value: '2009',
            text: '2009'
          },
          {
            value: '2010',
            text: '2010'
          },
          {
            value: '2011',
            text: '2011'
          },
          {
            value: '2012',
            text: '2012'
          },
          {
            value: '2013',
            text: '2013'
          },
          {
            value: '2014',
            text: '2014'
          },
          {
            value: '2015',
            text: '2015'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.studentGender ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/studentGender'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'studentGender'"
        :label="'Elevens kjønn'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'male',
            text: 'Mann / Gutt'
          },
          {
            value: 'female',
            text: 'Kvinne / Jente'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.startupSchedulePreferance ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/startupSchedulePreferance'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'startupSchedulePreferance'"
        :label="'Ønsker å komme i gang'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'now',
            text: 'Nå'
          },
          {
            value: 'fewDays',
            text: 'Innen noen dager'
          },
          {
            value: 'fewWeeks',
            text: 'Innen noen uker'
          },
          {
            value: 'fewMonths',
            text: 'Innen noen måneder'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.problemOrigin ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/problemOrigin'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'problemOrigin'"
        :label="'Hvorfor løser ikke skolen problemet?'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'badTeachers',
            text: 'Dårlig(e) lærer(e)'
          },
          {
            value: 'specialNeeds',
            text: 'Spesielle behov'
          },
          {
            value: 'motivation',
            text: 'Motivasjon'
          },
          {
            value: 'studyTechnique',
            text: 'Struktur/studieteknikk'
          },
          {
            value: 'overlooked',
            text: 'Blir ikke sett'
          },
          {
            value: 'other',
            text: 'Annet'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.priceSensitivity ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/priceSensitivity'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'priceSensitivity'"
        :label="'Prissensitivitet'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'high',
            text: 'Høy'
          },
          {
            value: 'medium',
            text: 'Middels'
          },
          {
            value: 'low',
            text: 'Lav'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.referrer ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/referrer'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'referrer'"
        :label="'Hvor hørte kunden om Learnlink første gang?'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'press',
            text: 'Presse'
          },
          {
            value: 'facebook',
            text: 'Facebook'
          },
          {
            value: 'google',
            text: 'Google'
          },
          {
            value: 'referral',
            text: 'Word of mouth'
          },
          {
            value: 'podcast',
            text: 'Podcast'
          },{
            value: 'telia',
            text: 'Telia'
          },
          {
            value: 'flytoget',
            text: 'Flytoget'
          },
          {
            value: 'facebook-lead-ads',
            text: 'Facebook lead ads'
          },
          {
            value: 'native-lead-ads',
            text: 'Native lead ads'
          },
          {
            value: 'recruiting',
            text: 'Verving'
          },
          {
            value: 'blog',
            text: 'Blogg'
          },
          {
            value: 'other',
            text: 'Annet'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.decisionMakingTimeDays ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/decisionMakingTimeDays'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'decisionMakingTimeDays'"
        :label="'Hvor lang tid brukte kunden på å ta kjøpsbeslutningen?'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 1,
            text: '1 dag'
          },
          {
            value: 7,
            text: '1 uke'
          },
          {
            value: 14,
            text: '2 uker'
          },
          {
            value: 28,
            text: '1 måned'
          },
          {
            value: 56,
            text: '2 måneder'
          },
          {
            value: 168,
            text: '6 måneder'
          }
        ]"
      />
    </v-col>

    <v-col
      cols="4"
      :style="'background-color: ' + (project.customer.user.decisionMaker ? '#beffbe' : 'rgba(255,0,12,0.56)') + '; padding: 10px'"
    >
      <document-field-picker
        :id="project.customerUID"
        :key="project.customerUID + '/decisionMaker'"
        :collection="'users'"
        :doc="project.customer.user"
        :field="'decisionMaker'"
        :label="'Hvem besluttet å kjøpe undervisning?'"
        :alternatives="[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'mother',
            text: 'Mor'
          },
          {
            value: 'father',
            text: 'Far'
          },
          {
            value: 'student',
            text: 'Eleven selv'
          },
          {
            value: 'other',
            text: 'Andre'
          }
        ]"
      />
    </v-col>

  </v-row>
</template>

<script>
export default {
  name: 'CustomerInformation',
  props: ['project'],
};
</script>
