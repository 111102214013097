<template>
  <div>
    <el-input v-if="searchable" v-model="textFilter" placeholder="Søk etter fag" />

    <el-tree
      v-if="!hidden || (hidden && textFilter)"
      ref="categoryTree"
      v-loading="loading"
      :accordion="accordion"
      :data="categories"
      :default-expand-all="expanded"
      :filter-node-method="filterNode"
      :props="defaultProps"
      class="filter-tree"
      empty-text="Ingen fag tilgjengelig"
      node-key="ID"
      show-checkbox
      style="cursor: default;
            background: #fff;
            text-align: left;
            height: 30vh;
            border: 1px solid #d1dbe5;
            overflow: auto;"
      @check-change="categoryChecked"
    />
  </div>
</template>

<script>
import { activeCategories, allCategories } from '../categories'

export default {
  props: [
    'hidden', // If the tree should be hidden
    'type', // Type of categories to display. 0 is regular school subjects.
    'level', // Advancement level. Currently, 1 is elementary, 2 is high school, 3 is vgs.
    'expanded', // See Element documentation.
    'accordion', // See Element documentation.
    'preChecked', // Categories that should already be checked when the component initializes.
    'searchable', // If the picker should be searchable. Displays the search bar.
    'chooseFrom' // The list of allowed categories.
  ],
  data () {
    return {
      categories: [],
      currentlyChecked: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      loading: true,
      textFilter: ''
    }
  },
  watch: {
    textFilter (val) {
      this.$refs.categoryTree.filter(val)
    }
  },
  mounted () {
    // Reset to init the tree
    this.categories = []

    // It a set of categories is defined, display only them.
    if (this.chooseFrom) {
      this.chooseFrom.forEach(ID => {
        if (activeCategories[ID]) this.categories.push(activeCategories[ID])
      })
    }
    // Else, display all.
    else {
      // Getting parents
      Object.keys(allCategories).forEach(parent => {
        if (allCategories[parent].parent === 0 && allCategories[parent].active) {
          const category = allCategories[parent]

          // Attaching children
          category.children = []

          Object.keys(activeCategories).forEach(child => {
            if (allCategories[child].parent === allCategories[parent].ID && allCategories[child].active && allCategories[child].type === this.type) {
              // Filter on parameters
              if (this.level && allCategories[child].level === this.level) {
                category.children.push(allCategories[child])
              }
              else {
                category.children.push(allCategories[child])
              }
            }
          })

          // Only show parents that have children
          if (category.children.length) this.categories.push(category)
        }
      })
    }

    setTimeout(() => {
      if (this.preChecked) this.$refs.categoryTree.setCheckedKeys(this.preChecked)
      this.loading = false
    }, 1000)
  },
  methods: {
    categoryChecked () {
      // Running each time a category is checked
      this.currentlyChecked = []
      this.$refs.categoryTree.getCheckedNodes().forEach(category => {
        // Don't add parents to the list
        if (category.parent !== 0) {
          // Checking that the ID isn't already in the list
          if (!this.currentlyChecked.includes(category.ID + '')) this.currentlyChecked.push(category.ID + '')
        }
      })
      this.$emit('check', this.currentlyChecked)
    },
    filterNode (value, data) {
      // Executed on each node when typing into the search field
      if (!value) return true

      // Text filter, that searches only children
      return (data.parent !== 0) ? data.title.toUpperCase().match(value.toUpperCase()) : false
    }
  }
}
</script>
