<template>
  <v-card>
    <v-container>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters v-if="!open">
                <v-col cols="4">Kontaktperson: {{ project.customer.user.POC }}</v-col>
                <v-col cols="4">Kontaktet: {{ project.customer.user.contacted }}</v-col>
                <v-col cols="4">Prioritet: {{ project.customer.user.priority }}</v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <project-categorization :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <template>
              <v-row no-gutters>
                <v-col cols="4">Beskrivelse: {{ project.description.substring(0, 50) }}...</v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <project-description :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters v-if="!open">
                <v-col>
                  <p v-if="project.categories.length && project.categories[0].length <= 3" class="inline-list">
                    Fag:
                    <span v-for="category in project.categories" :key="category">{{ allCategories[category].title
                      }}</span>
                  </p>
                </v-col>
                <v-col>
                  Sted: {{ project.location }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <project-subjects-or-categories :project="project" />
              </v-col>
              <v-col>
                <project-location :project="project" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters v-if="!open">
                <v-col cols="4">Status: {{ project.status }}</v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <project-status :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters v-if="!open">
                <v-col cols="3">Elevens fornavn: {{ project.studentName }}</v-col>
                <v-col cols="3">Generelt: {{ project.general.substring(0, 45) }}...</v-col>
                <v-col cols="3">Nivå: {{ project.level }}</v-col>
                <v-col cols="3">Ønsker undervisning: {{ project.lessonFrequency }}</v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <project-information :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            Kartleggingstime
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <first-lesson-editor :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            Datainnsamling
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <customer-information :project="project" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <v-toolbar dark dense>
      <v-spacer />
      <div v-if="!project.sellerUID && !project.cancelled">
        <v-btn
          :loading="loading"
          v-if="!project.approved"
          color="green"
          dark
          small
          @click="approveProject(project)"
        >
          Godkjenn oppdraget
        </v-btn>
        <v-btn
          :loading="loading"
          v-else-if="project.approved"
          color="red"
          small
          dark
          @click="unapproveProject(project.ID)"
        >
          Fjern godkjenning
        </v-btn>
      </div>

      <div v-if="!project.minutesSold && !project.completed">
        <v-btn
          :loading="loading"
          v-if="!project.cancelled"
          color="red"
          dark
          small
          @click="cancelProject(project.ID)"
        >
          Kanseller oppdraget
        </v-btn>
        <v-btn
          :loading="loading"
          v-else-if="project.cancelled"
          color="red"
          dark
          small
          @click="unCancelProject(project.ID)"
        >
          Angre kansellering
        </v-btn>
      </div>

      <v-btn
        :loading="loading"
        v-if="!project.completed && !project.cancelled && project.minutesSold"
        color="orange"
        small
        dark
        @click="completeProject(project.ID)"
      >
        Fullfør oppdraget
      </v-btn>

      <v-spacer />

      <syncers :uid="project.customerUID" />
    </v-toolbar>
  </v-card>
</template>

<script>
import { allCategories } from '../categories';
import CustomerInformation from './ProjectDialog/CustomerInformation';
import FirstLessonEditor from './ProjectDialog/FirstLessonEditor';
import ProjectCategorization from './ProjectDialog/ProjectCategorization';
import ProjectDescription from './ProjectDialog/ProjectDescription';
import ProjectLocation from './ProjectDialog/ProjectLocation';
import ProjectStatus from './ProjectDialog/ProjectStatus';
import ProjectSubjectsOrCategories from './ProjectDialog/ProjectSubjectsOrCategories';
import ProjectInformation from './ProjectDialog/ProjectInformation';
import Syncers from '@/components/Syncers';

export default {
  components: {
    ProjectInformation,
    ProjectSubjectsOrCategories,

    CustomerInformation,
    FirstLessonEditor,
    ProjectCategorization,
    ProjectDescription,
    ProjectLocation,
    ProjectStatus,
    Syncers,
  },
  props: ['project'],
  data() {
    return {
      allCategories,
      loading: false,
    };
  },
  methods: {
    async approveProject(project) {
      this.loading = true;
      await this.$emit('update', {
        ID: project.ID,
        approved: this.$moment().unix(),
      });

      await this.$axios.get('/notifications/newProjectApproved/' + project.ID);
      await this.$axios.get('/notifications/introPackage/' + project.customerUID);

      this.loading = false;
    },
    async cancelProject(projectID) {
      this.loading = true;
      await this.$emit('update', {
        ID: projectID,
        cancelled: this.$moment().unix(),
      });
      this.loading = false;
    },
    async completeProject(projectID) {
      this.loading = true;
      await this.$emit('update', {
        ID: projectID,
        completed: this.$moment().unix(),
      });
      this.loading = false;
    },
    async unapproveProject(projectID) {
      this.loading = true;
      await this.$emit('update', {
        ID: projectID,
        approved: 0,
      });
      this.loading = false;
    },
    async unCancelProject(projectID) {
      this.loading = true;
      await this.$emit('update', {
        ID: projectID,
        cancelled: 0,
      });
      this.loading = false;
    },
  },
};
</script>
