var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: {
        border: "1px solid #ffda81",
        padding: "10px 0 0 0",
        "border-radius": "3px"
      }
    },
    [
      _c("h5", { staticStyle: { color: "orange", margin: "-5px 0 15px 0" } }, [
        _vm._v("\n    Kun synlig for PL og ansatte\n  ")
      ]),
      _c(
        "el-row",
        { staticStyle: { "text-align": "left", margin: "0 10px" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.status,
                callback: function($$v) {
                  _vm.status = $$v
                },
                expression: "status"
              }
            },
            [
              _c(
                "el-row",
                { staticClass: "status-picker" },
                [
                  _vm.project.minutesSold === 0
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: "awaitingFirstLesson" } },
                            [
                              _vm._v(
                                "\n            Venter kartleggingstime\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c("el-radio", { attrs: { label: "good" } }, [
                        _vm._v("\n            Eleven har det bra\n          ")
                      ])
                    ],
                    1
                  ),
                  _vm.project.minutesSold > 0
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { span: 12 }
                        },
                        [
                          _c("el-radio", { attrs: { label: "paused" } }, [
                            _vm._v(
                              "\n            Pause fra undervisningen\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: "badResultPossible" } },
                        [
                          _vm._v(
                            "\n            Fare for dårlig resultat\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c("el-radio", { attrs: { label: "notAnswering" } }, [
                        _vm._v(
                          "\n            Jeg får ikke tak i eleven\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c("el-radio", { attrs: { label: "quitting" } }, [
                        _vm._v(
                          "\n            Eleven ønsker å slutte\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c("el-radio", { attrs: { label: "other" } }, [
                        _vm._v("\n            Annet\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInput,
                  expression: "showInput"
                }
              ],
              staticStyle: { margin: "20px 0 10px 0" },
              attrs: { gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 20, md: 20, lg: 18, xl: 20 } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      placeholder: _vm.generatePlaceholder
                    },
                    model: {
                      value: _vm.statusText,
                      callback: function($$v) {
                        _vm.statusText = $$v
                      },
                      expression: "statusText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 4, md: 4, lg: 4, xl: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.statusText },
                      on: { click: _vm.saveStatus }
                    },
                    [_vm._v("\n          Lagre\n        ")]
                  )
                ],
                1
              ),
              _vm.currentStatusText
                ? _c("el-col", { attrs: { span: 24 } }, [
                    _c("p", { staticStyle: { "white-space": "pre-wrap" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.currentStatusText) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }