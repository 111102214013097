var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.searchable
        ? _c("el-input", {
            attrs: { placeholder: "Søk etter fag" },
            model: {
              value: _vm.textFilter,
              callback: function($$v) {
                _vm.textFilter = $$v
              },
              expression: "textFilter"
            }
          })
        : _vm._e(),
      !_vm.hidden || (_vm.hidden && _vm.textFilter)
        ? _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "categoryTree",
            staticClass: "filter-tree",
            staticStyle: {
              cursor: "default",
              background: "#fff",
              "text-align": "left",
              height: "30vh",
              border: "1px solid #d1dbe5",
              overflow: "auto"
            },
            attrs: {
              accordion: _vm.accordion,
              data: _vm.categories,
              "default-expand-all": _vm.expanded,
              "filter-node-method": _vm.filterNode,
              props: _vm.defaultProps,
              "empty-text": "Ingen fag tilgjengelig",
              "node-key": "ID",
              "show-checkbox": ""
            },
            on: { "check-change": _vm.categoryChecked }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }