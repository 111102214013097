<template>
  <v-row>
    <v-col>
      <p v-if="(project.lessons && project.lessons.length) || newlyRegisterredLesson">
        <strong>Første time</strong>
        <span v-if="!newlyRegisterredLesson">
          {{ $moment.unix(project.firstLesson.startTime).format('Do MMM HH:mm') }} - {{ $moment.unix(project.firstLesson.endTime).format('HH:mm') }}
        </span>
        <span v-else>
          {{
            $moment.unix(newlyRegisterredLesson.startTime).format('Do MMM HH:mm')
          }} - {{ $moment.unix(newlyRegisterredLesson.endTime).format('HH:mm') }}
        </span>
      </p>
      <span v-else>
        <h4>Registrer kartleggingstime</h4>
        <lesson-editor :project="project" @success="updateFirstLesson" />
      </span>
    </v-col>
  </v-row>
</template>

<script>
import LessonEditor from '../core/LessonEditor'

export default {
  components: {
    LessonEditor
  },
  props: ['project'],
  data () {
    return {
      newlyRegisterredLesson: null
    }
  },
  methods: {
    updateFirstLesson (val) {
      this.newlyRegisterredLesson = val
    }
  }
}
</script>
