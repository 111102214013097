var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "justify-space-around": "" } },
    [
      _c(
        "v-col",
        [
          _c("document-field-picker", {
            key: _vm.project.customerUID + "/POC",
            attrs: {
              id: _vm.project.customerUID,
              collection: "users",
              doc: _vm.project.customer.user,
              field: "POC",
              label: "Kontaktperson",
              alternatives: [
                {
                  value: "",
                  text: "(Ikke valgt)"
                },
                {
                  value: "jonas",
                  text: "Jonas"
                },
                {
                  value: "tellef",
                  text: "Tellef"
                },
                {
                  value: "sigrid",
                  text: "Sigrid"
                },
                {
                  value: "hakon",
                  text: "Håkon"
                }
              ]
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("document-field-picker", {
            key: _vm.project.customerUID + "/contacted",
            attrs: {
              id: _vm.project.customerUID,
              collection: "users",
              doc: _vm.project.customer.user,
              field: "contacted",
              label: "Kontaktet?",
              alternatives: [
                {
                  value: "",
                  text: "(Ikke valgt)"
                },
                {
                  value: "yes",
                  text: "Ja"
                },
                {
                  value: "no",
                  text: "Nei"
                }
              ]
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("document-field-picker", {
            key: _vm.project.customerUID + "/priority",
            attrs: {
              id: _vm.project.customerUID,
              collection: "users",
              doc: _vm.project.customer.user,
              field: "priority",
              label: "Prioritet",
              alternatives: [
                {
                  value: 0,
                  text: "(Ikke valgt)"
                },
                {
                  value: 1,
                  text: "1 (høyest)"
                },
                {
                  value: 2,
                  text: "2 (vanlig)"
                },
                {
                  value: 3,
                  text: "3 (lavest)"
                }
              ]
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }